/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Invoice.css";
import TableComponent from "./InvoiceTable";
import useApi from "../../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../../utils/secure-route/AuthContext";
import AddVendorModal from "../../ReusableForm/AddVendorModal";

function InvoiceForm({ onCancel }) {
    const { Get, Post } = useApi();
    let { user } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);
    const today = new Date().toISOString().split("T")[0]; // Formats date as YYYY-MM-DD

    const dropdownRef = useRef(null);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const handleVendorAdded = (newVendor) => {
        setVendors((prevVendors) => [...prevVendors, newVendor]); // Add new vendor to the list
        setFormData({ ...formData, vendor_department: newVendor.id }); // Set the newly added vendor as the selected one
    };

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        date: today,
        vendor_department: "",
        vendor_department_name: "",
        invoice_no: "", // This will now have a default value from the last invoice
        pay_method_status: "",
        prepaid_tax: false,
        prepaid_tax_percentage: "",
        amount: "",
        remaining_amount: "",
        pay_method: "",
        due_date: null,
        note: "",
        bank: "",
        status: "",
        cheque_no: null,
        type: "",
    });
    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            remaining_amount: prevData.amount, // Automatically set remaining_amount to the value of amount
        }));
    }, [formData.amount]);
    const [vendors, setVendors] = useState([]);
    const [banks, setBanks] = useState([]);
    const [dueDays, setDueDays] = useState([]);
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check for required fields
        if (!formData.vendor_department) {
            toast.error("Please select a vendor before submitting.");
            return; // Prevent form submission if vendor is not selected
        }

        if (!formData.invoice_no) {
            toast.error("Invoice number is required.");
            return; // Prevent form submission if invoice number is not provided
        }

        if (!formData.amount) {
            toast.error("Amount is required.");
            return; // Prevent form submission if amount is not provided
        }

        // Check if the payment method is selected
        if (!formData.pay_method) {
            toast.error("Please select a payment method before submitting.");
            return; // Prevent form submission if payment method is not selected
        }

        const invoiceStatus =
            formData.pay_method_status === "pay_now" && formData.pay_method === "cash"
                ? "paid"
                : "unpaid";

        const invoiceDataToSubmit = {
            ...formData,
            store: user.store,
            status: invoiceStatus,
        };

        try {
            const response = await Post("invoiceData", invoiceDataToSubmit);

            if (response && response.status === 201) {
                toast.success("Invoice submitted successfully!");
                // Reload the component by navigating to the same route
                navigate(0);
            } else {
                console.error("Failed to submit:", response?.data);
                toast.error("Failed to submit invoice. Please try again.");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error(
                "An error occurred while submitting the invoice. Please check your network and try again."
            );
        }
    };

    const fetchData = async () => {
        try {
            const [
                dueDaysResponse,
                vendorResponse,
                bankResponse,
            ] = await Promise.all([
                Get("dueDaysData"),
                Get("vendorDepartmentData"),
                Get("bank"),
                Get("invoiceData"),
            ]);

            setDueDays(dueDaysResponse);
            setVendors(vendorResponse);
            setBanks(bankResponse);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
const [reloaData, setReloadData] =useState(false);
    useEffect(() => {
        fetchData();
    }, [reloaData]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name === "vendor_department") {
            const selectedVendor = vendors.find((vendor) => vendor.id === value);
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                vendor_department_name: selectedVendor ? selectedVendor.name : "",
                type: selectedVendor ? selectedVendor.type : "",
            }));
        } else if (name === "due_days") {
            const selectedDueDays = parseInt(value, 10);
            const today = new Date();
            const dueDate = new Date(today);
            dueDate.setDate(dueDate.getDate() + selectedDueDays);

            // Format due date as YYYY-MM-DD
            const formattedDueDate = dueDate.toISOString().split("T")[0];

            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                due_date: formattedDueDate,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === "checkbox" ? checked : value,
            }));
        }
        setReloadData(!reloaData);
    };
    const handlePaymentMethodChange = (e) => {
        const value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            pay_method_status: value,
            pay_method: "cash", // Resetting pay method to "cash" by default
            due_date: null, // Resetting due date
            note: "", // Clear the note when changing payment method
            bank: "",
            cheque_no: "",
        }));
    };


    const handleCancel = () => {
        onCancel();
    };

    const fields = [
        {
            row: 1,
            column: "col-md-2",
            label: "Bill Date",
            type: "date",
            name: "date",
            placeholder: "Bill Date",
        },
        {
            row: 1,
            column: "col-md-5",
            label: "Vendor",
            type: "select-vendor",
            name: "vendor_department_name",
            placeholder: "Select Vendor",
            options: vendors,
        },
        {
            row: 1,
            column: "col-md-5",
            label: "Invoice Number",
            type: "text",
            name: "invoice_no",
            placeholder: formData.invoice_no || "Invoice Number",
        },
        {
            row: 2,
            column: "col-md-12",
            name: "pay_method_status",
            label: "Payment Method:",
            type: "radio",
            options: [
                { value: "pay_later", label: "Pay Later" },
                { value: "pay_now", label: "Pay Now" },
                { value: "credit_invoice", label: "Credit Invoice" },
            ],
        },
        {
            row: 3,
            column: "col-md-12",
            label: "Payment Method Options",
            type: "payment-method-options",
        },
        {
            row: 4,
            column: "col-md-12",
            label: "Payment Method Options",
            type: "pay-bank-options",
        },
        {
            row: 4,
            column: "col-md-6",
            label: "Due Days",
            type: "select-dueDays",
            name: "due_days",
            placeholder: "Select Due Days",
            options: dueDays,
        },
        // The "Notes" field is conditionally rendered based on pay_method_status
        ...(formData.pay_method_status !== "pay_now"
            ? [
                {
                    row: 5,
                    column: "col-md-6",
                    label: "Notes",
                    type: "text",
                    name: "note",
                    placeholder: "Notes",
                },
            ]
            : []),
        {
            row: 5,
            column: "col-md-12",
            label: "Do you want to add Prepaid Tax?",
            type: "checkbox",
            name: "prepaid_tax",
        },
        {
            row: 6,
            column: "col-md-6",
            label: "Amount",
            name: "amount",
            type: "prefix-input",
            placeholder: "Amount",
            prefixText: "USD",
        },
    ];


    const paymentMethodOptions = [
        { value: "cash", label: "Cash" },
        { value: "cheque", label: "Cheque" },
        { value: "bank", label: "Bank Card (ACH/EFT)" },
    ];

    const groupedFields = fields.reduce((acc, field) => {
        const row = field.row;
        if (!acc[row]) {
            acc[row] = [];
        }
        acc[row].push(field);
        return acc;
    }, {});

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleLinkClick = (path) => {
        setIsOpen(false); // Close the dropdown after clicking a link
        navigate(path); // Navigate to the clicked path
    };

    const renderField = (field) => {
        switch (field.type) {
            case "text":
                if (
                    field.name === "note" &&
                    (formData.pay_method === "cheque" || formData.pay_method === "bank")
                ) {
                    return null; // Don't render Notes
                }
                return (
                    <div className="form-group">

                        <input
                            type={field.type}
                            className="form-control-borderless"
                            name={field.name}
                            placeholder={field.placeholder}
                            value={formData[field.name]}
                            onChange={handleChange}
                        />
                    </div>
                );

            case "date":
                return (
                    <div className="form-group">
                        <input
                            type={field.type}
                            className="form-control-borderless"
                            name={field.name}
                            placeholder={field.placeholder}
                            value={formData[field.name] || today}
                            onChange={handleChange}
                        />
                    </div>
                );
            case "prefix-input":
                return (
                    <div
                        className="form-group col-md-6"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid #f4f4f4",
                            paddingBottom: "16px",
                            height: "50px",
                        }}
                    >
                        <div className="input-group">
                            <span className="input-group-text" style={{ border: "none" }}>
                                {field.prefixText}
                            </span>
                            <input
                                type="text"
                                style={{ border: "none" }}
                                className="form-control-borderless"
                                name={field.name}
                                placeholder={field.placeholder}
                                value={formData[field.name]}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                );

            case "select-vendor":
                return (
                    <div className="form-group">
                        <select
                            className="form-control-borderless"
                            name="vendor_department"
                            value={formData.vendor_department}
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                if (selectedValue === "add-vendor") {
                                    handleShow(); // Open the modal
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        vendor_department: "",
                                    })); // Reset the field
                                } else {
                                    handleChange(e); // Call the existing change handler for other options
                                }
                            }}
                        >
                            <option value="">Select Vendor</option>
                            <option value="add-vendor">Add Vendor</option>
                            {/* Add Vendor option */}
                            {field.options.map((vendor) => (
                                <option key={vendor.id} value={vendor.id}>
                                    {vendor.name}
                                </option>
                            ))}
                        </select>
                    </div>
                );

            case "select":
                return (
                    <div className="form-group">
                        <select
                            className="form-control-borderless"
                            name={field.name}
                            value={formData[field.name]}
                            onChange={handleChange}
                        >
                            <option value="">Select Bank</option>
                            {banks.map((bank) => (
                                <option key={bank.id} value={bank.id}>
                                    {bank.name}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            case "select-dueDays":
                // Show Select Due Days when 'Pay Later' or 'Credit Invoice' is selected
                return (
                    (formData.pay_method_status === "pay_later" ||
                        formData.pay_method_status === "credit_invoice") && (
                        <div className="form-group" style={{ position: "relative" }}>
                            <select
                                required
                                className="form-control-borderless"
                                name={field.name}
                                value={formData[field.name]}
                                onChange={handleChange}
                                style={{ paddingRight: "24px" }} // Give space for the icon
                            >
                                <option value="">{field.placeholder}</option>
                                {field.options.map((dueDays) => (
                                    <option key={dueDays.id} value={dueDays.days}>
                                        {dueDays.days}
                                    </option>
                                ))}
                            </select>

                            <svg
                                width="16"
                                height="10"
                                viewBox="0 0 16 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "8px", // Align to the right inside the select
                                    transform: "translateY(-50%)",
                                    pointerEvents: "none", // Prevents the icon from blocking select clicks
                                }}
                            >
                                <g opacity="0.4" clipPath="url(#clip0_723_243)">
                                    <path
                                        d="M8.07608 9.20648C7.97615 9.20775 7.87708 9.18722 7.78523 9.14622C7.69338 9.10521 7.61078 9.04463 7.54275 8.96839L0.685603 1.82553C0.380841 1.50807 0.380841 1.016 0.685603 0.698544C0.990365 0.381084 1.46275 0.381084 1.76751 0.698544L8.09132 7.28585L14.3999 0.714417C14.7046 0.396957 15.177 0.396957 15.4818 0.714417C15.7866 1.03188 15.7866 1.52394 15.4818 1.8414L8.62465 8.98426C8.47227 9.14299 8.27417 9.22235 8.09132 9.22235L8.07608 9.20648Z"
                                        fill="#002300"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_723_243">
                                        <rect width="16" height="10" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    )
                );

            case "radio":
                return (
                    <div className="form-group d-flex">
                        {/* Align items vertically */}
                        <label className="me-4">{field.label}</label>{" "}
                        {/* Extra space after the main label */}
                        {field.options.map((option, index) => (
                            <div key={index} className="form-check me-4">
                                {" "}
                                {/* Space between options */}
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name={field.name}
                                    value={option.value}
                                    checked={formData[field.name] === option.value}
                                    onChange={handlePaymentMethodChange}
                                />
                                <label className="form-check-label">{option.label}</label>{" "}
                                {/* Space between radio and label */}
                            </div>
                        ))}
                    </div>
                );

            case "payment-method-options":
                return (
                    formData.pay_method_status === "pay_now" && (
                        <div className="form-group d-flex">
                            {/* Align items vertically */}
                            <label className="me-4">Payment Method Options:</label>{" "}
                            {/* Extra space after the main label */}
                            {paymentMethodOptions.map((option, index) => (
                                <div key={index} className="form-check me-4">
                                    {" "}
                                    {/* Space between options */}
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="pay_method"
                                        value={option.value}
                                        checked={formData.pay_method === option.value}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label">{option.label}</label>{" "}
                                    {/* Space between radio and label */}
                                </div>
                            ))}
                        </div>
                    )
                );

            case "checkbox":
                return (
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={field.name}
                            checked={formData[field.name]}
                            onChange={handleChange}
                        />
                        <label className="form-check-label">{field.label}</label>
                    </div>
                );

            case "pay-bank-options":
                return (
                    (formData.pay_method === "cheque" ||
                        formData.pay_method === "bank") && (
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <select
                                        required
                                        className="form-control-borderless"
                                        name="bank"
                                        value={formData.bank}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Bank</option>
                                        {banks.map((bank) => (
                                            <option key={bank.id} value={bank.id}>
                                                {bank.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {formData.pay_method === "cheque" && ( // Only show this if pay_method is cheque
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            required
                                            type="text"
                                            className="form-control-borderless"
                                            name="cheque_no"
                                            placeholder="Enter Cheque Number"
                                            value={formData.cheque_no}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-md-12">
                                <div className="form-group">
                                    <input
                                        className="form-control-borderless"
                                        name="note"
                                        placeholder="Note"
                                        value={formData.note}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                );

            default:
                return null;
        }
    };

    return (
        <div className="dashboard-container">

            <div className="formcontainer">
                <div
                    className="container"
                    style={{
                        backgroundColor: "white",
                        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-between mb-4">
                            <div className="dropdown" ref={dropdownRef}>
                                <button
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    onClick={toggleDropdown}
                                >
                                    Add Invoice
                                    <svg
                                        width="16"
                                        height="10"
                                        viewBox="0 0 16 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <path
                                            d="M8.07608 9.20648C7.97615 9.20775 7.87708 9.18722 7.78523 9.14622C7.69338 9.10521 7.61078 9.04463 7.54275 8.96839L0.685603 1.82553C0.380841 1.50807 0.380841 1.016 0.685603 0.698544C0.990365 0.381084 1.46275 0.381084 1.76751 0.698544L8.09132 7.28585L14.3999 0.714417C14.7046 0.396957 15.177 0.396957 15.4818 0.714417C15.7866 1.03188 15.7866 1.52394 15.4818 1.8414L8.62465 8.98426C8.47227 9.14299 8.27417 9.22235 8.09132 9.22235L8.07608 9.20648Z"
                                            fill="#002300"
                                        />
                                    </svg>
                                </button>
                                {isOpen && (
                                    <ul
                                        className="dash-drop-menu"
                                        aria-labelledby="dropdownMenuButton"
                                    >
                                        <li>
                                            <a
                                                className="dash-drop-menu-item"
                                                onClick={() => handleLinkClick("/")}
                                            >
                                                Add Invoice
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dash-drop-menu-item"
                                                onClick={() => handleLinkClick("/payInvoice")}
                                            >
                                                Pay Invoice
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dash-drop-menu-item"
                                                onClick={() => handleLinkClick("/bankDeposit")}
                                            >
                                                Bank Deposit
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dash-drop-menu-item"
                                                onClick={() => handleLinkClick("/atmDeposit")}
                                            >
                                                ATM Deposit
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-contained me-2"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                        {Object.keys(groupedFields).map((rowKey) => (
                            <div className="row mb-3" key={rowKey}>
                                {groupedFields[rowKey].map((field, index) => (
                                    <div key={index} className={field.column}>
                                        {renderField(field)}
                                    </div>
                                ))}
                            </div>
                        ))}
                        {formData.prepaid_tax && (
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Prepaid Tax Percentage</label>
                                        <input
                                            type="text"
                                            className="form-control-borderless"
                                            name="prepaid_tax_percentage"
                                            placeholder="Enter Prepaid Tax Percentage"
                                            value={formData.prepaid_tax_percentage}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>

        </div>
    );
}

export default InvoiceForm;
