import React from 'react';

export default function Payment() {
  const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full viewport height
  };

  return (
    <div style={centerStyle}>
      <h1>Payments Page</h1>
    </div>
  );
}
