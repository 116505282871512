import React from "react";
import FormLayout from "./FormLayout";
import logo from "../../assets/img/EZHISAB.png";
import backgroundImage from "../../assets/img/Frame 812.png";
import topimage from "../../assets/img/Group 228723.png"
import SuccessMsg from "./WithUi/SuccessMsg";


function SuccessMsgPage() {
  return (
    <FormLayout logoSrc={logo} backgroundImageSrc={backgroundImage} TopImage={topimage}>
      <SuccessMsg />
    </FormLayout>
  );
}
export default SuccessMsgPage;
