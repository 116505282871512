import React from "react";
import FormLayout from "./FormLayout";
import logo from "../../assets/img/EZHISAB.png";
import backgroundImage from "../../assets/img/Frame 812.png";
import topimage from "../../assets/img/Group 228723.png"
import AccountCreate from "./WithUi/AccountCreate";

function SignUp() {
  return (
    <FormLayout logoSrc={logo} backgroundImageSrc={backgroundImage} TopImage={topimage}>
      <AccountCreate />
    </FormLayout>
  );
}
export default SignUp;
