import React, { useState } from "react";

const CustomSwitchSubscription = ({ name, label, handleSwitchChange, isActive }) => {
  const [internalState, setInternalState] = useState(isActive);

  const handleChange = () => {
    const newState = !internalState;
    setInternalState(newState); // Update internal state
    handleSwitchChange(newState); // Notify parent component of the change
  };

  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        name={name}
        type="checkbox"
        role="switch"
        onChange={handleChange}
        checked={internalState} // Controlled locally with internal state
        id={name}
      />
      <label className="custom-switch-label" htmlFor={name}>
        {label}
      </label>
      <style jsx>
        {`
          .form-check {
            position: relative;
          }

          .custom-switch-label {
            display: inline-block;
            width: 50px;
            height: 26px;
            background-color: #d9d9d9;
            border-radius: 13px;
            position: relative;
            cursor: pointer;
            transition: background-color 0.3s;
          }

          .form-check-input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .form-check-input:checked + .custom-switch-label {
            background-color: #3c3c3c;
          }

          .custom-switch-label::after {
            content: "";
            position: absolute;
            top: 1px;
            left: 1px;
            width: 24px;
            height: 24px;
            background-color: white;
            border-radius: 50%;
            transition: transform 0.3s;
          }

          .form-check-input:checked + .custom-switch-label::after {
            transform: translateX(24px);
          }
        `}
      </style>
    </div>
  );
};

export default CustomSwitchSubscription;
