import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";

import tick from "../../../../assets/img/tick-subscription.png";
import backToPlan from "../../../../assets/img/back-to-plan.png";
import CustomSwitchSubscription from "./CustomSwitchSubscription";
import loader from "../../../../assets/img/loader.gif";
import useApi from "../../../../utils/api-manager/Helper/useApi";
const CheckoutForm = (props) => {
  const { Post } = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clientSecret, setClientSecret] = useState(null); // Store clientSecret
  const stripe = useStripe();
  const elements = useElements();
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    nameOnCard: "",
    autoDebit: false,
  });
  const liStyle = {
    textAlign: "start",
    marginLeft: "-30px",
    width: "100%",
    position: "relative",
    lineHeight: "35px",
    fontSize: "16px",
    fontWeight: "500",
  };
  const liImgStyle = {
    height: "20px",
    width: "20px",
    position: "absolute",
    right: "13%",
    top: "5px",
  };
  const [checkBoxActive, setCheckBoxActive] = useState(false);
  useEffect(() => {
    // Fetch client secret on mount
  }, [clientSecret]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
    await Post("paymentIntent", {
        subscription_id: props.modelData.id,
      }).then(async (resp) => {
      
        if (resp.data.clientSecret) {
          setClientSecret(resp.data.clientSecret); // Store the client secret
          if (!stripe) {
            setError(
              "Stripe has not been initialized or client secret is missing."
            );
            return;
          }

          if (!elements) {
            setError("Stripe elements are not initialized");
            return;
          }
          if (!resp.data.clientSecret) {
            setError("Client secret is missing.");
            return;
          }

          // Get the individual card elements
          const cardNumberElement = elements.getElement(CardNumberElement);
          const cardExpiryElement = elements.getElement(CardExpiryElement);
          const cardCvcElement = elements.getElement(CardCvcElement);

          if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
            setError("All card details must be filled out.");
            setLoading(false);
            return;
          }

          const { error, paymentIntent } = await stripe.confirmCardPayment(
            resp.data.clientSecret,
            {
              payment_method: {
                card: cardNumberElement,
                billing_details: {
                  name: formData.nameOnCard,
                  email: formData.email,
                },
              },
            }
          );

          if (error) {
            setError(error.message);
          } else if (paymentIntent.status === "succeeded") {
            await Post("purchaseSubscription", {
              subscription_id: props.modelData.id,
              no_of_months: 1,
            })
              .then((resp) => {
                Swal.fire({
                  title: "Payment success",
                  text: `Payment received for ${props.modelData.title} plan.`,
                  icon: "success",
                });
                setLoading(false);
                props.handleClose();
          
              })
              .catch((err) => {
                Swal.fire({
                  title: "Payment Failed",
                  text: `Payment Failed for ${props.modelData.title} plan.`,
                  icon: "error",
                });
              });
            //Call Subscription Entry API Call here
          }

          setLoading(false);
        } else {
          setError("Failed to get payment client secret.");
        }
      });
    } catch (err) {
      setError("Error fetching client secret from backend.");
    }
  };

  const customStyles = {
    base: {
      fontSize: "16px",
      color: "#32325d",
      "::placeholder": {
        color: "#aab7c4",
      },
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="row" style={{ fontFamily: "Manrope" }}>
        {/* Left Column: Subscription Details */}
        <div
          className="col-md-6"
          style={{
            backgroundColor: "#4545DD",
            padding: "70px",
            color: "white",
          }}
        >
          <div className="row" style={{ marginTop: "2%", padding: "0px" }}>
            <div
              className="col-md-12"
              style={{ marginLeft: "0", padding: "0px", position: "relative" }}
            >
              <span
                style={{ position: "absolute", left: "0", top: "-50px" }}
                onClick={() => {
                  props.handleClose();
                }}
              >
                <img
                  src={backToPlan}
                  height="16px"
                  style={{ display: "inline", marginRight: "10px" }}
                />{" "}
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "inline",
                  }}
                >
                  Select Plan
                </p>
              </span>

              <p style={{ fontWeight: "500", fontSize: "20px" }}>
                Subscribe to {props.modelData.title}
              </p>
              {/* <p style={{fontWeight: '600', fontSize: '50px', display: "inline"}}
               
              >
                US ${props.modelData.amount}
                <span
                style={{
                  display: "inline",
                  fontSize: "16px",
                  fontWeight: '400',
                  width: "10px",
                  height: "50px",
                  marginLeft: "10px",

                }}
              >
               <span style={{ display: "block" }}>Per</span>
               <span style={{ display: "block" }}>Month</span>
              </span>
              </p> */}
              <table>
                <tr style={{ padding: "0px", margin: "0px" }}>
                  <td style={{ padding: "0px", margin: "0px" }}>
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "40px",
                        display: "inline",
                      }}
                    >
                      US ${props.modelData.amount}
                    </p>
                  </td>

                  <td style={{ paddingLeft: "7px", lineHeight: "18px" }}>
                    Per
                    <br />
                    Month
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                marginLeft: "-5px",
                backgroundColor: "#F4F6FF",
                borderRadius: "20px",
                width: "300px",
                marginTop: "30px",
                height: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label style={{ padding: "10px" }}>
                <span style={{ fontSize: "20px", color: "black" }}>
                  Auto Debit
                </span>
              </label>

              <CustomSwitchSubscription
                name="autoDebit"
                isActive={false}
                handleSwitchChange={(e) => setCheckBoxActive(true)}
              ></CustomSwitchSubscription>
            </div>
          </div>

          <div className="row" style={{ padding: "0px", marginTop: "20px" }}>
            <div className="col-md-12" style={{ padding: "0px" }}>
              <ul
                style={{
                  fontSize: "20px",
                  listStyleType: "none",
                  opacity: "80%",
                  fontWeight: "500",
                }}
              >
                {props.modelData.inc_features_titles !== undefined
                  ? props.modelData.inc_features_titles.map((features) => {
                      return (
                        <li style={liStyle}>
                          {features} <img src={tick} style={liImgStyle} />
                        </li>
                      );
                    })
                  : ""}
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12" style={{ position: "relative" }}>
              <p
                style={{
                  display: "inline",
                  marginLeft: "-12px",
                  fontWeight: "600",
                  fontSize: "30px",
                }}
              >
                Subtotal
              </p>
              <p
                style={{
                  display: "inline",
                  position: "absolute",
                  right: "20%",
                  fontWeight: "600",
                  fontSize: "30px",
                }}
              >
                ${props.modelData.amount}
              </p>
            </div>
          </div>
        </div>

        {/* Right Column: Payment Form */}
        <div
          className="col-md-6"
          style={{ backgroundColor: "#FFFF", padding: "50px" }}
        >
          <div className="col-md-12">
            <label style={{ marginTop: "10%", fontWeight: "600" }}>
              Email Address
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email Address"
              required
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginBottom: "10px",
                width: "100%",
              }}
            />
          </div>

          <div className="col-md-12">
            <label style={{ marginTop: "10px", fontWeight: "600" }}>
              Name on Card
            </label>
            <input
              type="text"
              name="nameOnCard"
              value={formData.nameOnCard}
              onChange={handleInputChange}
              placeholder="Name on Card"
              required
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginBottom: "10px",
                width: "100%",
              }}
            />

            <div>
              <label style={{ marginTop: "10px", fontWeight: "600" }}>
                Card Number
              </label>
              <CardNumberElement
                options={{ style: customStyles }}
                style={{ marginBottom: "10px", width: "100%" }}
              />
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <div style={{ flex: 1 }}>
                <label style={{ marginTop: "10px", fontWeight: "600" }}>
                  Expiry Date
                </label>
                <CardExpiryElement
                  options={{ style: customStyles }}
                  style={{ marginBottom: "10px", width: "100%" }}
                />
              </div>

              <div style={{ flex: 1 }}>
                <label style={{ marginTop: "10px", fontWeight: "600" }}>
                  CVC
                </label>
                <CardCvcElement
                  options={{ style: customStyles }}
                  style={{ marginBottom: "10px", width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-12">
              <Button
                type="submit"
                variant="primary"
                style={{
                  width: "100%",
                  height: "50px",
                  marginTop: "20px",
                  backgroundColor: "#FF9500",
                  border: "1px solid #FF9500",
                  color: "white",
                }}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <img src={loader} style={{ height: "30px" }} />{" "}
                       Processing...
                  </>
                ) : (
                  "Pay Now"
                )}
              </Button>

              {error && <div>{error}</div>}
            </div>
            <div className="col-md-12">
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              By clicking, you agree to our policies, which govern the use of
              our platform and outline your rights and responsibilities. This
              includes compliance with our terms of service, privacy practices,
              and any other applicable guidelines to ensure a secure and fair
              experience for all users.
            </p>
          </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm;
