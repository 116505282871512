/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// AddVendorModal.js
import React, { useState ,useContext} from "react";
import ReusableModal from "../ManageSettings/ReusableModal";
import { toast } from "react-toastify";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../utils/secure-route/AuthContext";

const AddVendorModal = ({ show, handleClose, reloadData }) => {
  const [vendorName, setVendorName] = useState("");
  const [vendorType, setVendorType] = useState("");
  const [vendorDepartments, setVendorDepartments] = useState([]);
  const [openingBalance, setOpeningBalance] = useState("");
  const [vendors, setVendors] = useState([]);
  const { user } = useContext(AuthContext);
  const storeId = user.store;
  const { Post, Get } = useApi();
  const [serviceData, setServiceData] = useState([]);

  // Fetch department and expense type options (you can modify this logic as needed)
  const fetchServices = async () => {
    try {
      const serviceResponse = await Get("vendorDepartmentServiceData");
      setServiceData(serviceResponse);
    } catch (error) {
      console.error("Error fetching services:", error);
      toast.error("Error fetching services!");
    }
  };

  // Fetch options on component mount
  React.useEffect(() => {
    fetchServices();
  }, []);

  const handleDepartmentChange = (event) => {
    const value = event.target.value;
    if (value && !vendorDepartments.includes(value)) {
      setVendorDepartments((prev) => [...prev, value]);
    }
  };

  const handleChipRemove = (id) => {
    setVendorDepartments((prev) => prev.filter((deptId) => deptId !== id));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const payload = {
      name: vendorName,
      type: vendorType,
      opening_balance: openingBalance,
      vendor_department_service: vendorDepartments,
      store: storeId,
    };

    try {
      const response = await Post("vendorDepartmentData", payload);
      setVendors((prev) => [...prev, response.data]);
      toast.success("Vendor added successfully!");
      // reloadData();
      handleClose();
     
      window.location.reload();
    } catch (error) {
      console.error("Error saving vendor:", error);
      toast.error("Error saving vendor!");
    }
  };

  const departmentOptions = serviceData
    .filter((service) => service.type === "department")
    .map((service) => ({ id: service.id, title: service.title }));

  const expenseTypeOptions = serviceData
    .filter((service) => service.type === "expense type")
    .map((service) => ({ id: service.id, title: service.title }));

  return (
    <ReusableModal
      show={show}
      handleClose={handleClose}
      title="Add Vendor"
      width="auto"
      height="auto"
    >
      <form onSubmit={handleSave}>
        <div className="form-group d-flex align-items-center mt-0">
          <input
            type="text"
            className="input-field"
            placeholder="Vendor Name"
            value={vendorName}
            onChange={(e) => setVendorName(e.target.value)}
            style={{ width: "400px", marginRight: "10px", height: "44px" }}

          />
          <select
            className="input-field"
            value={vendorType}
            onChange={(e) => {
              setVendorType(e.target.value);
              setVendorDepartments([]); // Reset departments when type changes
              setOpeningBalance("");
            }}
            style={{ width: "400px", marginRight: "10px", height: "44px" }}

          >
            <option value="">Vendor Type</option>
            <option value="purchase">Purchase</option>
            <option value="expense">Expense</option>
          </select>
        </div>
        {(vendorType === "purchase" || vendorType === "expense") && (
          <div className="form-group">
            <div className="chips-container">
              {vendorDepartments.map((deptId) => {
                const department =
                  vendorType === "purchase"
                    ? departmentOptions.find((d) => d.id === deptId)
                    : expenseTypeOptions.find((d) => d.id === deptId);
                return (
                  <div className="chip" key={deptId}>
                    {department?.title}
                    <span
                      className="chip-close"
                      onClick={() => handleChipRemove(deptId)}
                    >
                      &times;
                    </span>
                  </div>
                );
              })}
            </div>
            <select
              className="input-field"
              onChange={handleDepartmentChange}
              style={{ width: "400px", marginRight: "10px", height: "44px" }}
            >
              <option value="">
                {vendorType === "purchase"
                  ? "Select Vendor Department"
                  : "Select Expense Type"}
              </option>
              {(vendorType === "purchase"
                ? departmentOptions
                : expenseTypeOptions
              ).map((service) => (
                <option key={service.id} value={service.id}>
                  {service.title}
                </option>
              ))}
            </select>
            {vendorType === "purchase" && (
              <input
                type="number"
                className="input-field"
                placeholder="Opening Balance"
                value={openingBalance}
                onChange={(e) => setOpeningBalance(e.target.value)}
                style={{ width: "400px", marginRight: "10px", height: "44px" }}
              />
            )}
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <button
            type="submit"
            style={{
              marginLeft: "690px",
              padding: "4px 8px",
              backgroundColor: "#4545db",
              color: "white",
              border: "none",
              borderRadius: "40px",
              cursor: "pointer",
              width: "124px",
              height: "42px",
              fontSize: "18px",
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </ReusableModal>
  );
};

export default AddVendorModal;
