import React, { useState, useEffect } from "react";
import "./CustomPhoneInput.css"; // Import your custom styles

const CustomPhoneInput = ({ value, onChange, onBlur, error, errorMessage }) => {
  const [phoneNumber, setPhoneNumber] = useState(value || "+1");

  // Initialize to "+1" if not already set
  useEffect(() => {
    if (!phoneNumber || phoneNumber === "+") {
      setPhoneNumber("+1");
    }
  }, [phoneNumber]);

  // Handle changes in the phone number
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
  
    // Sanitize the input: Allow only "+1" as a prefix and digits after it
    if (inputValue.startsWith("+1")) {
      const sanitizedValue = "+1" + inputValue.slice(2).replace(/\D/g, ""); // Remove non-digit characters after "+1"
      setPhoneNumber(sanitizedValue);
      onChange(sanitizedValue); // Notify parent of the change
    } else if (inputValue === "") {
      setPhoneNumber("+1");
      onChange("+1"); // Reset to "+1" if empty
    } else {
      setPhoneNumber("+1");
      onChange("+1"); // Ensure it always starts with "+1"
    }
  };
  

  return (
    <div className="phone-input">
      <div className="phone-input-inner">
        {/* Static +1 prefix inside the input field */}
        <input
        
          type="tel"

          value={phoneNumber} // The input now shows "+1" and the user can input after it
          onChange={handleInputChange}
          onBlur={onBlur}
          className={`custom-input ${error ? "input-error" : ""}`}
          maxLength="12" // Allow 10 digits after "+1" (12 characters in total)
          placeholder="Enter phone number"
        />
       
      </div>
      {error && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default CustomPhoneInput;
