import React from 'react';
import PropTypes from 'prop-types';
import { getDynamicHelperText } from '../ValidationHelper/getDynamicHelperText'; // Adjust the path accordingly

const ValidationHelper = ({ placeholder, error, custom=false, msg="" }) => {
  const helperText = (custom)?error ? msg : '':error ? getDynamicHelperText(placeholder) : ''

  return (
    <div className="helper-text">
      {helperText && <span className="text-danger">{helperText}</span>}
    </div>
  );
};

ValidationHelper.propTypes = {
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
};

export default ValidationHelper;
