/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../utils/secure-route/AuthContext";

function GasReport() {
  const { Post, Get, Delete } = useApi();
  const today = new Date().toISOString().split("T")[0];
  const [gasCompanies, setGasCompanies] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useContext(AuthContext);



  const [newHouseCharge, setNewHouseCharge] = useState({
    company_id: '',
    company_title: '',
    amount: '',
    entry_no: null,
    id: null // Add this to track the specific entry being edited

  });
  const handleAddHouseCharge = async () => {
    // Validate inputs
    if (!newHouseCharge.company_id || !newHouseCharge.amount) {
      return;
    }

    try {
      // Prepare payload
      const payload = {
        gas_report: fullShiftData.gasReportData.id,
        entry_no: isEditing ? newHouseCharge.entry_no : fullShiftData.houseCharges.length + 1,
        company_title: gasCompanies.find(
          company => company.id === newHouseCharge.company_id
        )?.name || '',
        amount: parseFloat(newHouseCharge.amount),
        house_charge_id: isEditing ? newHouseCharge.id : undefined // Add this for editing specific entry
      };

      // Send API request to add or update house charge
      const response = await Post("updateGasReport", payload);

      if (response.error) {
        console.error('Error adding/updating house charge:', response.error);
        return;
      }

      // Refetch shift data to update house charges
      await refetchShiftData();

      // Reset new house charge inputs
      setNewHouseCharge({
        company_id: '',
        company_title: '',
        amount: '',
        entry_no: null,
        id: null
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Network error adding/updating house charge:', error);
    }
  };
  const handleEditHouseCharge = (charge) => {
    // Find the corresponding company ID
    const companyId = gasCompanies.find(
      company => company.name === charge.company_title
    )?.id || '';

    // Set the charge for editing
    setNewHouseCharge({
      company_id: companyId,
      company_title: charge.company_title,
      amount: charge.amount.toString(),
      entry_no: charge.entry_no,
      id: charge.id
    });
    setIsEditing(true);
  };

  const handleHouseChargeBlur = async () => {
    // Only proceed if both company and amount are selected/entered
    if (newHouseCharge.company_id && newHouseCharge.amount) {
      await handleAddHouseCharge();
    }
  };

  const handleHouseChargeCompanyChange = (e) => {
    setNewHouseCharge(prev => ({
      ...prev,
      company_id: e.target.value
    }));
  };

  const handleHouseChargeAmountChange = (e) => {
    setNewHouseCharge(prev => ({
      ...prev,
      amount: e.target.value
    }));
  };

  const handleCancelEdit = () => {
    setNewHouseCharge({
      company_id: '',
      company_title: '',
      amount: '',
      entry_no: null,
      id: null
    });
    setIsEditing(false);
  };

  const [fullShiftData, setFullShiftData] = useState({
    shifts: [],
    selectedShift: '',
    selectedDate: today,
    shiftDetails: {},
    gasTypes: [],
    gasReportData: {},
    gasTypeReports: {},
    businessReport: {},
    lotteryReport: {},
    registerNetSales: [],
    purchaseExpenses: [],
    lotteryRegisterReports: [],
    houseCharges: []
  });
  useEffect(() => {
    const fetchGasCompanies = async () => {
      try {
        // Add a new API endpoint method in your useApi hook
        const companiesResponse = await Get("gasCompanyData");

        if (Array.isArray(companiesResponse)) {
          setGasCompanies(companiesResponse);
        } else {
          console.error('Invalid response format for gas companies');
        }
      } catch (error) {
        console.error('Error fetching gas companies:', error);
      }
    };

    fetchGasCompanies();
  }, []);

  const handleDeleteHouseCharge = async (charge) => {
    try {

      const response = await Delete("houseChargeData", charge.id);

      if (response.error) {
        console.error('Error deleting house charge:', response.error);
        return;
      }
      await refetchShiftData();
      if (newHouseCharge.id === charge.id) {
        setNewHouseCharge({
          company_id: '',
          company_title: '',
          amount: '',
          entry_no: null,
          id: null
        });
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Network error deleting house charge:', error);
    }
  };

  // Fetch initial data and update full state
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [gasTypesResponse, shiftsResponse] = await Promise.all([
          Get("gasTypeData"),
          Post("getShiftDataWithRole", { date: fullShiftData.selectedDate })
        ]);

        // Filter active gas types
        const activeGasTypes = gasTypesResponse.filter(gasType => gasType.is_enable);

        const shiftData = Array.isArray(shiftsResponse?.data?.shift_data)
          ? shiftsResponse.data.shift_data
          : [];

        const sortedShifts = shiftData.sort((a, b) => {
          const timeA = new Date(a.shift.start_time).getTime();
          const timeB = new Date(b.shift.start_time).getTime();
          return timeA - timeB;
        });

        let selectedShift;
        if (sortedShifts.length === 0) {
          // No shifts available
          selectedShift = null;
        } else {
          // First, try to find a shift assigned to the current user
          const userShift = sortedShifts.find((shift) => shift.shift.user === user.user_id);

          if (userShift) {
            // If a shift is assigned to the current user, select that shift
            selectedShift = userShift;
          } else {
            // If no shift is assigned to the current user, select the last (most recent) shift
            selectedShift = sortedShifts[sortedShifts.length - 1];
          }
        }

        const gasTypeReportsMap = {};
        selectedShift?.gas_type_reports?.forEach(report => {
          gasTypeReportsMap[report.gas_type] = report;
        });

        setFullShiftData(prevState => ({
          ...prevState,
          shifts: sortedShifts,
          selectedShift: selectedShift?.shift?.title || '',
          shiftDetails: selectedShift || {},
          gasTypes: activeGasTypes,
          gasReportData: selectedShift?.gas_report || {},
          gasTypeReports: gasTypeReportsMap,
          businessReport: selectedShift?.business_report || {},
          lotteryReport: selectedShift?.lottery_report || {},
          registerNetSales: selectedShift?.register_net_sales || [],
          purchaseExpenses: selectedShift?.purchase_expenses || [],
          lotteryRegisterReports: selectedShift?.lottery_register_reports || [],
          houseCharges: selectedShift?.house_charges || []
        }));
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, [fullShiftData.selectedDate]);
  const handleShiftChange = (e) => {
    const selectedTitle = e.target.value;
    const selectedShiftDetails = fullShiftData.shifts.find(shift => shift.shift.title === selectedTitle);

    if (selectedShiftDetails) {
      const gasTypeReportsMap = {};
      selectedShiftDetails.gas_type_reports?.forEach(report => {
        gasTypeReportsMap[report.gas_type] = report;
      });

      setFullShiftData(prevState => ({
        ...prevState,
        selectedShift: selectedTitle,
        shiftDetails: selectedShiftDetails || {},
        gasReportData: selectedShiftDetails?.gas_report || {},
        gasTypeReports: gasTypeReportsMap,
        businessReport: selectedShiftDetails?.business_report || {},
        lotteryReport: selectedShiftDetails?.lottery_report || {},
        registerNetSales: selectedShiftDetails?.register_net_sales || [],
        purchaseExpenses: selectedShiftDetails?.purchase_expenses || [],
        lotteryRegisterReports: selectedShiftDetails?.lottery_register_reports || [],
        houseCharges: selectedShiftDetails?.house_charges || []
      }));
    }
  };
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setFullShiftData(prevState => ({
      ...prevState,
      selectedDate: newDate
    }));
  };

  const handleSpecialFieldChange = (field, value) => {
    setFullShiftData(prevState => ({
      ...prevState,
      gasReportData: {
        ...prevState.gasReportData,
        [field]: value // Directly update the special field in `gasReportData`
      }
    }));
  };

  const handleGasTypeFieldChange = (gasTypeTitle, field, value) => {
    setFullShiftData(prevState => ({
      ...prevState,
      gasTypeReports: {
        ...prevState.gasTypeReports,
        [gasTypeTitle]: {
          ...prevState.gasTypeReports[gasTypeTitle],
          [field]: value // Update specific field inside a specific gas type's report
        }
      }
    }));
  };



  const handleGasReportBlur = async (field) => {
    // Check for gas report ID
    if (!fullShiftData.gasReportData.id) {
      console.error('No gas report ID available');
      // Optional: Show a user-friendly error message
      // toast.error('Unable to update: No gas report found');
      return;
    }

    try {
      // Create a more comprehensive payload
      const payload = {
        gas_report: fullShiftData.gasReportData.id,
        shift_id: fullShiftData.shiftDetails.shift.id, // Include shift ID
        date: fullShiftData.selectedDate,
        [field]: fullShiftData.gasReportData[field]
      };

      // Add more logging for debugging

      const response = await Post("updateGasReport", payload);

      if (response.error) {
        // Handle potential error in response
        console.error(`Error updating gas report ${field}:`, response.error);
        // Optional: Show user-friendly error
        // toast.error('Failed to update gas report');
        return;
      }


      // Refetch shift data to ensure UI is in sync with backend
      await refetchShiftData();
    } catch (error) {
      console.error(`Network Error updating gas report ${field}:`, error);
      // Optional: Show user-friendly network error
      // toast.error('Network error: Unable to update gas report');
    }
  };

  const parseToFloat = (value) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const handleBlur = async (gasType) => {
    const data = fullShiftData.gasTypeReports[gasType];

    const gallonsVolume = data.gallons_volume ? parseToFloat(data.gallons_volume) : 0;
    const revenueTotal = data.revenue_total ? parseToFloat(data.revenue_total) : 0;
    const tankReading = data.tank_reading ? parseToFloat(data.tank_reading) : 0;
    const price = data.price ? parseToFloat(data.price) : 0;

    try {
      const response = await Post("updateGasReport", {
        gas_report: fullShiftData.gasReportData.id,
        gas_type: gasType,
        gallons_volume: gallonsVolume,
        revenue_total: revenueTotal,
        tank_reading: tankReading,
        price: price,
      });


      // Refetch shift data after updating gas report
      refetchShiftData();
    } catch (error) {
      console.error('Error updating gas report:', error);
    }
  };

  const refetchShiftData = async () => {
    try {
      const shiftsResponse = await Post("getShiftDataWithRole", { date: fullShiftData.selectedDate });
      const shiftData = Array.isArray(shiftsResponse?.data?.shift_data) ? shiftsResponse.data.shift_data : [];
      const sortedShifts = shiftData.sort((a, b) => new Date(a.shift.start_time) - new Date(b.shift.start_time));

      // Find the currently selected shift in the new data
      const currentlySelectedShiftDetails = sortedShifts.find(
        shift => shift.shift.title === fullShiftData.selectedShift
      );

      const gasTypeReportsMap = {};
      currentlySelectedShiftDetails?.gas_type_reports?.forEach(report => {
        gasTypeReportsMap[report.gas_type] = report;
      });

      setFullShiftData(prevState => ({
        ...prevState,
        shifts: sortedShifts,
        selectedShift: currentlySelectedShiftDetails?.shift?.title || prevState.selectedShift,
        shiftDetails: currentlySelectedShiftDetails || prevState.shiftDetails,
        gasReportData: currentlySelectedShiftDetails?.gas_report || prevState.gasReportData,
        gasTypeReports: gasTypeReportsMap,
        businessReport: currentlySelectedShiftDetails?.business_report || prevState.businessReport,
        lotteryReport: currentlySelectedShiftDetails?.lottery_report || prevState.lotteryReport,
        registerNetSales: currentlySelectedShiftDetails?.register_net_sales || prevState.registerNetSales,
        purchaseExpenses: currentlySelectedShiftDetails?.purchase_expenses || prevState.purchaseExpenses,
        lotteryRegisterReports: currentlySelectedShiftDetails?.lottery_register_reports || prevState.lotteryRegisterReports,
        houseCharges: currentlySelectedShiftDetails?.house_charges || prevState.houseCharges
      }));
    } catch (error) {
      console.error('Error refetching shift data:', error);
    }
  };
  const totalGallons = Object.values(fullShiftData.gasTypeReports).reduce((sum, report) =>
    sum + parseToFloat(report.gallons_volume), 0);

  const totalRevenue = Object.values(fullShiftData.gasTypeReports).reduce((sum, report) =>
    sum + parseToFloat(report.revenue_total), 0);

  const totalHouseCharges = fullShiftData.houseCharges.reduce((sum, charge) =>
    sum + parseToFloat(charge.amount), 0);


  const grandTotal = parseFloat(fullShiftData.gasReportData.grand_total || 0);

  return (
    <div className="gas-report">
      <div className="header-row">
        <div className="business-title">
          <div className="dropdown">
            <select
              id="shift-select"
              value={fullShiftData.selectedShift}
              className="dropdown-toggle shift"
              onChange={handleShiftChange}
              style={{ padding: "10px", marginBottom: "20px", fontSize: "16px", height: "44px" }}
            >
              {fullShiftData.shifts.length > 0 ? (
                fullShiftData.shifts.map((shiftItem) => (
                  <option key={shiftItem.shift.id} value={shiftItem.shift.title} className="shift-text">
                    {shiftItem.shift.title}
                  </option>
                ))
              ) : (
                <option value="">No active shifts available</option>
              )}
            </select>
          </div>
          <input
            className="form-control"
            type="date"
            name="reportDate"
            value={fullShiftData.selectedDate}
            onChange={handleDateChange}
            style={{ backgroundColor: "#f4f4f4", marginLeft: "10px", height: "44px" }}
          />
        </div>
      </div>

      {/* Gas Type Fields */}
      <div className="row">
        <div className="col-md-2">
          <p className="business-report-title">Gas Type</p>
        </div>
        <div className="col-md-2">
          <p className="business-report-title">Gallons/Volume</p>
        </div>
        <div className="col-md-2">
          <p className="business-report-title">Revenue/Total</p>
        </div>
        <div className="col-md-2">
          <p className="business-report-title">Price</p>
        </div>
        <div className="col-md-2">
          <p className="business-report-title">Tank Readings (in Liters)</p>
        </div>
      </div>
      {fullShiftData.gasTypes.length > 0 ? (
        fullShiftData.gasTypes.map((gasType) => {
          const typeReport = fullShiftData.gasTypeReports[gasType.gas_type_title] || {};
          return (
            <div key={gasType.id} className="row business-report">
              <div className="col-md-2">
                <div className="data-report">
                  <p>{gasType.gas_type_title}</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="data-report">
                  <input
                    type="number"
                    className="input"
                    value={typeReport.gallons_volume || ''}
                    onChange={(e) => handleGasTypeFieldChange(gasType.gas_type_title, 'gallons_volume', e.target.value)} // Use gas type handler
                    onBlur={() => handleBlur(gasType.gas_type_title)}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="data-report">
                  <input
                    type="number"
                    className="input"
                    value={typeReport.revenue_total || ''}
                    onChange={(e) => handleGasTypeFieldChange(gasType.gas_type_title, 'revenue_total', e.target.value)} // Use gas type handler
                    onBlur={() => handleBlur(gasType.gas_type_title)}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="data-report">
                  <p>{typeReport.price || gasType.cost_per_gallon}</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="data-report">
                  <input
                    type="number"
                    className="input"
                    value={typeReport.tank_reading || ''}
                    onChange={(e) => handleGasTypeFieldChange(gasType.gas_type_title, 'tank_reading', e.target.value)} // Use gas type handler
                    onBlur={() => handleBlur(gasType.gas_type_title)}
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p>No gas types found.</p>
      )}


      {/* Business Report Totals */}
      <div className="business-report">
        <div className="row mt-4 pr-2">
          <div className="col-md-2">
            <div className="data-report">
              <label className="label" style={{ marginRight: "10px" }}>
                Total Gallons
              </label>
            </div>
          </div>
          <div className="col-md-2">
            <div className="data-report">
              <input
                type="number"
                className="input"
                value={totalGallons || ''}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row mt-4 pr-2">
          <div className="col-md-2">
            <div className="data-report">
              <label className="label" style={{ marginRight: "10px" }}>
                Total Revenue
              </label>
            </div>
          </div>
          <div className="col-md-2">
            <div className="data-report">
              <input
                type="number"
                className="input"
                value={totalRevenue || ''}

              />
            </div>
          </div>
        </div>
        <div className="row mt-4 pr-2">
          <div className="col-md-2">
            <div className="data-report">
              <label className="label" style={{ marginRight: "10px" }}>
                Cash
              </label>
            </div>
          </div>
          <div className="col-md-2">
            <div className="data-report">
              <input
                type="number"
                className="input"
                value={fullShiftData.gasReportData.cash || ''}
                onChange={(e) => handleSpecialFieldChange('cash', e.target.value)}
                onBlur={() => handleGasReportBlur('cash')}
              />
            </div>
          </div>
        </div>
        <div className="row mt-4 pr-2">
          <div className="col-md-2">
            <div className="data-report">
              <label className="label" style={{ marginRight: "10px" }}>
                DCR Payments
              </label>
            </div>
          </div>
          <div className="col-md-2">
            <div className="data-report">
              <input
                type="number"
                className="input"
                defaultValue={fullShiftData.gasReportData.dcr_payments || ''}
                onChange={(e) => handleSpecialFieldChange('dcr_payments', e.target.value)}
                onBlur={() => handleGasReportBlur('dcr_payments')}
              />
            </div>
          </div>
        </div>
        <div className="row mt-4 pr-2">
          <div className="col-md-2">
            <div className="data-report">
              <label className="label" style={{ marginRight: "10px" }}>
                Credit Cards
              </label>
            </div>
          </div>
          <div className="col-md-2">
            <div className="data-report">
              <input
                type="number"
                className="input"
                value={fullShiftData.gasReportData.credit_cards || ''}
                onChange={(e) => handleSpecialFieldChange('credit_cards', e.target.value)}
                onBlur={() => handleGasReportBlur('credit_cards')}
              />
            </div>
          </div>
        </div>
        <div className="row mt-4 pr-2">
          <div className="col-md-2">
            <div className="data-report">
              <label className="label" style={{ marginRight: "10px" }}>
                Total House Charges
              </label>
            </div>
          </div>
          <div className="col-md-2">
            <div className="data-report">
              <input
                type="number"
                className="input"
                value={totalHouseCharges || ''}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row mt-4 pr-2">
          <div className="col-md-2">
            <div className="data-report">
              <label className="label" style={{ marginRight: "10px" }}>
                Grand Total
              </label>
            </div>
          </div>
          <div className="col-md-2">
            <div className="data-report">
              <input
                type="number"
                className="input"
                value={grandTotal || ''}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <h4>House Charges</h4>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-3">
  <div
    className="card shadow-sm"
    style={{
      width: "755px",
      borderTopLeftRadius: "30px",
      borderTopRightRadius: "30px",
    }}
  >
    <div
      className="card-header text-white text-center"
      style={{
        backgroundColor: "#f4f4f4",
        height: "55px",
        borderTopLeftRadius: "30px",
        borderTopRightRadius: "30px",
      }}
    >
    </div>

    <div className="house-charge-row mb-3 d-flex justify-content-center align-items-center">
      <div className="d-flex col-md-8 pt-4">
        <select
          className="input me-2 flex-grow-1 pt-"
          value={newHouseCharge.company_id}
          onChange={handleHouseChargeCompanyChange}
        >
          <option value="">Select Company</option>
          {gasCompanies.map((company) => (
            <option key={company.id} value={company.id}>
              {company.name}
            </option>
          ))}
        </select>

        <input
          type="number"
          className="input flex-grow-1"
          placeholder="Amount"
          value={newHouseCharge.amount}
          onChange={handleHouseChargeAmountChange}
          onBlur={handleHouseChargeBlur}
        />
      </div>
    </div>

    {isEditing && (
      <div className="d-flex justify-content-center mt-2">
        <button className="btn btn-contained btn-danger" onClick={handleCancelEdit}>
          Cancel
        </button>
      </div>
    )}

    <div className="d-flex justify-content-center mt-3">
      <div className="col-md-3 pb-3">
        <div className="financial-report">
          <label>Total House Charges</label>
          <input
            type="number"
            className="form-control"
            value={fullShiftData.houseCharges
              .reduce((sum, charge) => sum + parseFloat(charge.amount || 0), 0)
              .toFixed(2)}
            readOnly
          />
        </div>
      </div>
    </div>
    <div style={{ padding: '15px' }}> {/* Added padding around the table */}
    <table className="table table-hover align-middle mb-0">
  <thead className="table-light">
    <tr style={{ textAlign: 'center' }}>
      <th
        style={{
          alignContent: "center",
          border: 'none',
          borderTopLeftRadius: '30px', // Top-left rounded corner
          borderBottomLeftRadius: '30px', // Bottom-left rounded corner
          textAlign: 'center', // Center text horizontally
        }}
      >
        Company Title
      </th>
      <th
        style={{
          alignContent: "center",
          border: 'none',
          textAlign: 'center', // Center text horizontally
        }}
      >
        Amount
      </th>
      <th
        className="text-center"
        style={{
          alignContent: "center",
          border: 'none',
          borderTopRightRadius: '30px', // Top-right rounded corner
          borderBottomRightRadius: '30px', // Bottom-right rounded corner
          textAlign: 'center', // Center text horizontally
        }}
      >
        Action
      </th>
    </tr>
  </thead>

  <tbody>
    {fullShiftData.houseCharges.length === 0 ? (
      <tr>
        <td colSpan="3" className="text-center">
          No data available
        </td>
      </tr>
    ) : (
      fullShiftData.houseCharges.map((charge) => (
        <tr key={charge.id}>
          <td className="text-center">
            <span className="fw-bold" style={{ color: '#212529' }}>
              {charge.company_title}
            </span>
          </td>
          <td className="text-center">
            <span className="text-success fw-semibold">
              ${parseFloat(charge.amount).toFixed(2)}
            </span>
          </td>
          <td className="text-center">
            <div className="dropdown">
              <button
                className="btn btn-outline-secondary btn-sm"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-three-dots-vertical"></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => handleEditHouseCharge(charge)}
                  >
                    <i className="bi bi-pencil me-2"></i>Edit
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item text-danger"
                    onClick={() => handleDeleteHouseCharge(charge)}
                  >
                    <i className="bi bi-trash me-2"></i>Delete
                  </button>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      ))
    )}
  </tbody>
</table>

    </div>
  </div>
</div>


    </div>
  );
}

export default GasReport;
