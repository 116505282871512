/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./Invoice.css";
import TableComponent from "./BankDepositTable";
import useApi from "../../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../../utils/secure-route/AuthContext";

function BankDeposit() {
    const { user } = useContext(AuthContext);
    const { Get, Post } = useApi();
    const storeid = user.store;
    const navigate = useNavigate();
    const today = new Date().toISOString().split("T")[0]; // Formats date as YYYY-MM-DD
    const [isOpen, setIsOpen] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const dropdownRef = useRef(null);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const [formData, setFormData] = useState({
        date: "" || today,
        transaction_type: "Bank Deposit",
        bank: "",
        amount: "",
        cash_amount: "",
        cheque_amount: "",
        total_amount: "",
        note: "",
        atm_commission: "",
        income_type: null,
        bank_deposite_type: null,
        owner: null
    });

    const [banks, setBanks] = useState([]);

    useEffect(() => {
        const fetchBanks = async () => {
            try {
                const data = await Get("bank");
                setBanks(data);
            } catch (error) {
                console.error("Error fetching banks:", error);
            }
        };


        fetchBanks();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleLinkClick = (path) => {
        setIsOpen(false); // Close the dropdown after clicking a link
        navigate(path); // Navigate to the clicked path
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        // Allow only numbers and restrict the input to 10 digits
        if (name === 'cash_amount' || name === 'cheque_amount') {
            // If the value is numeric and length is <= 10, update the formData
            if (/^\d{0,10}(\.\d{0,2})?$/.test(value)) {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: value
                }));
            }
        } else {
            // For other fields, handle the change normally
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the selected type
        if (!formData.bank_deposite_type || formData.bank_deposite_type === "") {
            toast.error("Please select a valid Deposite type."); // Show error toast
            return; // Stop the submission if validation fails
        }

        try {
            const updatedFormData = { ...formData, store: storeid };
            console.log("Submitting data:", updatedFormData); // Log the data being submitted

            const response = await Post("transactionData", updatedFormData);

            // Assuming response is an object that includes a status property
            if (response && (response.status === 200 || response.status === 201)) {
                toast.success("Submission successful!"); // Success toast

                // Clear the form data
                setFormData({
                    date: today,
                    transaction_type: "Bank Deposit",
                    bank: "",
                    amount: "",
                    cash_amount: "",
                    cheque_amount: "",
                    total_amount: "",
                    note: "",
                    atm_commission: "",
                    income_type: null,
                    bank_deposite_type: null,
                    owner: null
                });

                setReloadData(!reloadData); // Optionally reload the data

                // Optionally close dropdown and reset other states
                setIsOpen(false);
            } else {
                setReloadData(!reloadData);
                // Handle unexpected status codes
                throw new Error(`Error: ${response.statusText || "Unknown error"}`); // Improved error handling
            }
        } catch (error) {
            setReloadData(!reloadData);
            console.error("Error submitting form:", error); // Log the error details
            toast.error("Submission failed: " + error.message); // Show error toast
        }
    };


    return (
        <div className="dashboard-container">
            <ToastContainer />
            <div className="formcontainer">
                <div
                    className="container"
                    style={{
                        backgroundColor: "white",
                        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)"
                    }}
                >
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-between mb-4">
                            <div className="dropdown" ref={dropdownRef}>
                                <button
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    onClick={toggleDropdown}
                                >
                                    Bank Deposit
                                    <svg
                                        width="16"
                                        height="10"
                                        viewBox="0 0 16 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <path
                                            d="M8.07608 9.20648C7.97615 9.20775 7.87708 9.18722 7.78523 9.14622C7.69338 9.10521 7.61078 9.04463 7.54275 8.96839L0.685603 1.82553C0.380841 1.50807 0.380841 1.016 0.685603 0.698544C0.990365 0.381084 1.46275 0.381084 1.76751 0.698544L8.09132 7.28585L14.3999 0.714417C14.7046 0.396957 15.177 0.396957 15.4818 0.714417C15.7866 1.03188 15.7866 1.52394 15.4818 1.8414L8.62465 8.98426C8.47227 9.14299 8.27417 9.22235 8.09132 9.22235L8.07608 9.20648Z"
                                            fill="#002300"
                                        />
                                    </svg>
                                </button>
                                {isOpen && (
                                    <ul
                                        className="dash-drop-menu"
                                        aria-labelledby="dropdownMenuButton"
                                    >
                                        <li>
                                            <a
                                                className="dash-drop-menu-item"
                                                onClick={() => handleLinkClick("/")}
                                            >
                                                Add Invoice
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dash-drop-menu-item"
                                                onClick={() => handleLinkClick("/payInvoice")}
                                            >
                                                Pay Invoice
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dash-drop-menu-item"
                                                onClick={() => handleLinkClick("/bankDeposit")}
                                            >
                                                Bank Deposit
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dash-drop-menu-item"
                                                onClick={() => handleLinkClick("/atmDeposit")}
                                            >
                                                ATM Deposit
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-contained me-2"
                                    onClick={() => navigate("/")}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>

                        <div className="col-md-12 row mb-3">
                            <div className="mb-3 col-md-4">
                                <input
                                    type="date"
                                    className="form-control-borderless"
                                    name="date"
                                    value={formData.date || today}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3 col-md-4">
                                <select
                                    className="form-control-borderless"
                                    name="bank"
                                    value={formData.bank}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select a bank</option>
                                    {banks.map((bank) => (
                                        <option key={bank.id} value={bank.id}>
                                            {bank.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3 col-md-4">
                                <input
                                    type="text"
                                    className="form-control-borderless"
                                    name="note"
                                    value={formData.note}
                                    onChange={handleChange}
                                    placeholder="Notes"
                                />
                            </div>
                        </div>

                        <div className="col-md-12 row">
                            <div className="mb-3 col-md-12">
                                <div className="d-flex flex-row">
                                    Type:&nbsp; &nbsp; &nbsp;
                                    {["Business cash", "Lottery cash", "Gas cash"].map((type) => (
                                        <div className="form-check me-3" key={type}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="bank_deposite_type"
                                                id={`type-${type.toLowerCase().replace(" ", "-")}`}
                                                value={type}
                                                checked={formData.bank_deposite_type === type}
                                                onChange={handleChange}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={`type-${type.toLowerCase().replace(" ", "-")}`}
                                            >
                                                {type}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-md-12 row mt-4">
                                <div
                                    className="form-group col-md-5"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        borderBottom: "1px solid #f4f4f4",
                                        paddingBottom: "16px",
                                        height: "50px",
                                        marginLeft: "10px"
                                    }}
                                >
                                    <div className="input-group">
                                        <span className="input-group-text" style={{ border: "none" }}>
                                            USD
                                        </span>
                                        <input
                                            type="text" // Change to text because we want to handle the regex validation manually
                                            style={{ border: "none" }}
                                            className="form-control-borderless"
                                            name="cash_amount"
                                            placeholder="Cash Amount"
                                            value={formData.cash_amount || ""}
                                            onChange={handleChange}
                                            maxLength={10} // Limit input to 10 digits
                                        />
                                    </div>

                                </div>
                                <div
                                    className="form-group col-md-5"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        borderBottom: "1px solid #f4f4f4",
                                        paddingBottom: "16px",
                                        height: "50px",
                                        marginLeft: "10px"
                                    }}
                                >
                                    <div className="input-group">
                                        <span
                                            className="input-group-text"
                                            style={{ border: "none" }}
                                        >
                                            USD
                                        </span>
                                        <input
                                            type="number"
                                            style={{ border: "none" }}
                                            className="form-control-borderless"
                                            name="cheque_amount"
                                            placeholder="Cheque Amount"
                                            value={formData.cheque_amount}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div
                className="container mt-5"
                style={{
                    backgroundColor: "white",
                    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)"
                }}
            >
                <TableComponent reloadData={reloadData} />
            </div>
        </div>
    );
}

export default BankDeposit;
