import React, { useEffect, useState } from "react";

const containerStyle = {
  //   display: "grid",
  //   gridTemplateColumns: "repeat(3, 1fr)",
  //   gap: "20px",
  paddingTop: "20px",
  paddingBottom: "20px",
};

const sectionStyle = {
  //   display: "flex",
  //   flexDirection: "column",
  //   //   border: "1px solid red",
  borderRadius: "10px",
  backgroundColor: "#f9f9f9",
  padding: "20px",
  boxShadow: "0 0 10px 1px rgba(0, 0, 0, 0.3)",
};

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  marginTop: "10px",
};

const thStyle = {
  backgroundColor: "#f1f1f1",
  fontWeight: "700",
  border: "1px solid #ddd",
  padding: "8px",

  textAlign: "center",
};

const thStyleRight = {
  fontWeight: "700",
  backgroundColor: "#f1f1f1",
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "right",
};
const thStyleLeft = {
  backgroundColor: "#f1f1f1",
  fontWeight: "bold",
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "left",
};

const tdStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "center",
};

const tdStyleRight = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "right",
  width: "50%",
};
const tdStyleRight25 = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "right",
  width: "25%",
};

const tdStyleLeft25 = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "left",
  width: "25%",
};

const tdStyleLeft = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "left",
  width: "50%",
};

const tdStyle25 = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "center",
  width: "25%",
};

const titleStyle = {
  justifyContent: "center",
  textAlign: "center",
  alignItems: "center",
  fontSize: "20px",
  color: "#2084e9",
};

const totalStyle = {
  backgroundColor: "#d6ebff",
  fontWeight: "bold",
  border: "1px solid #ccc",
  padding: "8px",
  width: "33.33%",
  textAlign: "center",
};

const Report = () => {
  const [printData, setPrintData] = useState();
  useEffect(() => {
    setPrintData(JSON.parse(localStorage.getItem("printDailyReport")));
  }, []);

  const [print, setPrint] = useState(false);
  const proceedPrint = () => {
    setPrint(true);
    setTimeout(() => window.print(), 500);
  };
  return printData !== undefined ? (
    <div style={{ padding: "20px", backgroundColor: "#f2f2f2" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "10px",
            width: "100%",
            height: "40px",
            padding: "20px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
            background: "#ffffff",
          }}
        >
          <h6 style={{ margin: 0, fontWeight: "bold" }}>
            {printData.store_name}
          </h6>

          <h6 style={{ margin: 0, fontWeight: "bold" }}>
            Shift: {printData.shift}
          </h6>

          <h6 style={{ margin: 0, fontWeight: "bold" }}>
            Report Date: {printData.date}
          </h6>
        </div>
      </div>
      {!print ? (
        <button
          onClick={() => {
            proceedPrint();
          }}
          style={{ position: "fixed", right: "10px", top: "50px" }}
          className="btn btn-success printBtn"
        >
          {" "}
          <i className="fa fa-print"></i> Print (A3 Page)
        </button>
      ) : (
        ""
      )}
      <div style={containerStyle} className="row">
        {/* DAILY REGISTER CASH OUT */}
        <div
          className="col-md-3"
          style={
            !print
              ? {
                  display: "flex",
                  height: "700px", // Fixed height for the container
                }
              : {}
          }
        >
          <div
            style={
              !print
                ? {
                    borderRadius: "10px",
                    backgroundColor: "#f9f9f9",
                    padding: "20px",
                    boxShadow: "0 0 10px 1px rgba(0, 0, 0, 0.3)",
                    width: "100%",
                    height: "100%", // Occupy full height of the container
                    overflowY: "auto", // Vertical scroll if content exceeds container height
                    borderRight: "1px solid #ccc", // Optional: Separator between tables
                    paddingRight: "5px", // Optional: Small padding for better scroll visibility
                  }
                : sectionStyle
            }
          >
            <h3 style={titleStyle}>💵 DAILY REGISTER CASH OUT</h3>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyleRight}>Name</th>
                  <th style={thStyleLeft}>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={tdStyleRight}>Business On report</td>
                  <td style={tdStyleLeft}>$ {printData.business_on_report}</td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Tax On Report</td>
                  <td style={tdStyleLeft}>$ {printData.tax_on_report}</td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Taxable Sales</td>
                  <td style={tdStyleLeft}>$ {printData.taxable_sales}</td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Non-Taxable Sales</td>
                  <td style={tdStyleLeft}>$ {printData.non_taxable_sales}</td>
                </tr>
              </tbody>
            </table>

            {/* Cash Purchase/Expense Section */}
            <br />
            <h3 style={titleStyle}>💸 Cash Purchase/Expense</h3>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>No.</th>
                  <th style={thStyle}>Vendor</th>
                  <th style={thStyle}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {printData.cash_purchase_expense !== undefined
                  ? printData.cash_purchase_expense.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td style={tdStyle}>{index + 1}</td>
                          <td style={tdStyle}>{item.vendor}</td>
                          <td style={tdStyle}>{item.amount}</td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>

            <br />

            <h3 style={titleStyle}>🧾 Others</h3>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>No.</th>
                  <th style={thStyle}>Name</th>
                  <th style={thStyle}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {printData.others.map !== undefined
                  ? printData.others.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td style={tdStyle}>{index + 1}</td>
                          <td style={tdStyle}>{item.name}</td>
                          <td style={tdStyle}>{item.amount}</td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
            <br />
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyleRight}>Name</th>
                  <th style={thStyleLeft}>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={tdStyleRight}>Total Payout</td>
                  <td style={tdStyleLeft25}>$ {printData.total_payout}</td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Total Other</td>
                  <td style={tdStyleLeft25}>$ {printData.total_other}</td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Refund</td>
                  <td style={tdStyleLeft25}>$ {printData.refund}</td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Check</td>
                  <td style={tdStyleLeft25}>$ {printData.cheque}</td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Credit Card</td>
                  <td style={tdStyleLeft25}>$ {printData.credit_card}</td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Customer Credits</td>
                  <td style={tdStyleLeft25}>$ {printData.customer_credits}</td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Customer Debits</td>
                  <td style={tdStyleLeft25}>$ {printData.customer_debits}</td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Register Cash To Account For</td>
                  <td style={tdStyleLeft25}>
                    $ {printData.register_cash_to_account_for}
                  </td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Actual Register Cash</td>
                  <td style={tdStyleLeft25}>
                    $ {printData.actual_register_cash}
                  </td>
                </tr>
                <tr>
                  <td style={tdStyleRight}>Business Over/Short</td>
                  <td style={tdStyleLeft25}>
                    $ {printData.business_over_short}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* DAILY LOTTERY REPORT */}
        <div
          className="col-md-6"
          style={
            !print
              ? {
                  display: "flex",
                  height: "700px", // Fixed height for the container
                }
              : sectionStyle
          }
        >
          <div
            style={
              !print
                ? {
                    borderRadius: "10px",
                    backgroundColor: "#f9f9f9",
                    padding: "20px",
                    boxShadow: "0 0 10px 1px rgba(0, 0, 0, 0.3)",
                    width: "100%",
                    height: "100%", // Occupy full height of the container
                    overflowY: "auto", // Vertical scroll if content exceeds container height
                    borderRight: "1px solid #ccc", // Optional: Separator between tables
                    paddingRight: "5px", // Optional: Small padding for better scroll visibility
                  }
                : {}
            }
          >
            <h3 style={titleStyle}>🎰 DAILY LOTTERY REPORT</h3>
            <div style={{ display: "flex", marginTop: "10px" }}>
              {/* First Table - 70% */}
              <table
                style={{
                  width: "70%",
                  borderCollapse: "collapse",
                  marginRight: "10px", // Adds a small gap between tables
                }}
              >
                <thead>
                  <tr>
                    <th style={thStyle} colSpan={2}>
                      Today Invoice
                    </th>
                    <th style={thStyle} colSpan={2}>
                      Yesterday Invoice
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={tdStyleRight25}>Online Net Sale</td>
                    <td style={tdStyleLeft25}>
                      $ {printData.online_net_sale_today}
                    </td>
                    <td style={tdStyleRight25}>
                      $ {printData.online_net_sale_yesterday}
                    </td>
                    <td style={tdStyleLeft25}>Online Net Sale</td>
                  </tr>
                  <tr>
                    <td style={tdStyleRight25}>Online Cashing</td>
                    <td style={tdStyleLeft25}>
                      $ {printData.online_cashing_today}
                    </td>
                    <td style={tdStyleRight25}>
                      $ {printData.online_cashing_yesterday}
                    </td>
                    <td style={tdStyleLeft25}>Online Cashing</td>
                  </tr>
                  <tr>
                    <td style={tdStyleRight25}>Instant Cashing</td>
                    <td style={tdStyleLeft25}>
                      $ {printData.instant_cashing_today}
                    </td>
                    <td style={tdStyleRight25}>
                      $ {printData.instant_cashing_yesterday}
                    </td>
                    <td style={tdStyleLeft25}>Instant Cashing</td>
                  </tr>
                  <tr>
                    <td style={tdStyleRight25}>Instant Sale</td>
                    <td style={tdStyleLeft25}>
                      $ {printData.instant_sale_today}
                    </td>
                    <td style={tdStyleRight25}></td>
                    <td style={tdStyleLeft25}></td>
                  </tr>
                </tbody>
              </table>

              {/* Second Table - 30% */}
              <table
                style={{
                  width: "30%",
                  borderCollapse: "collapse",
                }}
              >
                <tbody>
                  <tr>
                    <td style={tdStyleRight25}>Debit/Credit Card</td>
                    <td style={tdStyleLeft25}>
                      $ {printData.debit_credit_card}
                    </td>
                  </tr>
                  <tr>
                    <td style={tdStyleRight25}>Credits Sale</td>
                    <td style={tdStyleLeft25}>$ {printData.credit_sales}</td>
                  </tr>
                  <tr>
                    <td style={tdStyleRight25}>Debits Sale</td>
                    <td style={tdStyleLeft25}>$ {printData.debit_sales}</td>
                  </tr>
                  <tr>
                    <td style={tdStyleRight25}>Instant Balance</td>
                    <td style={tdStyleLeft25}>$ {printData.instant_balance}</td>
                  </tr>
                  <tr>
                    <td style={tdStyleRight25}>Online Balance</td>
                    <td style={tdStyleLeft25}>$ {printData.online_balance}</td>
                  </tr>
                  <tr>
                    <td style={tdStyleRight25}>Total Balance</td>
                    <td style={tdStyleLeft25}>$ {printData.total_balance}</td>
                  </tr>
                  <tr>
                    <td style={tdStyleRight25}>Register Cash</td>
                    <td style={tdStyleLeft25}>$ {printData.register_cash}</td>
                  </tr>
                  <tr>
                    <td style={tdStyleRight25}>Over/Short</td>
                    <td style={tdStyleLeft25}>
                      $ {printData.lottery_over_short}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Footer rows */}
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <td style={totalStyle}>
                    Total Lottery Sales ($ {printData.total_lottery_sales})
                  </td>
                  <td style={totalStyle}>$ {printData.total_lottery_sales1}</td>
                  <td style={totalStyle}>$ {printData.total_lottery_sales2}</td>
                </tr>
                <tr>
                  <td style={totalStyle}>
                    Total Lottery Cashing ($ {printData.total_lottery_cashing})
                  </td>
                  <td style={totalStyle}>
                    $ {printData.total_lottery_cashing1}
                  </td>
                  <td style={totalStyle}>
                    $ {printData.total_lottery_cashing2}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h3 style={titleStyle}>📗 ACTIVATED BOOKS</h3>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Game Number</th>
                  <th style={thStyle}>Book Number</th>
                  <th style={thStyle}>Count</th>
                  <th style={thStyle}>Value</th>
                </tr>
              </thead>
              <tbody>
                {printData.activated_books !== undefined
                  ? printData.activated_books.map((book, index) => (
                      <tr key={index}>
                        <td style={tdStyle25}>{book.game_no}</td>
                        <td style={tdStyle25}>{book.book_no}</td>
                        <td style={tdStyle25}>{book.count}</td>
                        <td style={tdStyle25}>{book.value}</td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>

            <br />
            <h3 style={titleStyle}>📕 RETURNED BOOKS</h3>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Game Number</th>
                  <th style={thStyle}>Ticket Number</th>
                  <th style={thStyle}>Value</th>
                </tr>
              </thead>
              <tbody>
                {printData.returned_books !== undefined
                  ? printData.returned_books.map((book, index) => (
                      <tr key={index}>
                        <td style={tdStyle25}>{book.game_no}</td>
                        <td style={tdStyle}>{book.ticket_number}</td>

                        <td style={tdStyle}>{book.value}</td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-3">
          {/* GAS DAILY REPORT */}
          <div style={sectionStyle}>
            <h3 style={titleStyle}>⛽ GAS DAILY REPORT</h3>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Gas Type</th>
                  <th style={thStyle}>Gallon</th>
                  <th style={thStyle}>Price</th>
                  <th style={thStyle}>Revenue</th>
                </tr>
              </thead>
              <tbody>
                {printData.gas_type_gallon_price_revenue !== undefined
                  ? printData.gas_type_gallon_price_revenue.map(
                      (gas, index) => (
                        <tr key={index}>
                          <td style={tdStyleRight}>{gas.gas_type}</td>
                          <td style={tdStyle25}>{gas.gallon}</td>
                          <td style={tdStyle25}>$ {gas.price}</td>
                          <td style={tdStyle25}>$ {gas.revenue}</td>
                        </tr>
                      )
                    )
                  : ""}
                <tr>
                  <td style={tdStyleRight}>
                    <strong>Total</strong>
                  </td>
                  <td style={tdStyle25}>{printData.total_gallon}</td>
                  <td style={tdStyle25}>-</td>
                  <td style={tdStyle25}>$ {printData.total_revenue}</td>
                </tr>
                <tr>
                  <td colSpan={3} style={tdStyleRight}>
                    <strong>Cash</strong>
                  </td>
                  <td style={tdStyleLeft}>$ {printData.cash}</td>
                </tr>
                <tr>
                  <td colSpan={3} style={tdStyleRight}>
                    <strong>DCR payments</strong>
                  </td>
                  <td style={tdStyleLeft}>$ {printData.dcr_payments}</td>
                </tr>
                <tr>
                  <td colSpan={3} style={tdStyleRight}>
                    <strong>Credit Card</strong>
                  </td>
                  <td style={tdStyleLeft}>$ {printData.credit_cards}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* LOTTERY TICKETS */}
      <div style={sectionStyle}>
        <h3 style={titleStyle}>🎟 LOTTERY TICKETS</h3>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>Ticket No.</th>
              <th style={thStyle}>Value</th>
              <th style={thStyle}>Game Name</th>
              <th style={thStyle}>Game Number</th>
              <th style={thStyle}>Book Number</th>
              <th style={thStyle}>Yesterday Ticket No</th>
              <th style={thStyle}>Today Ticket No</th>
              <th style={thStyle}>Quantity Sold</th>
              <th style={thStyle}>Today Sold</th>
            </tr>
          </thead>
          <tbody>
            {printData.lottery_tickets !== undefined
              ? printData.lottery_tickets.map((ticket, index) => (
                  <tr key={index}>
                    <td style={tdStyle}>{ticket.today_ticket_no}</td>
                    <td style={tdStyle}>{ticket.value}</td>
                    <td style={tdStyle}>{ticket.game_name}</td>
                    <td style={tdStyle}>{ticket.game_no}</td>
                    <td style={tdStyle}>{ticket.book_no}</td>
                    <td style={tdStyle}>{ticket.last_ticket_no}</td>
                    <td style={tdStyle}>{ticket.today_ticket_no}</td>
                    <td style={tdStyle}>{ticket.quantity_sold}</td>
                    <td style={tdStyle}>{ticket.today_sold}</td>
                  </tr>
                ))
              : ""}

            <tr>
              <td
                colSpan={7}
                style={{ ...tdStyle, textAlign: "right", fontWeight: "bold" }}
              >
                Grand Total
              </td>
              <td style={tdStyle}>
                <strong>{printData.grand_total_of_quantity_sold}</strong>
              </td>
              <td style={tdStyle}>
                <strong>{printData.grand_total_of_today_sold}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Report;
