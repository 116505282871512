/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

const ReusableModal = ({
  show,
  handleClose,
  title,
  children,
  width = "1080px",
  height = "auto",
}) => {
  // Function to handle clicks outside of the modal to close it
  const handleOutsideClick = (e) => {
    // Close the modal if the click is on the overlay (background) and not on the modal content
    if (e.target.classList.contains("modal-overlay")) {
      handleClose();
    }
  };

  // Add event listener on mount and remove on unmount
  useEffect(() => {
    if (show) {
      // Attach the event listener when modal is shown
      document.addEventListener("click", handleOutsideClick);
    } else {
      // Remove the event listener when modal is hidden
      document.removeEventListener("click", handleOutsideClick);
    }

    // Clean up event listener when component is unmounted or modal is hidden
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [show]);

  const modalOverlayStyle = {
    display: show ? "flex" : "none", // Modal visibility based on 'show' prop
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000, // to ensure it appears above other content
  };

  const modalContentStyle = {
    borderRadius: "15px",
    overflow: "hidden",
    width: width, // use the width prop
    height: height, // use the height prop
    maxWidth: "100%", // prevent exceeding the viewport
    maxHeight: "80%", // maximum height to avoid overflow
    overflowY: "auto", // allow vertical scrolling if content exceeds max height
  };

  const headerStyle = {
    backgroundColor: "#F0F0F0",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    height: "55px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const titleStyle = {
    fontFamily: "'Manrope', sans-serif",
    fontWeight: "600", // semibold
    fontSize: "20px",
    color: "#002300",
    margin: 0, // remove default margin
  };

  return (
    <div
      className="modal-overlay"
      style={modalOverlayStyle}
      tabIndex="-1"
      role="dialog"
      // Click event is added directly to the overlay
      onClick={handleOutsideClick}
    >
      <div className="modal-content" style={modalContentStyle}>
        <div className="modal-header" style={headerStyle}>
          <h5 className="modal-title" style={titleStyle}>
            {title}
          </h5>
          <svg
            onClick={handleClose}
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.4226 6.45104C20.5153 6.36563 20.5888 6.26421 20.6389 6.15258C20.6891 6.04094 20.715 5.92127 20.7151 5.80041C20.7152 5.67954 20.6894 5.55985 20.6394 5.44815C20.5894 5.33646 20.516 5.23496 20.4235 5.14944C20.331 5.06393 20.2211 4.99607 20.1001 4.94975C19.9792 4.90343 19.8496 4.87955 19.7186 4.87947C19.5877 4.8794 19.458 4.90313 19.337 4.94931C19.216 4.9955 19.1061 5.06323 19.0134 5.14864L13.0005 10.699L6.98928 5.14864C6.80218 4.97593 6.54841 4.87891 6.28381 4.87891C6.01921 4.87891 5.76545 4.97593 5.57835 5.14864C5.39125 5.32135 5.28613 5.55559 5.28613 5.79984C5.28613 6.04409 5.39125 6.27833 5.57835 6.45104L11.5913 11.9998L5.57835 17.5486C5.4857 17.6342 5.41221 17.7357 5.36208 17.8474C5.31194 17.9591 5.28613 18.0789 5.28613 18.1998C5.28613 18.3208 5.31194 18.4405 5.36208 18.5523C5.41221 18.664 5.4857 18.7655 5.57835 18.851C5.76545 19.0237 6.01921 19.1208 6.28381 19.1208C6.41483 19.1208 6.54456 19.097 6.66561 19.0507C6.78665 19.0044 6.89664 18.9366 6.98928 18.851L13.0005 13.3006L19.0134 18.851C19.2005 19.0235 19.4542 19.1204 19.7186 19.1202C19.9831 19.1201 20.2366 19.0229 20.4235 18.8502C20.6104 18.6775 20.7152 18.4434 20.7151 18.1993C20.7149 17.9552 20.6097 17.7211 20.4226 17.5486L14.4097 11.9998L20.4226 6.45104Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default ReusableModal;
