import React from "react";
import FormLayout from "./FormLayout";
import logo from "../../assets/img/EZHISAB.png";
import topimage from "../../assets/img/Group 228723.png"
import backgroundImage from "../../assets/img/Frame 812.png";
import LoginPage from "./WithUi/LoginPage";
function Login() {
  return (
    <FormLayout logoSrc={logo} backgroundImageSrc={backgroundImage} TopImage={topimage}> 
      <LoginPage />
    </FormLayout>
  );
}
export default Login;
