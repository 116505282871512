import React, { useEffect, useState } from "react";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuditTrailTable from "./AuditTrailTable";

export default function AuditTrail() {
  const { Get } = useApi();
  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleGetMethod = async () => {
      try {
        const response = await Get("auditLog");
        // Ensure response and results exist before setting state
        if (response) {
          setAuditLogs(response); // Update auditLogs with the data
        } else {
          setAuditLogs([]); // Set an empty array if data is undefined
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    handleGetMethod();
  }, );
  
  return (
    <div className="dashboard-container">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <AuditTrailTable
        auditLogs ={auditLogs} // Data is coming from API

        />
      )}
    </div>
  );
}
