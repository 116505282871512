/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../utils/secure-route/AuthContext";
import CustomInput from "../../custominput/CustomInput";

const LoginPage = () => {
  const { loginUser } = useContext(AuthContext);
  const { authToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const checkLoggedIn = () => {
    if (authToken) {
      navigate("/");
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, [authToken]);

  const [errors, setErrors] = useState({});
  const [customErr, setCustomErr] = useState(false);
  const [customErrMsg, setCustomErrMsg] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const checkValidUsername = (username) => {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(username)) {
      setErrors((prevErrors) => ({ ...prevErrors, username: true }));
      setCustomErr(true);
      setCustomErrMsg("Please Enter a Valid Email as Username!");
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, username: "" }));
      setCustomErrMsg("");
    }
  };

  const [passError, setPassError] = useState(false);
  const [passErrMsg, setPassErrMsg] = useState("");

  const checkValidPassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>_\-])[A-Za-z\d!@#$%^&*(),.?":{}|<>_\-]{8,}$/;;
    if (!passwordRegex.test(password)) {
      setErrors((prevErrors) => ({ ...prevErrors, password: true }));
      setPassError(true);
      setPassErrMsg(
        "Password must be at least 8 characters, with one uppercase letter, one digit, and one special character."
      );
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
      setPassErrMsg("");
    }
  };

  return (
    <div className="container" style={{ maxWidth: "500px" }}>
      <div className="row justify-content-center align-items-center">
        <p className="card-title mb-3 mt-4" style={{ fontWeight: "700", fontSize: "30px" }}>
          Welcome Back
        </p>
        <p className="mb-3" style={{ fontSize: "14px", color: "#002300" }}>
          Ready to manage your ledger? Sign in to track your transactions and stay on top of your financials!
        </p>
        <form onSubmit={loginUser}>
          <div className="mb-3">
            <CustomInput
              type="text"
              id="username"
              name="username"
              placeholder="Enter Email"
              onBlur={(e) => {
                checkValidUsername(e.target.value);
              }}
              custom={customErr}
              msg={customErrMsg}
              required
              error={Boolean(errors.username)}
              helpertext={errors.username || usernameError}
            />
          </div>
          <div className="mb-3">
            <CustomInput
              type="password"
              id="password"
              placeholder="Password"
              required
              onBlur={(e) => {
                checkValidPassword(e.target.value);
              }}
              custom={passError}
              msg={passErrMsg}
              error={Boolean(errors.password)}
              helpertext={errors.password || passwordError}
            />
          </div>
          <div className="d-flex justify-content-between mb-3">
            <div>
              <input type="checkbox" id="rememberMe" className="form-check-input" />
              <label htmlFor="rememberMe" className="form-check-label ms-2" style={{ fontSize: "18px" }}>
                Remember Me
              </label>
            </div>
            <div>
              <Link
                to="/forgot-password"
                style={{
                  color: "#002300",
                  textDecoration: "none",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Forgot Password?
              </Link>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary rounded-pill"
            style={{
              width: "100%",
              height: "60px",
              backgroundColor: "#4545DB",
              color: "#ffffff",
              padding: "10px 10px",
              fontSize: "20px",
              fontWeight: "600",
              border: "none",
              cursor: "pointer",
            }}
            onFocus={(e) => (e.target.style.boxShadow = "none")}
          >
            LOGIN
          </button>
        </form>
        <div className="text-center mt-4">
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "16px" }}>
            <span>New to Ezhisab?</span>
            <Link
              to="/create-user"
              style={{
                textDecoration: "none",
                marginLeft: "5px",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              Create an Account
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
