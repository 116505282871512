/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./Lottery.css";

const LotteryInventoryTable = ({
  data,
  columns,
  onEdit,
  onDelete,
  showAction,
  showFooter,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const getDisplayedData = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const dataToSort = data.slice(startIndex, endIndex);
    return sortDisplayedData(dataToSort);
  };

  const sortDisplayedData = (dataToSort) => {
    let sortableItems = [...dataToSort];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <a
            className="page-link"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage(i);
            }}
          >
            {i}
          </a>
        </li>
      );
    }
    return pages;
  };

  const displayedData = getDisplayedData();

  return (
    <div>
      <table className="data-table custom-table rounded-table">
        <thead className="table-header">
          <tr>
            {columns.map((col, index) => (
              <th key={index} onClick={() => handleSort(col.field)}>
                {col.header}
                {sortConfig.key === col.field && (
                  <span style={{ marginLeft: "5px" }}>
                    {sortConfig.direction === "ascending" ? (
                      <svg
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 4L4 0L8 4H0Z" fill="#002300" />
                      </svg>
                    ) : (
                      <svg
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0L4 4L8 0H0Z" fill="#002300" />
                      </svg>
                    )}
                  </span>
                )}
              </th>
            ))}
            {showAction && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {displayedData.length === 0 ? (
            <tr>
              <td
                colSpan={columns.length + (showAction ? 1 : 0)}
                style={{
                  textAlign: "center",
                  fontFamily: "Manrope",
                  fontSize: "40px",
                  fontWeight: '600',
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.1) -92.86%, #4545DB 71.43%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                No data found
              </td>
            </tr>
          ) : (
            displayedData.map((item, index) => (
              <tr key={index}>
                {columns.map((col, colIndex) => (
                  <td style={{padding:10}} key={colIndex}>{item[col.field] || "-"}</td>
                ))}
                {showAction && (
                  <td>
                    <button onClick={() => onEdit(item)}>Edit</button>
                    <button onClick={() => onDelete(item.id)}>Delete</button>
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>
      {showFooter && (
        <nav>
          <ul className="pagination">
            <li className={`prev-next ${currentPage === 1 ? "disabled" : ""}`}>
              <span
                className="page-link"
                onClick={handlePrev}
                style={{ cursor: currentPage === 1 ? "default" : "pointer" }}
              >
                &lt;
              </span>
            </li>
            {renderPagination()}
            <li
              className={`prev-next ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <span
                className="page-link"
                onClick={handleNext}
                style={{
                  cursor: currentPage === totalPages ? "default" : "pointer",
                }}
              >
                &gt;
              </span>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default LotteryInventoryTable;
