import React from 'react'

function VendingBookMovement() {
  return (
    <div className="due-days">
    <div className="header-row">
      <div className="setting-title"> Vending Book Movement</div>
      </div>
    </div>
  )
}

export default VendingBookMovement