import React from 'react'

function LotteryBookOnHold() {
  return (
    <div className="due-days">
    <div className="header-row">
      <div className="setting-title">Lottery Book On Hold</div>
      </div>
    </div>
  )
}

export default LotteryBookOnHold