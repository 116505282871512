import React from "react";
import FormLayout from "./FormLayout";
import Forgotpassword from "./WithUi/Forgotpassword";
import logo from "../../assets/img/EZHISAB.png";
import backgroundImage from "../../assets/img/Frame 812.png";
import topimage from "../../assets/img/Group 228723.png"
function ForgotPassword() {
  return (
    <FormLayout logoSrc={logo} backgroundImageSrc={backgroundImage} TopImage={topimage}>
      <Forgotpassword />
    </FormLayout>
  );
}
export default ForgotPassword;
