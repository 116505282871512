import React, { useState, useContext } from "react";
import ReusableModal from "../ManageSettings/ReusableModal"; // Adjust path as needed
import { toast } from "react-toastify";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../utils/secure-route/AuthContext";

const AddDepartmentModal = ({ show, handleClose, onDepartmentAdded }) => {
  const [departmentName, setDepartmentName] = useState("");
  const { user } = useContext(AuthContext);
  const storeId = user.store;
  const { Post } = useApi();

  const handleSave = async (e) => {
    e.preventDefault();
    if (!departmentName) {
      toast.warn("Department name is required");
      return;
    }

    try {
      const response = await Post("vendorDepartmentServiceData", {
        store: storeId,
        type: "department",
        title: departmentName,
      });
      if (response.status === 201) {
        toast.success("Department added successfully!");
        const newDepartment = response.data; // Assuming the API returns the newly created department
        onDepartmentAdded(newDepartment); // Update the parent component state
        setDepartmentName(""); // Reset the input field
        handleClose(); // Close the modal
      }
    } catch (error) {
      console.error("Error saving department:", error);
      toast.error("Error saving department!");
    }
  };

  return (
    <ReusableModal show={show} handleClose={handleClose} title="Add Department" width="760px" height="175px">
      <form onSubmit={handleSave}>
        <div className="form-group d-flex align-items-center mt-1">
          <input
            type="text"
            className="input-field"
            placeholder="Department Name"
            value={departmentName}
            onChange={(e) => setDepartmentName(e.target.value)}
            style={{ width: "483px", marginRight: "10px" }}
            required
          />
          <button
            type="submit"
            style={{
              padding: "6px 8px",
              backgroundColor: "#4545db",
              color: "white",
              border: "none",
              borderRadius: "40px",
              cursor: "pointer",
              width: "103px",
              height: "42px",
              fontSize: "18px",
              marginLeft: "20px",
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </ReusableModal>
  );
};

export default AddDepartmentModal;
