/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../../../config/Global.json";
import CustomInput from "../../custominput/CustomInput";
import { useNavigate } from "react-router-dom";

const Forgotpassword = () => {
  const [email, setEmail] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showInitialMessage, setShowInitialMessage] = useState(true);
  const [timer, setTimer] = useState(30);
  const [customErr, setCustomErr] = useState(false);
  const [customErrMsg, setCustomErrMsg] = useState("");
  const [errors, setErrors] = useState({});
  const [emailError, setEmailError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isUserExist, setIsUserExist] = useState(false); // State for user existence
  const [showTimer, setShowTimer] = useState(false); // State for timer visibility
  const apiBaseUrl = config.api.host;
  const passwordResetEndpoint = config.api.passwordReset;
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setCustomErrMsg(""); // Clear message when the email changes
    setIsUserExist(false); // Reset user existence state on email change
  };

  const checkUserExist = async (email) => {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    let err = false;
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      setCustomErr(true);
      setCustomErrMsg("Please enter a valid email.");
      setEmailError("Please enter a valid email.");
      err = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: false }));
      setCustomErr(false);
      setCustomErrMsg("");
      setEmailError("");
    }

    if (!err) {
      try {
        const response = await axios.post(
          `${config.api.host}${config.api.userExist}`,
          { email }
        );
        if (response.data.exists) {
          setErrors((prevErrors) => ({ ...prevErrors, email: false }));
          setCustomErr(false);
          setCustomErrMsg(""); // No message for existing user
          setEmailError("");
          setIsUserExist(true); // User exists
        } else {
          // User does not exist
          setErrors((prevErrors) => ({ ...prevErrors, email: true }));
          setCustomErr(true);
          setCustomErrMsg("This user account does not exist."); // Show message if user does not exist
          setEmailError("This user account does not exist.");
          setIsUserExist(false); // User does not exist
        }
      } catch (error) {
        setEmailError("Error checking user existence.");
        toast.error("Error checking user existence.");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isUserExist && !errors.email && !customErr) { // Check if user exists before submitting
      try {
        setIsButtonDisabled(true);
        await axios.post(`${apiBaseUrl}${passwordResetEndpoint}`, { email });
        toast.success("Password reset request sent successfully.");
        setShowSuccessMessage(true);
        setShowInitialMessage(false);
        setEmail("");
        setTimer(30); // Reset timer to 30 seconds
        setShowTimer(true); // Show the timer after a successful request
      } catch (error) {
        const errorMessage = error.response?.data?.detail || "";
        setCustomErr(true);
        setCustomErrMsg(errorMessage);
        setEmailError(errorMessage);
        setIsButtonDisabled(false);
      }
    }
  };

  useEffect(() => {
    let interval;
    if (showTimer && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsButtonDisabled(false);
      setShowTimer(false); // Hide the timer after it reaches 0
    }
    return () => clearInterval(interval);
  }, [timer, showTimer]);

  return (
    <div className="container" style={{ maxWidth: "459px" }}>
      <div className="row justify-content-center align-items-center">
        <div style={{ display: "flex", alignItems: "center" }}>
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => navigate("/login")}
            style={{
              marginTop: "10px",
              marginRight: "10px",
              fontSize: "25px",
              cursor: "pointer",
            }}
          ></i>
          <p
            className="card-title mb-3 mt-4"
            style={{
              fontFamily: "Manrope",
              fontSize: "40px",
              fontWeight: "700",
              textAlign: "left",
            }}
          >
            Forgot Password
          </p>
        </div>
        {showInitialMessage && (
          <p
            className="mb-3"
            style={{
              fontFamily: "Manrope, sans-serif",
              fontSize: "18px",
              color: "#002300",
            }}
          >
            No worries! Reset your password to regain access to your ledger and
            keep your finances on track.
          </p>
        )}
        {showSuccessMessage && (
          <p
            className="mb-3"
            style={{

              fontSize: "18px",
              color: "#002300",
            }}
          >
            Please check your inbox (and SPAM folder) for the password reset
            link.
          </p>
        )}
        <form>
          <div className="mb-3">
            <CustomInput
              type="email"
              id="email"
              placeholder="Email Address"
              value={email}
              onChange={handleEmailChange}
              onBlur={(e) => checkUserExist(e.target.value)}
              custom={customErr}
              msg={customErrMsg} // Display the error message here
              error={!!emailError}
              helpertext={emailError}
              style={{ width: "100%" }}
            />
          </div>
          <button
            type="button"
            style={{
              width: "100%",
              backgroundColor: isButtonDisabled || !isUserExist ? "#8B8B8E" : "#4545DB",
              color: "#FFFFFF",
              padding: "20px 40px",
              fontFamily: "Manrope, sans-serif",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "20px",
              border: "none",
              borderRadius: "40px",
              cursor: isButtonDisabled || !isUserExist ? "not-allowed" : "pointer",
              outline: "none",
              boxShadow: "none",
            }}
            onFocus={(e) => (e.target.style.boxShadow = "none")}
            onClick={handleSubmit}
            disabled={isButtonDisabled || !isUserExist} // Disable if button is disabled or user does not exist
          >
            SEND RESET LINK
          </button>
          <div className="d-flex justify-content-center mt-3">
            {showTimer && timer > 0 && ( // Only show timer when showTimer is true
              <span
                style={{
                  fontSize: "18px",
                  fontFamily: "Manrope",
                  color: "#002300",
                }}
              >
                Wait for {timer} seconds to request a new one.
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forgotpassword;
