import React, { useState, useEffect } from "react";
import "./LotteryHistoryModal.css";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import loader from '../../../../assets/img/loader.gif';

const LotteryHistoryModal = ({ open, onClose, data }) => {
  const today = new Date().toISOString().split("T")[0];
  const [shiftDetails, setShiftDetails] = useState({});
  const [shifts, setShifts] = useState([]);
  const [selectedShift, setSelectedShift] = useState("");
  const [selectedDate, setSelectedDate] = useState(today);
  const { Get, Post } = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    if (open) {
      refetchShiftData();
    }
  }, [open]);

  const handleFieldChange = (
    registerNo,
    field,
    value,
    isRegisterField = true
  ) => {
    if (isRegisterField) {
      handleLotteryRegisterField(registerNo, field, value);
    } else {
      // handleGlobalField(field, value);
    }
  };

  const handleLotteryRegisterField = async (registerNo, field, value) => {
    const payload = {
      lottery_report: shiftDetails.lottery_report?.id,
      register_no: registerNo,
      [field]: value,
    };

    setLoading(true);
    setError(null); // Clear previous errors
    await Post("updateLotteryReport", payload)
      .then((response) => {
        setShiftDetails((prevDetails) => ({
          ...prevDetails,
          lottery_register_reports: prevDetails.lottery_register_reports.map(
            (register) => {
              if (register.register_no === registerNo) {
                return { ...register, [field]: value };
              }
              return register;
            }
          ),
        }));
        refetchShiftData();
      })
      .catch((error) => {
        console.error(
          `Error updating ${field} for register ${registerNo}:`,
          error
        );
        setError(`Error updating ${field}: ${error.message}`);
      })
      .finally(() => setLoading(false));
  };

  const refetchShiftData = async () => {
    setLoading(true); // Indicate loading state
    setError(null); // Reset any previous errors

    try {
      // Step 1: Fetch the shift ID dynamically using GET method
      const shiftIdResponse = await Get("getShiftForHistoricalReport"); // Change Post to Get
      const shiftId = shiftIdResponse?.shift_id;

      if (!shiftId) {
        console.error(
          "Shift ID is missing from getShiftForHistoricalReport response."
        );
        setError("Failed to retrieve shift ID.");
        setLoading(false); // Reset loading state
        return;
      }

      console.log("Fetched Shift ID:", shiftId);

      // Step 2: Fetch historical report data using the shift ID
      const response = await Post("getHistoricalReport", { shift_id: shiftId });
      console.log("Historical Report Response:", response);

      // Step 3: Check if shift_data exists and is an array
      const shiftData = Array.isArray(response?.data?.shift_data)
        ? response.data.shift_data
        : [];
      console.log("Raw Shift Data:", shiftData);
      setShifts(shiftData); // Update state with raw shift data

      // Step 4: Select the latest shift if available
      if (shiftData.length > 0) {
        const latestShift = shiftData[shiftData.length - 1]; // Assuming the last one is the latest
        setSelectedShift(latestShift.shift.title); // Ensure 'title' exists in your data structure
        setShiftDetails(latestShift);
      }
    } catch (error) {
      console.error("Error in refetchShiftData:", error);
      setError(`Error fetching shift data: ${error.message}`); // Update state with error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const renderLotteryRegisterInputs = (registers, fieldName, labelPrefix) => {
    return <>
   
    {
      registers
      .sort((a, b) => a.register_no - b.register_no)
      .map((register, index) => (
        <div
          key={`${fieldName}_${register.register_no}`}
          className="data-report"
        >
          <label
            htmlFor={`${fieldName}_${register.register_no}`}
            className="label"
          >
            {`${labelPrefix} ${index + 1}`}
          </label>
          <input
            type="text"
            step="any"
            id={`${fieldName}_${register.register_no}`}
            name={`${fieldName}_${register.register_no}`}
            className="input"
            defaultValue={register[fieldName] || ""}
            onBlur={(e) =>
              handleFieldChange(
                register.register_no,
                fieldName,
                e.target.value,
                true
              )
            }
          />
        </div>
      ))
    }
     <hr/>
    </>
  };

 

  const handleLotteryGlobalField = async (field, value) => {
    const payload = {
      lottery_report: shiftDetails.lottery_report?.id,
      [field]: value,
    };

    const response = await Post("updateLotteryReport", payload)
      .then((response) => {
        // Update local state for the global field
        setShiftDetails((prevDetails) => ({
          ...prevDetails,
          lottery_report: {
            ...prevDetails.lottery_report,
            [field]: value,
          },
        }));
        refetchShiftData();
      })
      .catch((error) => {
        console.error(`Error updating ${field}:`, error);
      });
  };

  const handleLotteryFieldChange = (
    registerNo,
    field,
    value,
    isRegisterField = true
  ) => {
    if (isRegisterField) {
      handleLotteryRegisterField(registerNo, field, value);
    } else {
      handleLotteryGlobalField(field, value);
    }
  };

  const { lottery_report = {} } = shiftDetails;

  if (!open) return null;
  return (
    <div className="modal mb-4">
      <div className="modal-data">
        <div className="modal-header">
          <p className="report-table-title">Daily Lottery Report</p>
          <button className="close-button" onClick={onClose}>
              Close
            </button>
            {/* <button className="close-button" onClick={onClose}>
              Submit
            </button> */}
        </div>
        <div className="row">
          <div className="col-md-12">
          <div className="alert alert-warning" role="alert">
          Note: Before starting, make sure to add the number of registers in the manage settings if you have more than one.
          </div>
            
          </div>

        </div>
        <div className="row lotterys-title-header">
          <div className="col-md-4">
            <p className="lotterys-report-title">Last Invoice</p>
          </div>
          <div className="col-md-8">
            <p className="lotterys-report-title">Last Cash</p>
          </div>
        </div>

        <div className="row lotterys-report" style={{position: 'relative'}}>
          <div className="col-md-4 mt-4">
           
          {shiftDetails.lottery_register_reports?.length > 0 ? (
              <>
                {renderLotteryRegisterInputs(
                  shiftDetails.lottery_register_reports,
                  "net_sales_sr50",
                  "Online Net Sales"
                )}
                {renderLotteryRegisterInputs(
                  shiftDetails.lottery_register_reports,
                  "online_cashing_sr50",
                  "Online Cashing"
                )}
                {renderLotteryRegisterInputs(
                  shiftDetails.lottery_register_reports,
                  "instant_cashing_sr34",
                  "Instant Cashing"
                )}
              </>
            ) : (
              <div className="data-report">
                Loading Registers. <img height="25px" src={loader}/> 
              </div>
            )}

            <div className="data-report">
              <label htmlFor="total_net_sales_sr50" className="label">
                Total Net Sales SR50
              </label>
              <input
                type="text"
                step="any"
                id="total_net_sales_sr50"
                name="total_net_sales_sr50"
                className="input-readonly"
                value={shiftDetails.lottery_report?.total_net_sales_sr50 || ""}
                readOnly
              />
            </div>
            <div className="data-report">
              <label htmlFor="total_online_cashing_sr50" className="label">
                Total Online Cashing SR50
              </label>
              <input
                type="text"
                step="any"
                id="total_online_cashing_sr50"
                name="total_online_cashing_sr50"
                className="input-readonly"
                value={lottery_report?.total_online_cashing_sr50 || ""}
                onChange={(e) =>
                  handleLotteryFieldChange(
                    null,
                    "total_online_cashing_sr50",
                    e.target.value,
                    false
                  )
                }
                readOnly
              />
            </div>

            <div className="data-report">
              <label htmlFor="total_instant_cashing_today" className="label">
                Total Instant Cashing Today
              </label>
              <input
                type="text"
                step="any"
                id="total_instant_cashing_today"
                name="total_instant_cashing_today"
                className="input-readonly"
                value={lottery_report?.total_instant_cashing_today || ""}
                onChange={(e) =>
                  handleLotteryFieldChange(
                    null,
                    "total_instant_cashing_today",
                    e.target.value,
                    false
                  )
                }
                readOnly
              />
            </div>
            <div className="data-report">
              <label htmlFor="instant_sale_sr34" className="label">
                Instant Sale SR34
              </label>
              <input
                type="text"
                step="any"
                id="instant_sale_sr34"
                name="instant_sale_sr34"
                className="input"
                defaultValue={lottery_report?.instant_sale_sr34 || ""}
                onBlur={(e) =>
                  handleLotteryFieldChange(
                    null,
                    "instant_sale_sr34",
                    e.target.value,
                    false
                  )
                }
              />
            </div>
          </div>

         

          <div className="col-md-4 mt-4">
            {" "}
            {/* Today Cash Section */}
            <div className="data-report">
              <label htmlFor="total_online_balance" className="label">
                Total Online Balance
              </label>
              <input
                type="text"
                step="any"
                id="total_online_balance"
                name="total_online_balance"
                className="input-readonly"
                defaultValue={lottery_report?.total_online_balance || ""}
                onBlur={(e) =>
                  handleLotteryFieldChange(
                    null,
                    "total_online_balance",
                    e.target.value,
                    false
                  )
                }
              />
            </div>
            <div className="data-report">
              <label htmlFor="credit_sales" className="label">
                Credit Sales
              </label>
              <input
                type="text"
                step="any"
                id="credit_sales"
                name="credit_sales"
                className="input"
                defaultValue={lottery_report?.credit_sales || ""}
                onBlur={(e) =>
                  handleLotteryFieldChange(
                    null,
                    "credit_sales",
                    e.target.value,
                    false
                  )
                } // onBlur to update value
              />
            </div>
            <div className="data-report">
              <label htmlFor="debit_sales" className="label">
                Debit Sales
              </label>
              <input
                type="text"
                step="any"
                id="debit_sales"
                name="debit_sales"
                className="input"
                defaultValue={lottery_report?.debit_sales || ""}
                onBlur={(e) =>
                  handleLotteryFieldChange(
                    null,
                    "debit_sales",
                    e.target.value,
                    false
                  )
                } // onBlur to update value
              />
            </div>
            <div className="data-report">
              <label htmlFor="register_cash" className="label">
                Register Cash
              </label>
              <input
                type="text"
                step="any"
                id="register_cash"
                name="register_cash"
                className="input-readonly"
                defaultValue={lottery_report?.register_cash || ""}
                onBlur={(e) =>
                  handleLotteryFieldChange(
                    null,
                    "register_cash",
                    e.target.value,
                    false
                  )
                } // onBlur to update value
              />
            </div>
            <div className="data-report">
              <label htmlFor="over_short" className="label">
                Over Short
              </label>
              <input
                type="text"
                step="any"
                id="over_short"
                name="over_short"
                className="input-readonly"
                value={lottery_report?.over_short || ""}
                onBlur={(e) =>
                  handleLotteryFieldChange(
                    null,
                    "over_short",
                    e.target.value,
                    false
                  )
                } // onBlur to update value
              />
            </div>
            <div className="data-report">
              <label htmlFor="total_balance" className="label">
                Total Balance
              </label>
              <input
                type="text"
                step="any"
                id="total_balance"
                name="total_balance"
                className="input-readonly"
                value={lottery_report?.total_balance || ""}
                onBlur={(e) =>
                  handleLotteryFieldChange(
                    null,
                    "total_balance",
                    e.target.value,
                    false
                  )
                } // onBlur to update value
                
              />
               {error && <p className="error-message">{error}</p>}
           
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default LotteryHistoryModal;
