import { useContext } from "react";
import React from "react";
import AuthContext from "./AuthContext";
import Login from "../../components/common/Login";
import Header from "../../components/common/Header";
export const Auth = (props) => {
  let { user } = useContext(AuthContext);
  return props.check ? (
    user.user_id ? (
      <>
        <Header />
        {props.component}
      </>
    ) : (
      <Login />
    )
  ) : (
    props.component
  );
};
export default Auth;
