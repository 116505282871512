/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from "react";
import ReusableModal from "../ManageSettings/ReusableModal";
import "./Gas.css";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import SettingTable from "../ManageSettings/SettingTable";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import { toast ,ToastContainer} from "react-toastify";
import Swal from "sweetalert2";



function GasInvoice() {
  const [gas, setGas] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const { user } = useContext(AuthContext);
  const storeid = user.store;
  const { Get, Post, Delete } = useApi();
  const [showGasModal, setShowGasModal] = useState(false);
  const handleShowGas = () => setShowGasModal(true);
  const handleCloseGas = () => setShowGasModal(false);



  const handleOnDelete = async (id) => {

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
  });

  // Check if the user clicked the confirm button
  if (!result.isConfirmed) return; // Exit if the user cancels
    await Delete("gasInvoiceData", id)
      .then((resp) => {
        setReloadData(!reloadData);
        toast.success("Gas invoice removed.");
      })
      .catch((err) => {
        toast.error("Something went wrong.");
      });
  };
  const [formData, setFormData] = useState({
    date: "",
    invoice_no: "",
    freight_diesel_gross_units: "",
    freight_diesel_unit_price: "",
    freight_diesel_total: "",
    freight_gas_gross_units: "",
    freight_gas_unit_price: "",
    freight_gas_total: "",
    freight_both_gross_units: "",
    freight_both_unit_price: "",
    freight_both_total: "",
    minimum_load_penalty: "",
    misc_cost: "",
    invoice_total: "",
  });
  const [gasTypes, setGasTypes] = useState([]);
  const [gasFreights, setGasFreights] = useState([]);
  const [gasTaxes, setGasTaxes] = useState([]);

  const [myJson, setMyJson] = useState({});

  const [freightGrossUnits, setFreightGrossUnits] = useState({
    all: 0,
    diesel: 0,
    withOutDiesel: 0,
  });

  const [generalTotalObj, setGeneralTotalObj] = useState({});
  const [generalTotalObjFre, setGeneralTotalObjFre] = useState({});
  const [generalTotalObjTax, setGeneralTotalObjTax] = useState({});
  const updateGeneralTotal = (type, value) => {
    setGeneralTotalObj((prev) => ({ ...prev, [type]: value }));
  };

  const updateTaxTotal = (type, value) => {
    setGeneralTotalObjTax((prev) => ({ ...prev, [type]: value }));
  };

  const [finalTotal, setFinalTotal] = useState(0);

  useEffect(() => {
    fetchGasData();
    fetchInvoiceValues();
    settle();
  }, [reloadData]);

  useEffect(() => {
    settle();
  }, [myJson, generalTotalObj, finalTotal]);

  const handleDateChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      date: value,
    }));
  };

  function groupAndSum(data) {
    const result = { all: 0 };
    for (const [key, value] of Object.entries(data)) {
      const [prefix] = key.split("_");
      if (result[prefix]) {
        result[prefix] += value;
      } else {
        result[prefix] = value;
      }
      result.all += value;
    }
    return result;
  }
  const fetchGasData = async () => {
    try {
      const response = await Get("gasInvoiceData");
      setGas(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error("Error fetching gas data:", error);
    }
  };
  const handleOnUpdate = () => { }

  const fetchInvoiceValues = async () => {
    await Get("getValuesForInvoice")
      .then((response) => {
        if (response.gas_types) {
          setGasTypes(response.gas_types);
          setFormData((prevData) => {
            const updatedData = { ...prevData };
            response.gas_types.forEach((gasType) => {
              const fieldName = `gas_${gasType.gas_type_title.toLowerCase()}_unit_price`;
              updatedData[fieldName] = gasType.cost_per_gallon || "";
            });
            return updatedData;
          });
        }

        if (response.gas_freights) {
          setGasFreights(response.gas_freights);
          setFormData((prevData) => {
            const updatedData = { ...prevData };
            response.gas_freights.forEach((freight) => {
              const fieldName = `freight_${freight.freight_name.toLowerCase()}_unit_price`;
              updatedData[fieldName] = freight.freight_value || "";
            });
            return updatedData;
          });
        }

        if (response.gas_taxes) {
          setGasTaxes(response.gas_taxes);
          setFormData((prevData) => {
            const updatedData = { ...prevData };
            response.gas_taxes.forEach((tax) => {
              updatedData[`${tax.tax_name.toLowerCase()}_basic`] = "";
              updatedData[`${tax.tax_name.toLowerCase()}_rate`] = "";
              updatedData[`${tax.tax_name.toLowerCase()}_total`] = "0.00"; // Adjust this logic if necessary
            });
            return updatedData;
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching invoice values:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = value ? parseFloat(value) : 0;

    if (name.includes("gross_units")) {
      const gasType = name.split("_")[1]; // Extract gas type from name (e.g., "diesel", "gas")
      const unitPrice = formData[`freight_${gasType}_unit_price`] || 0;
      const total = (updatedValue * unitPrice).toFixed(2);

      setFormData((prevData) => ({
        ...prevData,
        [name]: updatedValue,
        [`freight_${gasType}_gross_units`]: updatedValue,
        [`freight_${gasType}_total`]: total,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: updatedValue,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dieselGrossUnitTotal =
      freightGrossUnits.freight_diesel *
      (formData.freight_diesel_unit_price || 0);
    const gasGrossUnitTotal =
      freightGrossUnits.freight_withOutDiesel *
      (formData.freight_gas_unit_price || 0);
    const bothGrossUnitTotal =
      freightGrossUnits.freight_all * (formData.freight_both_unit_price || 0);

    // const minimumLoadPenalty = parseFloat(formData.minimum_load_penalty) || 0;
    // const miscCost = parseFloat(formData.misc_cost) || 0;
    // const invoiceTotal = parseFloat(formData.invoice_total) || 0;

    const updatedFormData = {
      ...formData,
      store: storeid,
      freight_diesel_gross_units: freightGrossUnits.freight_diesel,
      freight_diesel_total: dieselGrossUnitTotal.toFixed(2),
      freight_gas_gross_units: freightGrossUnits.freight_withOutDiesel,
      freight_gas_total: gasGrossUnitTotal.toFixed(2),
      freight_both_gross_units: freightGrossUnits.freight_all,
      freight_both_total: bothGrossUnitTotal.toFixed(2),
      minimum_load_penalty: Number(minLoadCap),
      misc_cost: Number(miscCost),
      invoice_total: Number(finalTotal) + Number(minLoadCap) + Number(miscCost),
    };

    try {
      const response = await Post("gasInvoiceData", updatedFormData).then(
        (resp) => {
          setReloadData(!reloadData);
          setFormData((prevData) => ({
            ...prevData,
            ...response,
          }));
          setFormData({
            date: "",
            invoice_no: "",
            freight_diesel_gross_units: "",
            freight_diesel_unit_price: "",
            freight_diesel_total: "",
            freight_gas_gross_units: "",
            freight_gas_unit_price: "",
            freight_gas_total: "",
            freight_both_gross_units: "",
            freight_both_unit_price: "",
            freight_both_total: "",
            minimum_load_penalty: "",
            misc_cost: "",
            invoice_total: "",
            store: "",
          });
          handleCloseGas();
        }
      );
    } catch (error) {
      toast.error("Error posting data:", error);
    }
  };

  const [gasGrossUnits, setGasGrossUnits] = useState({});

  const calculateTotals = (data) => {
    let totalOnlyDiesel = 0;
    let totalWithoutDiesel = 0;
    let totalWithDiesel = 0;

    for (const [key, value] of Object.entries(data)) {
      if (key === "diesel") {
        totalOnlyDiesel = value;
      } else {
        totalWithoutDiesel += value;
      }

      totalWithDiesel += value;
    }

    setGeneralTotalObjFre({
      freight_diesel: formData.freight_diesel_unit_price * totalOnlyDiesel,
      freight_gas: formData.freight_gas_unit_price * totalWithoutDiesel,
      freight_both: formData.freight_both_unit_price * totalWithDiesel,
    });
    gasTaxes.map((tax, index) => {
      let type = tax.gas_type;
      const grossUnit =
        type === "both"
          ? freightGrossUnits.freight_all
          : type === "diesel"
            ? freightGrossUnits.freight_diesel
            : type === "gas"
              ? freightGrossUnits.freight_withOutDiesel
              : 0;

      const rate =
        formData[`${tax.tax_name.toLowerCase()}_rate`] || tax.tax_value; // Fetch rate

      updateTaxTotal(`gasTax_${type}`, grossUnit * rate);
    });

    setFreightGrossUnits({
      freight_all: totalWithDiesel,
      freight_diesel: totalOnlyDiesel,
      freight_withOutDiesel: totalWithoutDiesel,
    });

    return [
      { label: "diesel", total: totalOnlyDiesel },
      { label: "withOutDiesel", total: totalWithoutDiesel },
      { label: "all", total: totalWithDiesel },
    ];
  };

  const settle = () => {
    calculateTotals(myJson);
    let obj = {
      gasTotal: groupAndSum(generalTotalObj).all,
      freight: groupAndSum(generalTotalObjFre).all,
      tax: groupAndSum(generalTotalObjTax).all,
    };
    setFinalTotal(groupAndSum(obj).all);
  };

  const handleGrossUnitChange = async (e, gasType) => {
    let newGrossUnit = e.target.value;

    // Check if the input is numeric and doesn't exceed 10 digits in total (including 2 decimal places)
    if (/^\d{1,8}(\.\d{0,2})?$/.test(newGrossUnit)) {
      newGrossUnit = parseFloat(newGrossUnit);

      // Update the total cost based on the gross unit change
      formData[`gas_${gasType}_total`] = formData[`gas_${gasType}_unit_price`] * newGrossUnit;

      updateGeneralTotal(
        "gas_" + gasType,
        formData[`gas_${gasType}_unit_price`] * newGrossUnit
      );

      await updateGrossUnit(newGrossUnit, gasType);

      setMyJson((prev) => ({
        ...prev,
        [gasType]: newGrossUnit,
      }));

      settle(myJson);
    } else {
      // Optionally handle invalid input (e.g., show an error message or ignore the change)
      console.log("Invalid input: Must be a number with up to 8 digits before the decimal and 2 digits after.");
    }
  };

  const updateGrossUnit = async (newGrossUnit, gasType) => {
    if (!isNaN(newGrossUnit)) {
      setGasGrossUnits((prev) => ({
        ...prev,
        [gasType]: newGrossUnit,
      }));
    } else {
      setGasGrossUnits((prev) => ({
        ...prev,
        [gasType]: 0,
      }));
    }
  };

  const columns = [
    { header: "Purchase Date", field: "date" },
    { header: "Invoice no", field: "invoice_no" },
    { header: "Total Gas Gallons", field: "freight_gas_gross_units" },
    { header: "Total Diesel Gallons", field: "freight_diesel_gross_units" },
    { header: "Amount", field: "invoice_total" },
  ];
  const [minLoadCap, setMinLoadCap] = useState(0);
  const [miscCost, setMiscCost] = useState(0);
  return (
    <>
    <ToastContainer />
      <div className="due-days">
        <div className="header-row">
          <div className="setting-title">Gas Invoice</div>
          <button className="gas-invoice-add-button" onClick={handleShowGas}>
            + Add Invoice
          </button>
        </div>
        <ReusableModal
          show={showGasModal}
          handleClose={handleCloseGas}
          title={"Add Invoice"}
          width="927px"
          height="auto"
        >
          <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column">
              <div className="d-flex align-items-center mb-0">
                <label
                  htmlFor="settledDate"
                  style={{ marginRight: "10px", fontSize: "18px" }}
                >
                  Delivery/Invoice Date
                </label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleDateChange}
                  className="input-field"
                  style={{
                    width: "160px",
                    marginRight: "20px",
                    height: "44px",
                  }}
                />

                <label
                  htmlFor="invoiceNo"
                  style={{ marginRight: "10px", fontSize: "18px" }}
                >
                  Invoice No
                </label>
                <input
                  type="text"
                  name="invoice_no"
                  value={formData.invoice_no}
                  onChange={handleChange}
                  className="input-field"
                  style={{
                    width: "280px",
                    marginRight: "20px",
                    height: "44px",
                  }}
                />
              </div>

              <div className="card mt-3">
                <div className="table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Gross Units</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gasTypes.map((gasType, index) => {
                        const type = gasType.gas_type_title.toLowerCase();
                        return (
                          <tr key={index}>
                            <td>{gasType.gas_type_title}</td>
                            <td>
                              <input
                                type="number"
                                value={gasGrossUnits[type]} // Use state for specific gas type
                                onChange={(e) => handleGrossUnitChange(e, type)}
                                className="input-field"
                                style={{ width: "150px", height: "36px" }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name={`gas_${type}_unit_price`}
                                value={formData[`gas_${type}_unit_price`] || ""}
                                onChange={handleChange}
                                className="input-field"
                                style={{ width: "110px", height: "36px" }}
                              />
                            </td>
                            <td>
                              {formData[`gas_${type}_total`] ||
                                gasGrossUnits[type] *
                                formData[`gas_${type}_unit_price`] ||
                                "0.00"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card mt-3">
                <div className="table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Freight Summary</th>
                        <th>Gross Units</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gasFreights.map((freight, index) => {
                        const type = freight.freight_name.toLowerCase();
                        const freightUnitPrice =
                          formData[`freight_${type}_unit_price`] || 0;
                        const grossUnitValue =
                          type === "both"
                            ? freightGrossUnits.freight_all
                            : type === "diesel"
                              ? freightGrossUnits.freight_diesel
                              : type === "gas"
                                ? freightGrossUnits.freight_withOutDiesel
                                : 0;

                        return (
                          <tr key={index}>
                            <td>{freight.freight_name}</td>
                            <td>
                              {type === "both" ? (
                                <input
                                  type="number"
                                  value={
                                    type === "both"
                                      ? freightGrossUnits.freight_all
                                      : 0
                                  }
                                  className="input-field"
                                  style={{ width: "150px", height: "36px" }}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                              {type === "diesel" ? (
                                <input
                                  type="number"
                                  value={
                                    type === "diesel"
                                      ? freightGrossUnits.freight_diesel
                                      : 0
                                  }
                                  className="input-field"
                                  style={{ width: "150px", height: "36px" }}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                              {type === "gas" ? (
                                <input
                                  type="number"
                                  value={
                                    type === "gas"
                                      ? freightGrossUnits.freight_withOutDiesel
                                      : 0
                                  }
                                  className="input-field"
                                  style={{ width: "150px", height: "36px" }}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <input
                                type="number"
                                name={`freight_${type}_unit_price`}
                                value={
                                  formData[`freight_${type}_unit_price`] || ""
                                }
                                className="input-field"
                                style={{ width: "110px", height: "36px" }}
                                disabled
                              />
                            </td>
                            <td>
                              <td>
                                {formData[`freight_${type}_total`] ||
                                  grossUnitValue * freightUnitPrice ||
                                  "0.00"}
                              </td>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card mt-3">
                <div className="table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          Tax & Other <br />
                          Charges Summary
                        </th>
                        <th>Basic</th>
                        <th>Rate</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gasTaxes.map((tax, index) => {
                        let type = tax.gas_type;

                        const calculateTotal = () => {
                          const grossUnit =
                            type === "both"
                              ? freightGrossUnits.freight_all
                              : type === "diesel"
                                ? freightGrossUnits.freight_diesel
                                : type === "gas"
                                  ? freightGrossUnits.freight_withOutDiesel
                                  : 0;

                          const rate =
                            formData[`${tax.tax_name.toLowerCase()}_rate`] ||
                            tax.tax_value; // Fetch rate

                          return grossUnit * rate;
                        };
                        return (
                          <tr key={index}>
                            <td>{tax.tax_name}</td>
                            <td>
                              {type === "both" ? (
                                <input
                                  type="number"
                                  value={
                                    type === "both"
                                      ? freightGrossUnits.freight_all
                                      : 0
                                  }
                                  className="input-field"
                                  style={{ width: "150px", height: "36px" }}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                              {type === "diesel" ? (
                                <input
                                  type="number"
                                  value={
                                    type === "diesel"
                                      ? freightGrossUnits.freight_diesel
                                      : 0
                                  }
                                  className="input-field"
                                  style={{ width: "150px", height: "36px" }}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                              {type === "gas" ? (
                                <input
                                  type="number"
                                  value={
                                    type === "gas"
                                      ? freightGrossUnits.freight_withOutDiesel
                                      : 0
                                  }
                                  className="input-field"
                                  style={{ width: "150px", height: "36px" }}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <input
                                type="text"
                                name={`${tax.tax_name.toLowerCase()}_rate`} // Unique name for "Rate"
                                value={
                                  formData[
                                  `${tax.tax_name.toLowerCase()}_rate`
                                  ] || tax.tax_value
                                } // Show tax_value in "Rate" field
                                onChange={handleChange}
                                className="input-field"
                                style={{ width: "110px", height: "36px" }}
                                disabled
                              />
                            </td>
                            <td>{calculateTotal().toFixed(2)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card mt-3">
                <div className="table">
                  <table className="table">
                    <thead>
                      <tr></tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Minimum Load Penalty</td>
                        <td></td>
                        <td></td>
                        <td>
                          {" "}
                          <input
                            type="number"
                            name="minimum_load_penalty"
                            value={minLoadCap} // Default to "0.00" if no value is provided
                            onChange={(e) => {
                              setMinLoadCap(e.target.value);
                            }} // Assuming handleChange is already defined to update formData
                            className="input-field"
                            style={{
                              width: "110px",
                              height: "36px",
                              marginLeft: "450px",
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Misc Cost</td>
                        <td></td>
                        <td></td>
                        <td>
                          <input
                            type="number"
                            name="misc_cost"
                            value={miscCost} // Default to "0.00" if no value is provided
                            onChange={(e) => {
                              setMiscCost(e.target.value);
                            }} // Assuming handleChange is already defined to update formData
                            className="input-field"
                            style={{
                              width: "110px",
                              height: "36px",
                              marginLeft: "450px",
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Invoice Total</td>
                        <td></td>
                        <td></td>
                        <td>
                          <input
                            readOnly
                            type="number"
                            name="invoice_total"
                            value={
                              Number(finalTotal) +
                              Number(minLoadCap) +
                              Number(miscCost)
                            }
                            onChange={handleChange}
                            className="input-field"
                            style={{
                              width: "110px",
                              height: "36px",
                              marginLeft: "450px",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <button
                style={{
                  marginRight: "40px",
                  padding: "4px 8px",
                  backgroundColor: "#4545db",
                  color: "white",
                  border: "none",
                  borderRadius: "40px",
                  marginLeft: "700px",
                  cursor: "pointer",
                  width: "124px",
                  height: "42px",
                  fontSize: "18px",
                }}
                onClick={handleSubmit}
                type="button"
              >
                Submit
              </button>
            </div>
          </form>
        </ReusableModal>
        <SettingTable
          data={gas}
          columns={columns}
          showAction={true}
          showFooter={true}
          onDelete={handleOnDelete}
          onUpdate={handleOnUpdate}
        />
      </div>
    </>
  );
}

export default GasInvoice;
