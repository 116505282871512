import React, { useState, useEffect, useRef } from 'react';
import useAPI from '../../../../utils/api-manager/Helper/useApi';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { DatePicker, Space, Typography, Empty } from 'antd';
import CurrentStore from './CurrentStore/CurrentStore';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const { RangePicker } = DatePicker;
const { Title: AntdTitle } = Typography;

export default function StackedBarChart() {
  const [dateRange, setDateRange] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const chartRef = useRef();

  const { Post } = useAPI();

  const fetchDashboardData = async (startDate, endDate) => {
    setLoading(true);
    try {
      const payload = {
        start_date: startDate,
        end_date: endDate,
      };
  
      const response = await Post('dashboardChart', payload);
      const data = response.data;
  
      const businessReports = data.business_reports || [];
      const lotteryReports = data.lottery_reports || [];
  

      const mappedData = businessReports.map((entry, index) => ({
        name: entry.date,
        outflow: parseFloat(entry.cash_to_account || 0),
        inflow: parseFloat(lotteryReports[index]?.total_balance || 0),
      }));
  
      
      const filtered = mappedData.filter(
        (entry) => new Date(entry.name) >= new Date(startDate) && new Date(entry.name) <= new Date(endDate)
      );
  
     
      if (filtered.length === 0) {
        setFilteredData([]); 
      } else {
        setFilteredData(filtered);
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setFilteredData([]); 
    } finally {
      setLoading(false);
    }
  };
  
  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (!dates || dates.length === 0) {
      setFilteredData([]); 
    } else if (dates.length === 2) {
      const [start, end] = dates;
      fetchDashboardData(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
    }
  };
  
  


  useEffect(() => {
    if (dateRange && dateRange.length === 2) {
      const [start, end] = dateRange;
      fetchDashboardData(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
    }
  }, [dateRange]);

  const netCashFlow = filteredData.reduce((acc, curr) => acc + curr.outflow + curr.inflow, 0);
  const formattedCashFlow = netCashFlow.toLocaleString();
  const totalOutflow = filteredData.reduce((acc, curr) => acc + curr.inflow, 0);
  const profitPercentage = totalOutflow !== 0 ? ((netCashFlow / totalOutflow) * 100).toFixed(2) : 0;
  const arrow = profitPercentage > 0 ? '↗' : profitPercentage < 0 ? '↓' : '↘';
  const arrowColor = profitPercentage > 0 ? 'green' : profitPercentage < 0 ? 'red' : 'gray';

  const data = {
    labels: filteredData.map((entry) => entry.name),
    datasets: [
      {
        label: 'Business Report',
        stack: 'positive', 
        data: filteredData.map((entry) => entry.outflow),
       
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) return null;

          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0.02, 'rgba(255, 255, 255, 0.4)');
          gradient.addColorStop(0.90, 'rgba(0, 0, 255, 0)');
          gradient.addColorStop(0.30, 'rgba(0, 0, 255, 0.1)');
          gradient.addColorStop(0.48, 'rgba(0, 0, 255, 0.03)');
          gradient.addColorStop(0.95, 'rgba(0, 0, 255, 0.0)');

          return gradient;
        },
       
        borderColor: 'blue',
        borderWidth: {
          top: 2,
          left: 0,
          right: 0,
          bottom: 0,
        },
        stack: 'stack1',
        hoverBackgroundColor: 'rgba(0, 0, 255, 0.5)',
        hoverBorderWidth: 0,
      },
      {
        label: 'Lottery Report',
        stack: 'negative', 
        data: filteredData.map((entry) => -entry.inflow),
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) return null;

          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0.0, 'rgba(255, 255, 255, 0)');
          gradient.addColorStop(0.02, 'rgba(160, 160, 160, 0.2)');
          gradient.addColorStop(0.59, 'rgba(160, 160, 160, 0.03)');
          gradient.addColorStop(0.95, 'rgba(160, 160, 160, 0.2)');

          return gradient;
        },
        borderColor: 'black',
        borderWidth: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 2,
        },
        stack: 'stack1',
        hoverBackgroundColor: 'rgba(0, 0, 0, 0.7)',
        hoverBorderWidth: 0,

      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: ({ raw, dataset }) => `${dataset.label}: $${Math.abs(raw)}`,
        },
      },
    },
    scales: {
      x: {
        stacked: true, 
      },
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          callback: (value) => value === 0 ? '0' : `$${Math.abs(value)}k`, 
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };
  

  const customAnnotations = (
    <>
      <div style={{ position: 'absolute', top: '-5%', left: '53%', transform: 'translateX(-50%)', fontWeight: 'bold', fontSize: '16px', color: 'blue' }}>
        Business Report
      </div>
      <div style={{ position: 'absolute', bottom: '-3%', left: '53%', transform: 'translateX(-50%)', fontWeight: 'bold', fontSize: '16px', color: 'black' }}>
        Lottery

      </div>
    </>
  );

  return (
    <div style={{ padding: '20px', width: '1050px', margin: '20px 160px', backgroundColor: 'white', borderRadius: '20px', height:"520px" }}>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <AntdTitle level={2} style={{ fontSize: '20px', marginBottom: '10px' }}>
            Cashflow:
          </AntdTitle>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <span style={{ color: netCashFlow >= 0 ? 'black' : 'green', fontSize: '30px', marginRight: '10px' }}>
              ${formattedCashFlow}
            </span>
            <span style={{ fontSize: '14px', fontWeight: 'bold', color: arrowColor }}>
              <span style={{ fontSize: '16px' }}>{arrow}</span>
              {profitPercentage}%
            </span>
          </div>
        </div>
        <Space>
          <RangePicker onChange={handleDateChange} />
        </Space>
      </div>
      {filteredData.length > 0 ? (
  <div style={{ position: 'relative', width: '80%', height: '400px', margin: '0 auto' }}>
    {customAnnotations}
    <Bar ref={chartRef} data={data} options={options} />
  </div>
) : (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '400px', 
    }}
  >
    <Empty description="No data available for the selected date range" />
  </div>
)}
</div>


  );
}
