/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./Invoice.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import useApi from "../../../../../utils/api-manager/Helper/useApi";

const ATMDepositTable = (props) => {
  const [invoices, setInvoices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [sortOrder, setSortOrder] = useState({ column: "", direction: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const invoicesPerPage = 10;
  const [selectedVendor, setSelectedVendor] = useState("");
  const [vendors, setVendors] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { Get, Delete } = useApi();
  function filterByTransactionType(dataArray, transactionType) {
    return dataArray.filter(record => record.transaction_type === transactionType);
}
  useEffect(() => {
    const fetchInvoices = async () => {
      await Get("transactionData")
        .then((response) => {
            let type = (props.transaction_type)?props.transaction_type:"Bank Deposit";
          setInvoices(filterByTransactionType(response, type));
          const uniqueVendors = [
            ...new Set(response.map((invoice) => invoice.vendor_department_name)),
          ];
          setVendors(uniqueVendors);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    fetchInvoices();
  }, [props.reloadData]);
  const applyFilters = () => {
    let filteredInvoices = invoices;

    if (selectedStatus !== "All") {
      filteredInvoices = filteredInvoices.filter(
        (invoice) => invoice.status === selectedStatus
      );
    }
    if (selectedVendor) {
      filteredInvoices = filteredInvoices.filter(
        (invoice) => invoice.vendor_department_name === selectedVendor
      );
    }

    if (fromDate) {
      filteredInvoices = filteredInvoices.filter(
        (invoice) => new Date(invoice.date) >= new Date(fromDate)
      );
    }
    if (toDate) {
      filteredInvoices = filteredInvoices.filter(
        (invoice) => new Date(invoice.date) <= new Date(toDate)
      );
    }

    setInvoices(filteredInvoices);
  };

  const handleSort = (column) => {
    const direction =
      sortOrder.column === column && sortOrder.direction === "asc"
        ? "desc"
        : "asc";
    setSortOrder({ column, direction });

    const sortedInvoices = [...filteredInvoices].sort((a, b) => {
      if (a[column] < b[column]) return direction === "asc" ? -1 : 1;
      if (a[column] > b[column]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setInvoices(sortedInvoices);
  };

  const filteredInvoices = (invoices || []).filter((invoice) => {
    const vendorName = invoice.date || "";
    const invoiceNumber = invoice.bank_deposite_type || "";
    const matchesSearch =
      vendorName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      invoiceNumber.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesStatus =
      selectedStatus === "All" || invoice.bank_deposite_type === selectedStatus;

    return matchesSearch && matchesStatus;
  });

  const totalInvoices = filteredInvoices.length;

  const totalPages = Math.ceil(totalInvoices / invoicesPerPage);
  const startIndex = (currentPage - 1) * invoicesPerPage;
  const currentInvoices = filteredInvoices.slice(
    startIndex,
    startIndex + invoicesPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleDelete = async (id) => {
    const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
    });

    // Check if the user clicked the confirm button
    if (!result.isConfirmed) return; // Exit if the user cancels

    try {
        await Delete(`transactionData`, id);

        const updatedInvoices = invoices.filter((invoice) => invoice.id !== id);
        setInvoices(updatedInvoices);

        // Show success notification
        toast.success("Invoice successfully deleted!");
    } catch (error) {
        if (error.response) {
            toast.error(`Error deleting invoice: ${error.response.data}`);
            toast.error(`Status code: ${error.response.status}`);
        } else if (error.request) {
            toast.error("No response received");
        } else {
            toast.error(`Error setting up request: ${error.message}`);
        }
    }
};


  const handleEdit = (invoice) => {
    console.log("Edit invoice:", invoice);
    toast.error("You don't have permission to edit this invoice."); // Show error notification
  };

  const getSortIcon = (column) => {
    if (sortOrder.column === column) {
      return (
        <svg
          width="8"
          height="10"
          viewBox="0 0 8 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 6L4 10L8 6H0Z" fill="black" />
          <path d="M0 4L4 0L8 4H0Z" fill="black" />
        </svg>
      );
    }
    return null;
  };
  const renderPagination = () => {
    const paginationItems = [];
    const totalPages = Math.ceil(totalInvoices / invoicesPerPage);

    if (totalPages <= 3) {
      // If there are 3 or fewer pages, show all
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
          <button
            key={i}
            className={currentPage === i ? "active-pgbtn" : "pgbtn"}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      // More than 3 pages
      if (currentPage > 1) {
        paginationItems.push(
          <button
            key={1}
            className={currentPage === 1 ? "active-pgbtn" : "pgbtn"}
            onClick={() => setCurrentPage(1)}
          >
            1
          </button>
        );
      }

      if (currentPage > 2) {
        paginationItems.push(<span key="dots1">...</span>);
      }

      // Show the current page and surrounding pages
      if (currentPage > 1) {
        paginationItems.push(
          <button
            key={currentPage - 1}
            className={
              currentPage === currentPage - 1 ? "active-pgbtn" : "pgbtn"
            }
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            {currentPage - 1}
          </button>
        );
      }

      paginationItems.push(
        <button key={currentPage} className="active-pgbtn">
          {currentPage}
        </button>
      );

      if (currentPage < totalPages) {
        paginationItems.push(
          <button
            key={currentPage + 1}
            className={
              currentPage === currentPage + 1 ? "active-pgbtn" : "pgbtn"
            }
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            {currentPage + 1}
          </button>
        );
      }

      if (currentPage < totalPages - 1) {
        paginationItems.push(<span key="dots2">...</span>);
      }
    }

    return (
      <div className="pagination">
        <button
          className="pgbtn"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <svg
            width="4"
            height="8"
            viewBox="0 0 4 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4 0L1.74846e-07 4L4 8L4 0Z" fill="#002300" />
          </svg>
        </button>

        {paginationItems}

        <button
          className="pgbtn"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <svg
            width="4"
            height="8"
            viewBox="0 0 4 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 8L4 4L-3.49691e-07 0L0 8Z" fill="#002300" />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        {/* Search Bar */}
        <div className="searchcontainer">
          <div
            className="input-group"
            style={{ width: "300px", height: "50px" }}
          >
            <span
              className="input-group-text"
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5416 19.2497C15.3511 19.2497 19.2499 15.3508 19.2499 10.5413C19.2499 5.73186 15.3511 1.83301 10.5416 1.83301C5.73211 1.83301 1.83325 5.73186 1.83325 10.5413C1.83325 15.3508 5.73211 19.2497 10.5416 19.2497Z"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.1666 20.1663L18.3333 18.333"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

         <div className="d-flex align-items-center">
          <div
            className="filtercontainer"
            style={{
              backgroundColor: "#f4f4f4",
              padding: "5px",
              borderRadius: "60px",
            }}
          >
            {["All","Business cash", "Lottery cash", "Gas cash"].map((bank_deposite_type) => (
              <button
                key={bank_deposite_type}
                className={`btn ${selectedStatus === bank_deposite_type ? "active" : ""}`}
                onClick={() => {
                  setSelectedStatus(bank_deposite_type);
                  // Reset page when status changes
                }}
                style={{
                  borderRadius: "60px",
                  backgroundColor:
                    selectedStatus === bank_deposite_type ? "#ffffff" : "transparent",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  marginRight: "10px",
                  boxShadow: "none",
                  outline: "none",

                }}
              >
                {bank_deposite_type}
                <span
                  className="badge"
                  style={{
                    ...getCountClass(bank_deposite_type),

                    height: "30px",
                    width: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    textAlign: "center",
                  }}
                >
                  {bank_deposite_type === "All"
                    ? invoices.length
                    : invoices.filter((invoice) => invoice.bank_deposite_type === bank_deposite_type)
                        .length}
                </span>
              </button>
            ))}
          </div>

          <div
            className="filterbutton"
            style={{
              marginLeft: "10px",
              backgroundColor: "#f4f4f4",
              width: "130px",
              borderRadius: "60px",
              padding: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <button
              className="btn dropdown"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
                border: "none",
                background: "transparent",
                outline: "none",
              }}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.0707 1.83301H11.2291C11.9074 1.83301 12.4665 2.39218 12.4665 3.07051V4.42716C12.4665 4.92216 12.1549 5.53635 11.8524 5.84801L9.19408 8.19468C8.82741 8.50634 8.57987 9.1205 8.57987 9.6155V12.2739C8.57987 12.6405 8.33239 13.1355 8.02072 13.328L7.15905 13.8872C6.35238 14.3822 5.24319 13.823 5.24319 12.833V9.5605C5.24319 9.12967 4.99571 8.57052 4.74821 8.25885L2.40155 5.78384C2.08988 5.47217 1.8424 4.92217 1.8424 4.54633V3.12551C1.83323 2.39218 2.39236 1.83301 3.0707 1.83301Z"
                  stroke="#002300"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.8335 11.0004V13.7504C1.8335 18.3337 3.66683 20.1671 8.25016 20.1671H13.7502C18.3335 20.1671 20.1668 18.3337 20.1668 13.7504V8.25039C20.1668 5.39039 19.4518 3.59371 17.7926 2.65871C17.3251 2.39288 16.3902 2.19121 15.5377 2.05371"
                  stroke="#002300"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.9165 11.917H16.4998"
                  stroke="#002300"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.0835 15.583H16.5002"
                  stroke="#002300"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              &nbsp; Filter
            </button>

            <ul
              className="dropdown-menu p-3"
              aria-labelledby="dropdownMenuButton"
              style={{
                width: "290px",
                justifyContent: "center",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1,
              }}
            >
              <li>
              <div className="mb-2">
                  <select
                    id="selectStatus"
                    className="form-select"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    style={{ borderRadius: "60px", padding: "auto" }}
                  >
                    <option value="All">All</option>
                    {["Business cash", "Lottery cash", "Gas cash"].map((bank_deposite_type) => (
                      <option key={bank_deposite_type} value={bank_deposite_type}>
                        {bank_deposite_type.charAt(0).toUpperCase() + bank_deposite_type.slice(1)}
                      </option>
                    ))}
                  </select>
                </div>
              </li>
              {/* <li>
                <div className="mb-2">
                  <select
                    id="selectVendor"
                    className="form-select"
                    value={selectedVendor}
                    onChange={(e) => setSelectedVendor(e.target.value)}
                    style={{ borderRadius: "60px", padding: "auto" }}
                  >
                    <option value="">Select Vendor</option>
                    {vendors.map((vendor) => (
                      <option key={vendor} value={vendor}>
                        {vendor}
                      </option>
                    ))}
                  </select>
                </div>
              </li> */}
              <li className="mt-2">
                <div
                  className="row col-md-12"
                  style={{ width: "100%", justifyContent: "space-around" }}
                >
                  <div className="col-5">
                    <input
                      style={{
                        borderRadius: "60px",
                        height: "40px",
                        width: "110px",
                      }}
                      type="date"
                      className="form-control"
                      placeholder="From"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>
                  <div className="col-5">
                    <input
                      style={{
                        borderRadius: "60px",
                        height: "40px",
                        width: "110px",
                      }}
                      type="date"
                      className="form-control"
                      placeholder="To"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                </div>
              </li>
              <li className="text-center mt-2">
                <button
                  className="btn btn-primary"
                  style={{ width: "163px", height: "40px" }}
                  onClick={applyFilters}
                >
                  Apply Filters
                </button>
              </li>
            </ul>
          </div>
        </div>

      </div>

      {/* Table of Invoices */}
      <table className="table table-borderless table-responsive">
        <thead>
          <tr>
            {["date", "bank_deposite_type", "amount","Transaction Type", "actions"].map(
              (header, index) => (
                <th
                  key={header}
                  onClick={() => handleSort(header)}
                  style={{
                    paddingLeft: index === 0 ? "30px" : "0",
                    cursor: "pointer",
                    borderTopLeftRadius: index === 0 ? "60px" : "0",
                    borderBottomLeftRadius: index === 0 ? "60px" : "0",
                    borderTopRightRadius: index === 4 ? "60px" : "0",
                    borderBottomRightRadius: index === 4 ? "60px" : "0",
                    textAlign: "start",
                    alignContent: "center",
                  }}
                >
                  {header
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}{" "}
                  {getSortIcon(header)}
                </th>
              )
            )}
            <div className="dropdown">
              <ul className="dropdown-menu" aria-labelledby="actionDropdown">
                {/* <li>
                  <a
                    className="dropdown-item"
                    onClick={() => handleEdit(currentInvoices.map)}
                  >
                    Edit
                  </a>
                </li> */}
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => handleDelete(currentInvoices.map)}
                  >
                    Delete
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    View Details
                  </a>
                </li>
              </ul>
            </div>
          </tr>
        </thead>
        <tbody style={{ textAlign: "center" }}>
          {currentInvoices.length !== 0?
            currentInvoices.map((invoice) => (
            <tr key={invoice.id}>
              <td style={{ cursor: "pointer", textAlign: "left" }}>
                {invoice.date}
              </td>

              <td style={{ cursor: "pointer", textAlign: "left" }}>
                <span style={getPaymentMethodStyle(invoice.bank_deposite_type)}>
                  {invoice.bank_deposite_type
                    ? invoice.bank_deposite_type.charAt(0).toUpperCase() +
                      invoice.bank_deposite_type.slice(1)
                    : "N/A"}
                </span>
              </td>
              <td style={{ textAlign: "left" }}>{invoice.total_amount}</td>
              <td style={{ textAlign: 'left' }}>
                <span style={getStatusStyle(invoice.transaction_type)}>
                    {invoice.transaction_type ?
                        invoice.transaction_type.charAt(0).toUpperCase() + invoice.transaction_type.slice(1) :
                        'N/A'}
                </span>
            </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-sm btn-outline-secondary dropdown-vertical "
                    type="button"
                    id={`actionDropdown-${invoice.id}`} // Unique ID for each dropdown
                    data-bs-toggle="dropdown"
                    aria-expanded="false"

                  >
                    <i
                      className="bi bi-three-dots"
                      style={{ transform: "rotate(90deg)", display: "block" }}
                    ></i>
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby={`actionDropdown-${invoice.id}`}
                  >
                    {/* <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleEdit(invoice)}
                      >
                        Edit
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleDelete(invoice.id)}
                      >
                        Delete
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          ))

          :(
            <tr>
              <td colSpan="7" style={{
                textAlign: "center", fontFamily: 'Manrope',
                fontWeight: '600',
                fontSize: '40px',
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) -92.86%, #4545DB 71.43%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>
                No data found
              </td>
            </tr>
          )
          }
        </tbody>
      </table>

      {renderPagination()}
    </div>
  );
};

const paymentMethodStyles = {
  bank: { backgroundColor: "#57A09C" },
  "Business cash": { backgroundColor: "#38400B" },
  "Credit Card": { backgroundColor: "#28a745" },
  "Gas cash": { backgroundColor: "#57A09C" },
  "Lottery cash": { backgroundColor: "#CAC59D" },
  cheque: { backgroundColor: "#38400B" },
  pay_later: { backgroundColor: "#E55477" },
  pay_now: { backgroundColor: "#A9B0F0" },
  credit_invoice: { backgroundColor: "#CFCC76" },
};

const getPaymentMethodStyle = (method) => ({
  ...(paymentMethodStyles[method] || { backgroundColor: "#6c757d" }),
  borderRadius: "30px",
  padding: "5px 10px",
  color: "#fff",
});


const statusStyles = {
  All: { backgroundColor: "#4545DB" },
  "Business cash": { backgroundColor: "#38400B" },
 "Lottery cash": { backgroundColor: "#CAC59D" },
 "Gas cash": { backgroundColor: "#57A09C" },
};

const getStatusStyle = (status) => ({
  ...(statusStyles[status] || { backgroundColor: "#6c757d" }),
  borderRadius: "30px",
  padding: "5px 10px",
  color: "#fff",
});

const getCountClass = (status) => ({
  ...statusStyles[status],
  padding: "10px",
});
export default ATMDepositTable;
