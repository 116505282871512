import React from "react";
import FormLayout from "./FormLayout";
import logo from "../../assets/img/EZHISAB.png";
import topimage from "../../assets/img/Group 228723.png"
import backgroundImage from "../../assets/img/Frame 812.png";
import CreateNewPassword from "./WithUi/CreateNewPassword";
function ConfirmPassword() {
  return (
    <FormLayout logoSrc={logo} backgroundImageSrc={backgroundImage} TopImage={topimage}>
      <CreateNewPassword />
    </FormLayout>
  )
}

export default ConfirmPassword