/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { toast,ToastContainer } from "react-toastify";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../.././../utils/secure-route/AuthContext";
import SettingTable from "../ManageSettings/SettingTable";
import Swal from "sweetalert2";

export default function GeneralLedgerATM() {
  const { user } = useContext(AuthContext);
  const { Get, Post, Delete, Put } = useApi();
  const storeid = user.store;
  const [banks, setBanks] = useState();
  const [isEditing, setIsEditing] = useState(false); // Track if editing
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const [showModalAtm, setShowModalAtm] = useState(false);

  const toggleModal = () => {
    if (showModalAtm) {
      resetFormData(); // Clear form data only when closing the modal
    }
    setShowModalAtm(!showModalAtm);
    // Prevent automatic focus when modal is opened

    if (!showModalAtm) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 0);
    }
  };
  const [atmDeposit, setAtmDeposit] = useState();
  useEffect(() => {
    // fetchBanks();
    fetchAtmData();
  }, []);


  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked); // Set checkbox state

    // Call the bank API only if the checkbox is checked
    if (isChecked) {
      try {
        await fetchBanks();
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    }
  };

  const fetchAtmData = async () => {
    try {
      const atmDepositResponse = await Get("transactionData");
      setAtmDeposit(filterByTransactionType(atmDepositResponse, "ATM Deposit"));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  function filterByTransactionType(dataArray, transactionType) {
    return dataArray.filter(record => record.transaction_type === transactionType);
}

  const fetchBanks = async () => {
    try {
      const data = await Get("bank");
      setBanks(data);
    } catch (error) {
      console.error("Error fetching banks:", error);
    }
  };
  const handleDateChange = (e) => {
    const { value } = e.target;

    // Ensure the date value is properly formatted to YYYY-MM-DD
    setFormData((prevData) => ({
      ...prevData,
      date: value, // This will be in the correct format for the <input type="date">
    }));
  };

  const handleChange = (e) => {
   const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const today = new Date().toISOString().split("T")[0];

  const [formData, setFormData] = useState({
    store: storeid,
    sr_no: 3,
    date: today, // default to today
    transaction_type: "ATM Deposit",
    bank: "",
    amount: "",
    cash_amount: "",
    cheque_amount: "",
    cheque_no: "",
    total_amount: "",
    note: "",
    atm_commission: "",
    income_type: null,
    bank_deposite_type: null,
    owner: null,
  });

  const handleAtmDeposit = async (event) => {
    event.preventDefault(); // Prevent page reload

    const { sr_no, ...formDataWithoutSrNo } = formData;

    // Always set pay_method_status to null
    formDataWithoutSrNo.pay_method_status = null;

    // If the checkbox is not checked, set pay_method to null
    if (!isChecked) {
      formDataWithoutSrNo.pay_method = null; // Set pay_method to null if checkbox is not checked
    }

    if (!formData.bank_deposite_type) {
      toast.error("Please select a valid Deposit type.");
      return;
    }
    if (!formData.amount) {
      toast.error("Please Enter Amount")
      return; // Stop form submission if the amount is invalid
    }

    try {
      const updatedFormData = {
        ...formDataWithoutSrNo,
        store: storeid,
      };

      if (isEditing) {
        // Update request (PUT)
        const response = await Put(
          "transactionData",
          currentRecord.id,
          updatedFormData
        );
        if (response && (response.status === 200 || response.status === 204)) {
          toast.success("ATM Deposit updated successfully!");
          // Optionally reset form after successful update
          resetFormData();
          setIsEditing(false); // Reset edit flag
           setShowModalAtm(false);
           fetchAtmData()
        }
      } else {
        // Create request (POST)
        const response = await Post("transactionData", updatedFormData);
        if (response && (response.status === 200 || response.status === 201)) {
          toast.success("ATM Deposit added successfully!");
          resetFormData();
          setShowModalAtm(false);
          fetchAtmData();
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Submission failed: " + error.message);
    }
  };

  // Reset the form data after submission
  const resetFormData = () => {
    setIsChecked(false);
    setFormData({
      ...formData,
      date: today,
      amount: "",
      total_amount: "",
      cheque_no: "",
      note: "",
      atm_commission: " ",
      owner: null,
      bank: "",
      cash_amount: "",
      cheque_amount: "",
      income_type: null,
      bank_deposite_type: null,
      pay_method: "", // Reset this correctly
      pay_method_status: null, // Reset this to null
      store: storeid,
    });
  };

  const handleEditAtmDeposit = (record) => {
    setIsEditing(true);
    setCurrentRecord(record);
    setFormData({
      ...record,
      date: record.date || "",
      bank: record.bank || "",
      atm_commission: record.atm_commission || "",
      note: record.note || "",
      amount: record.amount || "",
      cheque_no: record.cheque_no || "",
    });
    setShowModalAtm(true);
  };

  const handleDeleteAtmDeposit = async (id) => {
    const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
    });

    // Check if the user clicked the confirm button
    if (!result.isConfirmed) return; // Exit if the user cancels

    try {
        await Delete("transactionData", id); // Call delete API with the ATM deposit ID
        setAtmDeposit((prevAtmDeposit) =>
            prevAtmDeposit.filter((atmDeposit) => atmDeposit.id !== id)
        );
        toast.success("ATM Deposit deleted successfully");
    } catch (error) {
        console.error("Error deleting ATM Deposit:", error);
        toast.error("Error deleting ATM Deposit");
    }
};

  const columns = [
    { header: "Sr", field: "sr_no" },
    { header: "Date", field: "date" },
    { header: "Type", field: "bank_deposite_type" },
    { header: "Note", field: "note" },
    { header: "Atm Commission", field: "atm_commission" },
    { header: "Amount", field: "amount" },
  ];
  return (
    <>
    <ToastContainer />
    <div className="due-days">
      <div className="header-row mb-4">
        <div className="setting-title">ATM Deposit</div>
        <button className="gas-invoice-add-button" onClick={toggleModal}>
          + Add ATM Deposit
        </button>
      </div>

      {showModalAtm && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1000,
          }}
          onClick={toggleModal} // Close modal when clicking outside
        >
          <div
            onClick={(e) => e.stopPropagation()} // Prevent close when clicking inside modal
            style={{
              width: "700px",
              backgroundColor: "white",
              margin: "100px auto",
              // padding: "20px",
              borderRadius: "8px",
              position: "relative",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="modal-header" style={{ border: "none" }}>
              <h5 className="modal-title" style={{ fontWeight: "bold" }}>
              {isEditing ? "Edit ATM Deposit" : "Add ATM Deposit"}
              </h5>
              <svg
                onClick={toggleModal}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginBottom: "20px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              >
                <path
                  d="M1 1L13 13M13 1L1 13"
                  stroke="black"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="modal-body">
              <form onSubmit={handleAtmDeposit}>
                <div className="form-row form-group col-md-5 position-relative">
                  <input
                    style={{
                      backgroundColor: "#0023000C",
                      borderRadius: "10px",
                      height: "52px",
                    }}
                    type="date"
                    className="form-control"
                    name="date"
                    value={formData.date}
                    onChange={handleDateChange}
                    required
                  />
                </div>

                <div className="d-flex col-md-12">
                  <div className="form-row d-flex col-md-6">
                    <select
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      className="form-control"
                      id="bank_deposite_type"
                      name="bank_deposite_type"
                      value={formData.bank_deposite_type}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Type</option>
                      <option value="Business cash">Business Cash</option>
                      <option value="Gas cash">Gas Cash</option>
                      <option value="Lottery cash">Lottery Cash</option>
                    </select>
                  </div>
                </div>

                <div
                  className="form-row d-flex col-md-12"
                  style={{ padding: "10px" }}
                >
                  <label>
                    <input
                      type="checkbox"
                      id="prepaidTax"
                      checked={isChecked}
                      onChange={handleCheckboxChange} // Handle checkbox state change
                    />
                    &nbsp; Do you want to Add ATM Commission?
                  </label>
                </div>
                {isChecked && (
  <div>
    {/* USD Amount Field for ATM Commission */}
    <div className="form-row d-flex col-md-6 ">
      <div className="containercolor">
        <div
          className="input-group col-md-6 p-2 mt-0"
          style={{
            backgroundColor: "#0023000C",
            borderRadius: "10px",
            height: "52px",
            border: "1px lightgray solid",
          }}
        >
          <div
            className="input-group-prepend"
            style={{
              background: "#F5F5F5",
              height: "100%",
              borderRadius: "10px 0 0 10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              className="input-group-text prefixtext"
              style={{
                border: "none",
                fontSize: "14px",
              }}
            >
              USD
            </span>
          </div>
          <input
            type="text"
            className="form-control sampletext"
            aria-label="Username"
            aria-describedby="addon-wrapping"
            placeholder="Amount"
            name="atm_commission"
            value={formData.atm_commission}
            onChange={handleChange}
            onInput={(e) => {
              // Allow only numbers and a decimal point with two digits after it
              e.target.value = e.target.value
                .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
            }}
      
          />
        </div>
      </div>
    </div>

    {/* Payment Method Radio Buttons */}
    <div className="d-flex col-md-12 p-2">
      <div className="form-check me-3">
        <input
          type="radio"
          className="form-check-input"
          name="pay_method"
          value="cash"
          checked={formData.pay_method === "cash"}
          onChange={handleChange}
        />
        <label className="form-check-label">Cash</label>
      </div>
      <div className="form-check me-3">
        <input
          type="radio"
          className="form-check-input"
          name="pay_method"
          value="cheque"
          checked={formData.pay_method === "cheque"}
          onChange={handleChange}
        />
        <label className="form-check-label">Cheque</label>
      </div>
      <div className="form-check">
        <input
          required
          type="radio"
          className="form-check-input"
          name="pay_method"
          value="bank"
          checked={formData.pay_method === "bank"}
          onChange={handleChange}
        />
        <label className="form-check-label">Bank Card (ACH/EFT)</label>
      </div>
    </div>

    <div className="row">
      {/* Bank Dropdown - only visible for Bank Card or Cheque */}
      {(formData.pay_method === "bank" || formData.pay_method === "cheque") && (
        <div className="mb-3 mt-2 col-md-4">
          <select
            style={{
              backgroundColor: "#0023000C",
              borderRadius: "10px",
              height: "52px",
            }}
            className="form-control"
            name="bank"
            value={formData.bank}
            onChange={handleChange}
            required
          >
            <option value="">Select a bank</option>
            {banks.map((bank) => (
              <option key={bank.id} value={bank.id}>
                {bank.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Cheque Number Input - only visible for Cheque */}
      {formData.pay_method === "cheque" && (
        <div className="mb-3 mt-2 col-md-4">
          <input
            style={{
              backgroundColor: "#0023000C",
              borderRadius: "10px",
              height: "52px",
              border: "1px lightgray solid",
            }}
            type="text"
            name="cheque_no"
            value={formData.cheque_no || ""} // Ensure it's controlled
            placeholder="Cheque No"
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  </div>
)}
                <div className="form-row d-flex col-md-12">
                  <textarea
                    style={{ backgroundColor: "#fafbfa" }}
                    className="form-control expenformtextarea"
                    id="notes"
                    rows="3"
                    placeholder="Notes"
                    name="note"
                    value={formData.note} // Bind the textarea value to formData.note
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="form-row d-flex col-md-6 ">
                  <div class="containercolor">
                    <div
                      class="input-group col-md-6 p-2 mt-2"
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                        border: "1px lightgray solid",
                      }}
                    >
                      <div
                        class="input-group-prepend"
                        style={{
                          background: "#F5F5F5",
                          height: "100%",
                          borderRadius: "10px 0 0 10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          class="input-group-text prefixtext"
                          style={{
                            border: "none",
                            fontSize: "14px",
                          }}
                        >
                          USD
                        </span>
                      </div>
                      <input
                        type="text"
                        name="amount"
                        className="form-control sampletext"
                        value={formData.amount}
                        onChange={handleChange}
                        placeholder="Amount"
                        aria-label="Username"
                        aria-describedby="addon-wrapping"
                        onInput={(e) => {
                          // Allow only numbers and a decimal point with two digits after it
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                            .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                            .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer" style={{ border: "none" }}>
                  <button
                    type="button"
                    className="btn btn-secondary-outline"
                    onClick={toggleModal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <SettingTable
        data={atmDeposit}
        columns={columns}
        showFooter={true}
        showAction={true}
        showExport={true}
        showFilter={false}
        onEdit={handleEditAtmDeposit}
        onDelete={handleDeleteAtmDeposit}
      />
    </div>
    </>
  );
}
