/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useNavigate } from "react-router-dom";
import success from "../../../assets/img/Success.png";
function SuccessMsg() {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/login");
  };
  return (
    <div className="container" style={{ maxWidth: "500px" }}>
      <div className="row justify-content-center align-items-center">
        <p
          className="card-title mb-3 mt-4"
          style={{
            fontWeight: 700,
            fontSize: "24px",
          }}
        >
          Your Password Is Successfully Changed
          <img
            src={success}
            style={{ height: "24px", width: "24px", marginLeft: "10px" }}
          />
        </p>
        <div className="d-flex justify-content-between mb-3"></div>
        <button
          type="button"
          className="btn btn-primary rounded-pill"
          style={{
            width: "100%",
            height: "60px",
            backgroundColor: "#4545DB",
            color: "#ffffff",
            padding: "20px 40px 20px 40px",
            fontFamily: "Manrope, sans-serif",
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "20px",
            border: "none",
            cursor: "pointer",
            outline: "none",
            boxShadow: "none",
          }}
          onFocus={(e) => (e.target.style.boxShadow = "none")} // Ensure no shadow on focus
          onClick={handleSubmit}
        >
          BACK TO LOGIN
        </button>
      </div>
    </div>
  );
}
export default SuccessMsg;
