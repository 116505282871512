/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import HouseChargeTable from "./HouseChargeTable";
import Modal from "react-modal";
import { toast } from "react-toastify";

function HouseCharges() {
  const [gas, setGas] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null); // Store selected row data
  const [paymentType, setPaymentType] = useState(""); // To track the selected payment type
  const [selectedBank, setSelectedBank] = useState(""); // To track the selected bank
  const [chequeNumber, setChequeNumber] = useState(""); // To track the cheque number
  const { Get, Patch, Delete } = useApi();
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const fetchGasData = async () => {
      try {
        const response = await Get("houseChargeData");

        setGas(response);
        // setGas(Array.isArray(response) ? response : []);
      } catch (error) {
        console.error("Error fetching gas data:", error);
      }
    };
    fetchGasData();
    fetchBank();
  }, []);
  const handleDeleteHouseCharge = async () => {
    if (!selectedCharge) return;

    try {
      const response = await Delete("houseChargeData", selectedCharge.id);

      setGas((prevGas) =>
        prevGas.filter((item) => item.id !== selectedCharge.id)
      );
      closeModal();
      toast.success("House charge deleted successfully!");
    } catch (error) {
      // console.error("Error deleting house charge:", error);
      toast.error("An error occurred while deleting the charge.");
    } finally {
      // Ensure the modal closes after submission, even if there's an error
      closeModal();
    }
  };

  const handleEnableDisable = (id, currentStatus) => {
    const selectedCharge = gas.find((item) => item.id === id);
    if (selectedCharge) {
      openModal(selectedCharge);
    }
  };

  const fetchBank = async () => {
    try {
      const [bankResponse] = await Promise.all([Get("bank")]);
      setBanks(bankResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCharge) return;

    try {
      const response = await Patch("houseChargeData", selectedCharge.id, {
        ...selectedCharge,
        pay_method: paymentType,
        bank: selectedBank,
        cheque_no: chequeNumber,
        notes: selectedCharge.notes,
        is_paid: true,
      });

      // Update the state with the updated charge details
      setGas((prevGas) =>
        prevGas.map((item) =>
          item.id === selectedCharge.id
            ? {
                ...item,
                pay_method: paymentType,
                bank: selectedBank,
                cheque_no: chequeNumber,
                notes: selectedCharge.notes,
                is_paid: true,
              }
            : item
        )
      );

      // Close the modal based on the response status
      response.success && closeModal();
    } catch (error) {
      console.error("Error updating charge:", error);
    } finally {
      // Ensure the modal closes after submission, even if there's an error
      closeModal();
    }
  };

  const openModal = (charge) => {
    setSelectedCharge(charge); // Set selected row data
    setPaymentType(charge.pay_method || ""); // Set payment type from selected row
    setSelectedBank(charge.bank || ""); // Set selected bank from row data
    setChequeNumber(charge.cheque_no || ""); // Set cheque number if available
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setSelectedCharge(null); // Clear selected charge
    setIsModalOpen(false); // Close the modal
  };

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
    setSelectedBank(""); // Reset the bank selection when payment type changes
    setChequeNumber(""); // Reset cheque number when payment type changes
  };
  const columns = [
    { header: "Date", field: "bill_date" },
    { header: "Gas Company", field: "company_title" },
    { header: "Amount", field: "amount" },
  ];

  return (
    <>
      <div className="due-days">
        <HouseChargeTable
          data={gas}
          columns={columns}
          showAction={true}
          showFooter={true}
          handleRowClick={openModal} // Pass row click handler
          handleEnableDisable={handleEnableDisable}
        />
      </div>

      {/* Modal for Charge Details */}
      {isModalOpen && selectedCharge && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Mark As Received"
          style={{
            content: {
              width: "55%",
              height: "40%",
              margin: "auto",
              padding: "20px",
              borderRadius: "8px",
            },
          }}
        >
          {selectedCharge.is_paid ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px",
                }}
              >
                <h3>Transaction Details</h3>
                <svg
                  onClick={closeModal}
                  width="26"
                  height="24"
                  viewBox="0 0 26 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4221 6.45104C20.5148 6.36563 20.5883 6.26421 20.6385 6.15258C20.6886 6.04094 20.7145 5.92127 20.7146 5.80041C20.7147 5.67954 20.689 5.55985 20.6389 5.44815C20.5889 5.33646 20.5155 5.23496 20.423 5.14944C20.3305 5.06393 20.2206 4.99607 20.0997 4.94975C19.9787 4.90343 19.8491 4.87955 19.7181 4.87947C19.5872 4.8794 19.4575 4.90313 19.3365 4.94931C19.2155 4.9955 19.1056 5.06323 19.0129 5.14864L13 10.699L6.98879 5.14864C6.80169 4.97593 6.54793 4.87891 6.28332 4.87891C6.01872 4.87891 5.76496 4.97593 5.57786 5.14864C5.39076 5.32135 5.28564 5.55559 5.28564 5.79984C5.28564 6.04409 5.39076 6.27833 5.57786 6.45104L11.5908 11.9998L5.57786 17.5486C5.48522 17.6342 5.41173 17.7357 5.36159 17.8474C5.31145 17.9591 5.28564 18.0789 5.28564 18.1998C5.28564 18.3208 5.31145 18.4405 5.36159 18.5523C5.41173 18.664 5.48522 18.7655 5.57786 18.851C5.76496 19.0237 6.01872 19.1208 6.28332 19.1208C6.41434 19.1208 6.54408 19.097 6.66512 19.0507C6.78616 19.0044 6.89615 18.9366 6.98879 18.851L13 13.3006L19.0129 18.851C19.2 19.0235 19.4537 19.1204 19.7181 19.1202C19.9826 19.1201 20.2361 19.0229 20.423 18.8502C20.6099 18.6775 20.7148 18.4434 20.7146 18.1993C20.7144 17.9552 20.6092 17.7211 20.4221 17.5486L14.4092 11.9998L20.4221 6.45104Z"
                    fill="black"
                  />
                </svg>
              </div>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "10px",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#f8f9fa", textAlign: "left" }}>
                    <th
                      style={{
                        padding: "8px",
                        borderBottom: "1px solid #dee2e6",
                      }}
                    >
                      Date
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        borderBottom: "1px solid #dee2e6",
                      }}
                    >
                      Type
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        borderBottom: "1px solid #dee2e6",
                      }}
                    >
                      Amount
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        borderBottom: "1px solid #dee2e6",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                    <td style={{ padding: "8px" }}>
                      {selectedCharge.bill_date}
                    </td>
                    <td style={{ padding: "8px" }}>
                      {selectedCharge.pay_method}
                    </td>
                    <td style={{ padding: "8px" }}>${selectedCharge.amount}</td>
                    <td style={{ padding: "8px", textAlign: "center" }}>
                      <svg
                        onClick={handleDeleteHouseCharge}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.00016 12.6667C4.00016 13.4 4.60016 14 5.3335 14H10.6668C11.4002 14 12.0002 13.4 12.0002 12.6667V6C12.0002 5.26667 11.4002 4.66667 10.6668 4.66667H5.3335C4.60016 4.66667 4.00016 5.26667 4.00016 6V12.6667ZM12.0002 2.66667H10.3335L9.86016 2.19333C9.74016 2.07333 9.56683 2 9.3935 2H6.60683C6.4335 2 6.26016 2.07333 6.14016 2.19333L5.66683 2.66667H4.00016C3.6335 2.66667 3.3335 2.96667 3.3335 3.33333C3.3335 3.7 3.6335 4 4.00016 4H12.0002C12.3668 4 12.6668 3.7 12.6668 3.33333C12.6668 2.96667 12.3668 2.66667 12.0002 2.66667Z"
                          fill="#002300"
                        />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <form onSubmit={handleFormSubmit}>
              <div className="d-flex justify-content-between mb-4">
                <h3>Mark As Receiver</h3>
                <div>
                  <button
                    type="button"
                    className="btn btn-contained me-2"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleFormSubmit}
                  >
                    Save
                  </button>
                </div>
              </div>

              <div className="col-md-12 row mb-3">
                {/* Bill Date */}
                <div className="mb-3 col-md-4">
                  <input
                    type="date"
                    className="form-control-borderless"
                    name="date"
                    value={selectedCharge.bill_date || ""}
                    onChange={(e) =>
                      setSelectedCharge({
                        ...selectedCharge,
                        bill_date: e.target.value,
                      })
                    }
                    required
                  />
                </div>
                <div className="mb-3 col-md-4">
                  <input
                    type="text"
                    className="form-control-borderless"
                    name="amount"
                    value={selectedCharge.amount || ""}
                    onChange={(e) =>
                      setSelectedCharge({
                        ...selectedCharge,
                        amount: e.target.value,
                      })
                    }
                    placeholder="Amount"
                    required
                  />
                </div>
                <div className="mb-3 col-md-4">
                  <input
                    type="text"
                    className="form-control-borderless"
                    name="companyTitle"
                    value={selectedCharge.company_title || ""}
                    onChange={(e) =>
                      setSelectedCharge({
                        ...selectedCharge,
                        company_title: e.target.value,
                      })
                    }
                    placeholder="Notes"
                  />
                </div>
              </div>

              {/* Payment Type */}
              <div className="col-md-12 row mb-3">
                <div className="d-flex flex-row">
                  <span>Payment Method:&nbsp;&nbsp;&nbsp;</span>
                  {["cheque", "cash", "bank"].map((type) => (
                    <div className="form-check me-3" key={type}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="payment_type"
                        id={`type-${type.toLowerCase()}`}
                        value={type}
                        onChange={handlePaymentTypeChange}
                        checked={paymentType === type}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`type-${type.toLowerCase()}`}
                      >
                        {type}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mb-3 col-md-4">
                <input
                  type="text"
                  className="form-control-borderless"
                  name="note"
                  value={selectedCharge.notes || ""}
                  onChange={(e) =>
                    setSelectedCharge({
                      ...selectedCharge,
                      notes: e.target.value,
                    })
                  }
                  placeholder="Notes"
                />
              </div>
              {/* Conditional Bank Selection and Cheque Number */}
              {(paymentType === "cheque" || paymentType === "bank") && (
                <div className="d-flex mb-3">
                  <div className="col-md-6 me-3">
                    <select
                      required
                      className="form-control-borderless"
                      name="bank"
                      value={selectedCharge.bank || ""}
                      onChange={(e) =>
                        setSelectedCharge({
                          ...selectedCharge,
                          bank: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Bank</option>
                      {banks.map((bank) => (
                        <option key={bank.id} value={bank.name}>
                          {bank.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    {paymentType === "cheque" && (
                      <input
                        type="text"
                        className="form-control-borderless"
                        name="cheque_number"
                        value={chequeNumber}
                        onChange={(e) => setChequeNumber(e.target.value)}
                        placeholder="Cheque Number"
                        required
                      />
                    )}
                  </div>
                </div>
              )}
            </form>
          )}
        </Modal>
      )}
    </>
  );
}

export default HouseCharges;
