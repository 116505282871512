import React from "react";
import { ReportIcon } from "../../../../utils/api-manager/Forms/SvgIcons";

const PrintReport = ({ reportData, selectedShift, today }) => {
  const printReport = () => {
    window.open('/report-preview', '_blank', 'noopener,noreferrer');
//     const printWindow = window.open("", "_blank");
//     printWindow.document.write(`
//   <!DOCTYPE html>
// <html lang="en">
// <head>
//   <meta charset="UTF-8">
//   <meta name="viewport" content="width=device-width, initial-scale=1.0">
//   <title>Report Page</title>
//   <style>
//     body {
//       font-family: Arial, sans-serif;
//       background-color: #f2f2f2;
//       margin: 0;
//       padding: 20px;
//     }
//     .container {
//       display: grid;
//       grid-template-columns: repeat(3, 1fr);
//       gap: 20px;
//       padding: 20px;
//     }
//     .section {
//       display: flex;
//       flex-direction: column;
//       border: 1px solid #e0e0e0;
//       border-radius: 10px;
//       background-color: #f9f9f9;
//       padding: 20px;
//     }
//     table {
//       width: 100%;
//       border-collapse: collapse;
//       margin-top: 10px;
//     }
//     th, td {
//       border: 1px solid #ddd;
//       padding: 8px;
//       text-align: center;
//     }
//     th {
//       background-color: #f1f1f1;
//       font-weight: bold;
//     }
//     .title {
//       text-align: center;
//       font-size: 20px;
//       color: #2084e9;
//     }
//     .total {
//       background-color: #d6ebff;
//       font-weight: bold;
//       border: 1px solid #ccc;
//       padding: 8px;
//     }
//     .header {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       border-radius: 30px;
//       margin-bottom: 20px;
//       padding: 0 20px;
//       box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
//       background: #ffffff;
//     }
//     .header h6, .header p {
//       margin: 0;
//     }
//   </style>
// </head>
// <body>
//   <div class="header">
//     <h6>Humbingo Store</h6>
//     <p style="font-weight: bold;">Shift: 1</p>
//     <p>Report Date: 25/11/2024</p>
//   </div>

//   <div class="container">
//     <!-- DAILY REGISTER CASH OUT -->
//     <div class="section">
//       <h3 class="title">💵 DAILY REGISTER CASH OUT</h3>
//       <table>
//         <thead>
//           <tr>
//             <th>Name</th>
//             <th>Amount</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr><td>Business on report</td><td>$0.00</td></tr>
//           <tr><td>Tax on Report</td><td>$0.00</td></tr>
//           <tr><td>Taxable Sales</td><td>$0.00</td></tr>
//           <tr><td>Non-Taxable Sales</td><td>$0.00</td></tr>
//         </tbody>
//       </table>
//     </div>

//     <!-- DAILY LOTTERY REPORT -->
//     <div class="section">
//       <h3 class="title">🎰 DAILY LOTTERY REPORT</h3>
//       <table>
//         <thead>
//           <tr>
//             <th>Today Invoice</th>
//             <th>Yesterday Invoice</th>
//             <th>Debit/Credit Card</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr><td>Online Net Sale: $0.00</td><td>$0.00</td><td>Debit/Credit Card: $0.00</td></tr>
//           <tr><td>Online Cashing: $0.00</td><td>$0.00</td><td>Credits Sale: $0.00</td></tr>
//           <tr><td>Instant Cashing: $0.00</td><td>$0.00</td><td>Debits Sale: $0.00</td></tr>
//         </tbody>
//       </table>
//     </div>

//     <!-- GAS DAILY REPORT -->
//     <div class="section">
//       <h3 class="title">⛽ GAS DAILY REPORT</h3>
//       <table>
//         <thead>
//           <tr>
//             <th>Gas</th>
//             <th>Gallon</th>
//             <th>Price</th>
//             <th>Revenue</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr><td>Premium</td><td>0</td><td>$0.00</td><td>$0.00</td></tr>
//           <tr><td>Regular</td><td>0</td><td>$0.00</td><td>$0.00</td></tr>
//           <tr><td>Diesel</td><td>0</td><td>$0.00</td><td>$0.00</td></tr>
//         </tbody>
//       </table>
//     </div>
//   </div>
// </body>
// </html>

//     `);
//     printWindow.document.close();
  };

  return (
    <button className="business-button" onClick={printReport}>
      <ReportIcon />
      Print Report
    </button>
  );
};

export default PrintReport;
