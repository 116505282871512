/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useContext, useEffect } from "react";
import { toast,ToastContainer } from "react-toastify";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../.././../utils/secure-route/AuthContext";
import SettingTable from "../ManageSettings/SettingTable";
import "./GeneralLedger.css";
import Swal from "sweetalert2";

export default function GeneralLedgerBank() {
  const { user } = useContext(AuthContext);
  const { Get, Post, Delete , Put} = useApi();
  const storeid = user.store;
  const [banks, setBanks] = useState();
  const [isEditing, setIsEditing] = useState(false); // Track if editing
  const [currentRecord, setCurrentRecord] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [bankDeposits, setBankDeposits] = useState([]); // State to hold bank deposit records


  const toggleModal = () => setShowModal(!showModal);
  const [bankDeposit, setBankDeposit] = useState();
  const today = new Date().toISOString().split("T")[0];

  const [formData, setFormData] = useState({
    store: storeid,
    sr_no: "",
    date: today, // default to today
    transaction_type: "Bank Deposit",
    bank: "",
    amount: "",
    cash_amount: "",
    cheque_amount: "",
    total_amount: "",
    note: "",
    bank_deposite_type: null,
    owner: null,
  });


  useEffect(() => {
 
    fetchBankData();
  }, []);

  const fetchBankData = async () => {
    try {
      const resp = await Get("transactionData");
      const filteredData = filterByTransactionType(resp, "Bank Deposit");
      setBankDeposit(filteredData); // Assuming this is for another purpose
      setBankDeposits(filteredData); // Store the fetched deposits in state
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  function filterByTransactionType(dataArray, transactionType) {
    return dataArray.filter(record => record.transaction_type === transactionType);
}
  const fetchBanks = async () => {
    try {
      const data = await Get("bank");
      setBanks(data);
    } catch (error) {
      console.error("Error fetching banks:", error);
    }
  };
  const handleDateChange = (e) => {
    const { value } = e.target;

    // Ensure the date value is properly formatted to YYYY-MM-DD
    setFormData((prevData) => ({
      ...prevData,
      date: value, // This will be in the correct format for the <input type="date">
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTotalAmountChange = (e) => {
    const { name, value } = e.target;

    // Update the state with the new value for the respective field
    setFormData((prevData) => {
      const newFormData = { ...prevData, [name]: value };

      // Automatically calculate total_amount when either cash_amount or cheque_amount changes
      if (name === "cash_amount" || name === "cheque_amount") {
        const cashAmount = parseFloat(newFormData.cash_amount) || 0;
        const chequeAmount = parseFloat(newFormData.cheque_amount) || 0;
        newFormData.total_amount = cashAmount + chequeAmount; // Sum of cash_amount and cheque_amount
      }

      return newFormData;
    });
  };
  const handleBankDeposit = async (event) => {
    event.preventDefault(); // Prevent page reload
    const { sr_no, ...formDataWithoutSrNo } = formData;
  
    // Validation checks
    if (!formData.cash_amount && !formData.cheque_amount) {
      toast.error("Please Enter Cash Amount or Cheque Amount.");
      return;
    }
  
    // Ensure that if one field is provided, the other is also valid (greater than zero)
    if (formData.cash_amount && parseFloat(formData.cash_amount) <= 0) {
      toast.error("Cash Amount must be greater than 0.");
      return;
    }
  
    if (formData.cheque_amount && parseFloat(formData.cheque_amount) <= 0) {
      toast.error("Cheque Amount must be greater than 0.");
      return;
    }
  
    // Check if the deposit type is selected
    if (!formData.bank_deposite_type) {
      toast.error("Please select a valid Deposit type.");
      return;
    }
  
    try {
      const updatedFormData = {
        ...formDataWithoutSrNo,
        store: storeid,
        pay_method_status: null, // Explicitly set to null
        pay_method: null,        // Explicitly set to null
      };
  
      let response;
      if (isEditing) {
        // If editing, perform an update request (PUT)
        response = await Put("transactionData", currentRecord.id, updatedFormData);
        
        // Show success message only for update
        toast.success("Bank Deposit updated successfully!");
      } else {
        // If not editing, perform a create request (POST)
        response = await Post("transactionData", updatedFormData);
        
        // Show success message only for addition
        toast.success("Bank Deposit added successfully!");
      }
  
      if (response && (response.status === 200 || response.status === 201)) {
        fetchBankData();
        
        // Optionally, reset the form data after successful submission
        setFormData({
          ...formData,
          date: "",
          amount: "",
          total_amount: "",
          cheque_no: "",
          note: "",
          owner: null,
          store: storeid,
        });
      }
      setShowModal(false); // Close the modal after submission
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Submission failed: " + error.message);
      setShowModal(false); // Close the modal after an error
    }
  };
  
  
  // Edit function to open the modal with the selected record
  const handleEditBankDeposit = (record) => {
    setIsEditing(true);
    setCurrentRecord(record);
    setFormData({
      ...record,
      date: record.date,
      bank: record.bank || "",
      note: record.note || "",
    });
    handleModalToggle(); // Open the modal for editing
  };
  



  const handleDeleteBankDeposit = async (id) => {

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
  });

  // Check if the user clicked the confirm button
  if (!result.isConfirmed) return; // Exit if the user cancels
    try {
      await Delete("transactionData", id); // Call delete API with the gas type ID
      setBankDeposit((prevBankDeposit) =>
        prevBankDeposit.filter((bankdeposit) => bankdeposit.id !== id)
      );
      toast.success("Bank Deposit deleted successfully");
    } catch (error) {
      console.error("Error deleting Bank Deosit:", error);
      toast.error("Error deleting Bank Deposit");
    }
  };

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };
  
  const resetFormData = () => {
    setFormData({
      store: storeid,
      sr_no: "",
      date: today, // default to today
      transaction_type: "Bank Deposit",
      bank: "",
      amount: "",
      cash_amount: "",
      cheque_amount: "",
      total_amount: "",
      note: "",
      bank_deposite_type: null,
      owner: null,
    });
    setIsEditing(false);
    setCurrentRecord(null);
  };


  const openAddModal = async () => {
    resetFormData(); // Reset the form data
  
    if (!showModal) {
      try {
        await fetchBanks(); // Call the bank API only if the modal is not already open
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    }
  
    handleModalToggle(); // Toggle the modal state
  };
  

  const columns = [
    { header: "Sr", field: "sr_no" },
    { header: "Date", field: "date" },
    { header: "Bank", field: "bank_name" },
    { header: "Note", field: "note" },
    { header: "Type", field: "bank_deposite_type" },
    { header: "Cash Amount", field: "cash_amount" },
    { header: "Cheque Amount", field: "cheque_amount" },
    { header: "Total Amount", field: "total_amount" },
  ];
  return (
    <>
    <ToastContainer />
    <div className="due-days">
      <div className="header-row mb-4">
        <div className="setting-title">Bank Deposit</div>
        <button
          className="gas-invoice-add-button-bank-deposit"
          onClick={openAddModal}
          style={{ Width: "200px" }}
        >
          + Add Bank Deposit
        </button>
      </div>

      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1000,
          }}
          onClick={toggleModal}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "700px",
              backgroundColor: "white",
              margin: "100px auto",
              // padding: "20px",
              borderRadius: "8px",
              position: "relative",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              className="modal-header"
              style={{
                borderBottom: "none",
                paddingBottom: "10px",
                marginBottom: "10px",
              }}
            >
              <h5
                className="modal-title"
                style={{ fontWeight: "bold", marginLeft: "27px" }}
              >
             {isEditing ? "Edit Bank Deposit" : "Add Bank Deposit"}

              </h5>

              <svg
                onClick={toggleModal}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginBottom: "20px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              >
                <path
                  d="M1 1L13 13M13 1L1 13"
                  stroke="black"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="modal-body">
              <form onSubmit={handleBankDeposit}>
                <div className="form-row form-group col-md-4 position-relative">
                  <input
                    style={{
                      backgroundColor: "#0023000C",
                      borderRadius: "10px",
                      height: "52px",
                      marginLeft: "27px",
                    }}
                    type="date"
                    className="form-control"
                    name="date"
                    placeholder="Bill Date"
                    value={formData.date}
                    onChange={handleDateChange}
                    required
                  />
                </div>
                <div className="d-flex mb-3 mt-2 justify-content-around">
                  <div className="col-md-5">
                    <select
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      className="form-control"
                      name="bank"
                      value={formData.bank}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select a bank</option>
                      
                      {banks.map((bank) => (
                        <option key={bank.id} value={bank.id}>
                          {bank.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-5">
                    <select
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      className="form-control"
                      id="bank_deposite_type"
                      name="bank_deposite_type"
                      value={formData.bank_deposite_type}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Type</option>
                      <option value="Business cash">Business Cash</option>
                      <option value="Gas cash">Gas Cash</option>
                      <option value="Lottery cash">Lottery Cash</option>
                    </select>
                  </div>
                </div>

                <div className="form-row d-flex flex-wrap col-md-12 justify-content-around">
                  {/* First Field */}
                  <div className="containercolor col-md-5">
                    <div
                      className="input-group p-2 mt-2"
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "62px",
                        border: "1px lightgray solid",
                      }}
                    >
                      <div
                        className="input-group-prepend"
                        style={{
                          background: "#F5F5F5",
                          height: "100%",
                          borderRadius: "10px 0 0 10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="input-group-text prefixtext"
                          style={{
                            border: "none",
                            fontSize: "14px",
                          }}
                        >
                          USD
                        </span>
                      </div>
                      <input
                        type="number"
                        className="form-control sampletext"
                        aria-label="Username"
                        aria-describedby="addon-wrapping"
                        placeholder="Cash Amount"
                        name="cash_amount"
                        value={formData.cash_amount}
                        onChange={handleTotalAmountChange}
                        onInput={(e) => {
                          // Allow only numbers and a decimal point with two digits after it
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                            .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                            .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                        }}
                      />
                    </div>
                  </div>

                  {/* Second Field */}
                  <div className="containercolor col-md-5">
                    <div
                      className="input-group p-2 mt-2"
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "62px",
                        border: "1px lightgray solid",
                      }}
                    >
                      <div
                        className="input-group-prepend"
                        style={{
                          background: "#F5F5F5",
                          height: "100%",
                          borderRadius: "10px 0 0 10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="input-group-text prefixtext"
                          style={{
                            border: "none",
                            fontSize: "14px",
                          }}
                        >
                          USD
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control sampletext"
                        aria-label="Username"
                        aria-describedby="addon-wrapping"
                        placeholder="Cheque Amount"
                        name="cheque_amount"
                        value={formData.cheque_amount}
                        onChange={handleTotalAmountChange}
                        onInput={(e) => {
                          // Allow only numbers and a decimal point with two digits after it
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                            .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                            .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* Third Field Centered */}
                <div className="form-row d-flex justify-content-center col-md-12 mt-3 ">
                  <div className="containercolor col-md-5">
                    <div
                      className="input-group p-2 mt-2"
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "62px",

                        border: "1px lightgray solid",
                      }}
                    >
                      <div
                        className="input-group-prepend"
                        style={{
                          background: "#F5F5F5",
                          height: "100%",
                          borderRadius: "10px 0 0 10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="input-group-text prefixtext"
                          style={{
                            border: "none",
                            fontSize: "14px",
                          }}
                        >
                          USD
                        </span>
                      </div>
                      <input
                        readOnly
                        type="text"
                        name="total_amount"
                        className="form-control sampletext"
                        value={formData.total_amount}
                        onChange={handleChange}
                        placeholder="Total Amount"
                        aria-label="Username"
                        aria-describedby="addon-wrapping"
                        onInput={(e) => {
                          // Allow only numbers and a decimal point with two digits after it
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                            .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                            .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row d-flex col-md-12">
                  <textarea
                    style={{
                      backgroundColor: "#fafbfa",
                      marginTop: "10px",
                      justifyContent: "center",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                    className="form-control expenformtextarea"
                    id="note"
                    rows="3"
                    placeholder="Notes"
                    name="note"
                    value={formData.note} // Bind the textarea value to formData.note
                    onChange={handleChange} // Update formData.note on change
                  ></textarea>
                </div>

                <div className="modal-footer" style={{ border: "none" }}>
                  <button
                    type="button"
                    className="btn btn-secondary-outline"
                    onClick={toggleModal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <SettingTable
        data={bankDeposit}
        columns={columns}
        showFooter={true}
        showAction={true}
        showExport={true}
        showFilter={false}
        onEdit={handleEditBankDeposit}
        onDelete={handleDeleteBankDeposit}
      />
    </div>
    </>
  );
}
