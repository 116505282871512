import React, { useEffect, useState } from 'react';
import useApi from '../../../../../utils/api-manager/Helper/useApi';
import { MoreHoriz } from '@mui/icons-material';
import { toast ,ToastContainer} from "react-toastify";

const ExpenseBreakdown = () => {
  const [vendors, setVendors] = useState([]);
  const { Post } = useApi();

  useEffect(() => { 
    fetchExpenseData();
  }, []);
 
  const fetchExpenseData = async () => {
    try {
      const response = await Post('dashboardExpense');
      // console.log("API Response:", response);

      if (Array.isArray(response?.data?.top_vendors)) {
        setVendors(response.data.top_vendors);
        // toast.success("Expense data loaded successfully!");
      } else {
        console.error("Expected top_vendors to be an array.");
        toast.error("Error: Expected top vendors data to be an array.");
      }
    } catch (error) {
      console.error("Error fetching expense data:", error);
      toast.error("Error fetching expense data: " + (error.response ? error.response.data : error.message));
    }
  };
 
 

  // Calculate combined percentage (e.g., average percentage)
  const combinedPercentage = vendors.reduce((total, vendor) => total + vendor.percentage, 0) / vendors.length;

  return (
    <div style={{
      maxWidth: '508px',
      height: '250px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      padding: '16px',
      marginTop: '20px',
      backgroundColor: '#fff',
    }}>
      {/* Header */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h6 style={{ margin: 0, fontWeight: 'bold' }}>Expense Breakdown</h6>
        <button
          style={{
            background: 'none',
            border: 'none',
            color: '#999',
            fontSize: '18px',
            cursor: 'pointer',
          }}
        >
          <MoreHoriz />
        </button>
      </div>

      {/* Vendor Data */}
      <div style={{ marginTop: '16px', display: 'flex', flexDirection: 'row', gap: '12px' }}>
        {Array.isArray(vendors) && vendors.length > 0 ? (
          vendors.map((vendor) => (
            <div
              key={vendor.vendor_name}
              style={{
                color: 'black',
                borderLeft: '1px solid gray',
                padding: '8px 0',
                textAlign: 'left',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: '8px', // Add gap between vendor name and percentage
                paddingLeft: '8px', // Ensure there's space between border and vendor name
              }}
            >
              {/* Vendor Name */}
              <p style={{ margin: '0', fontSize: '14px', fontWeight: '500', color: 'gray' }}>
                {vendor.vendor_name}
              </p>
              {/* Vendor Percentage */}
              <p style={{
                margin: '0',
                fontSize: '20px',
                fontWeight: '900',
                color: 'black',
              }}>
                {vendor.percentage}%
              </p>
            </div>
          ))
        ) : (
          <p style={{ margin: '0 auto', color: '#999' }}>No vendors available</p>
        )}
      </div>

      {/* Stacked Progress Bar */}
      <div style={{ marginTop: '16px', height: '90px', width: '100%', display: 'flex' }}>
        {Array.isArray(vendors) && vendors.length > 0 ? (
          vendors.map((vendor, index) => {
            // Define styles for each vendor
            let backgroundStyle = '';
            if (index === 0) {
              backgroundStyle = 'rgba(0, 0, 255, 0.5)'; // Blue color for the first vendor
            } else if (index === 1) {
              backgroundStyle = 'repeating-linear-gradient(90deg, lightgray 0, lightgray 5px, transparent 5px, transparent 10px)'; // Light gray with repeating gradient for the second
            } else if (index === 2) {
              backgroundStyle = 'repeating-linear-gradient(90deg, black 0, black 5px, transparent 5px, transparent 10px)'; // Black with repeating gradient for the third
            }

            return (
              <div
                key={vendor.vendor_name}
                style={{
                  width: `${vendor.percentage}%`,
                  background: backgroundStyle,
                  height: '100%',
                  textAlign: 'center',
                  lineHeight: '20px',
                  color: '#fff',
                }}
              />
            );
          })
        ) : (
          <div style={{ width: '100%', textAlign: 'center', color: '#999' }}>
          
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpenseBreakdown
