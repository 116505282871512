// import React from 'react';
// // import 'bootstrap/dist/css/bootstrap.min.css';

// export default function AuditTrailTable({ auditLogs }) {
//   return (
//     <div className="container">
//       <h2>Audit Logs</h2>
//       <table className="table table-bordered table-hover">
//         <thead className="thead-dark">
//           <tr>
//             <th>ID</th>
//             <th>Service Name</th>
//             <th>Endpoint</th>
//             <th>Method</th>
//              <th>User</th>
//             {/* <th>Response Data</th> */}
//             <th>Timestamp</th>
//           </tr>
//         </thead>
//         <tbody>
//           {auditLogs.map((log) => (
//             <tr key={log.id}>
//               <td>{log.id}</td>
//               <td>{log.service_name}</td>
//               <td>{log.endpoint}</td>
//               <td>{log.method}</td>
//               {/* <td>
//                 {JSON.stringify(log.request_data, null, 2)}
//               </td>  */}
//               {/* <td> */}
//                 {/* {JSON.stringify(log.response_data.message, null, 2)} */}
//               {/* </td> */}
//               <td>
//                 {log.user ? (
//                   <>{JSON.stringify(log.user.username, null, 2)}</>
//                 ) : (
//                   'N/A'
//                 )}
//               </td>
//               <td>{new Date(log.timestamp).toLocaleString()}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }



import React, { useState, useEffect } from 'react';

import useApi from '../../../../utils/api-manager/Helper/useApi';

export default function AuditTrailTable() {
  const [logs, setLogs] = useState([]);
  const [count, setCount] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [currentPageUrl, setCurrentPageUrl] = useState('/api/v1/auditLog/?page=1');
  let {getHost, Get} =useApi();
  useEffect(() => {

    // Fetch data from the server when the component mounts or the page changes
    const fetchLogs = async () => {
      try {
      console.log("My URL: ", `${getHost()}${currentPageUrl}`);
        const response = await Get(`${getHost()}${currentPageUrl}`);

        setLogs(response.results);
        setCount(response.count);
        setNextPage(removeHostFromUrl(response.next));
        setPreviousPage(removeHostFromUrl(response.previous));
      } catch (error) {
        console.error('Error fetching audit logs:', error);
      }
    };

    fetchLogs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageUrl]);

  const handlePageChange = (url) => {
    if (url) {
      setCurrentPageUrl(url);
    }
  };
  function removeHostFromUrl(url) {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.pathname + parsedUrl.search;
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  }
  return (
    <div className="container mt-4">
      <h2>Audit Logs</h2>
      <p>Total logs: {count}</p>
      <table className="table table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>Service Name</th>
            <th>Endpoint</th>
            <th>Method</th>
            {/* <th>Request Data</th>
            <th>Response Data</th> */}
            <th>User</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>

          {logs.map((log) => (
            <tr key={log.id}>
              <td>{log.id}</td>
              <td>{log.service_name}</td>
              <td>{log.endpoint}</td>
              <td>{log.method}</td>

              <td>
                {log.user ? (
                  <>{JSON.stringify(log.user.email, null, 2)}</>
                ) : (
                  'N/A'
                )}
              </td>
              <td>{new Date(log.timestamp).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <nav>
        <ul className="pagination justify-content-center">
          <li className={`page-item ${!previousPage && 'disabled'}`}>
            <button className="btn"style={{backgroundColor: "#4545db", color: "#ffffff"}} onClick={() => handlePageChange(previousPage)}>
              Previous
            </button>
          </li>
          <li className={`page-item ${!nextPage && 'disabled'}`}>
            <button className="btn" style={{backgroundColor: "#4545db",color: "#ffffff"}} onClick={() => handlePageChange(nextPage)}>
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}
