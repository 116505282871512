/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ReusableModal from "../ManageSettings/ReusableModal";
import { toast,ToastContainer } from "react-toastify";
import "./Gas.css";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import SettingTable from "../ManageSettings/SettingTable";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import GasTypeTable from "./GasTypeTable";
import Swal from "sweetalert2";

function GasSettings() {
  let { user } = useContext(AuthContext);
  const storeId = user.store;

  const [gasTypeTitle, setGasTypeTitle] = useState("");
  const [costPerGallon, setCostPerGallon] = useState("");
  const [gasTypeData, setGasTypeData] = useState([]);

  const [gasCompany, setGasCompany] = useState("");
  const [companyContact, setCompanyContact] = useState("");
  const [companyEmail, setcompanyEmail] = useState("");
  const [companyData, setCompanyData] = useState([]);

  const [gasName, setGasName] = useState("");
  const [gasCommission, setGasCommission] = useState("");
  const [commissionDate, setCommissionDate] = useState("");
  const [commissionData, setCommissionData] = useState([]);

  const { Get, Post, Put, Delete,Patch } = useApi();

  const [showGasTypeModal, setShowGasTypeModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showCommissionModal, setShowCommissionModal] = useState(false);

  const handleShowGasType = () => setShowGasTypeModal(true);
  const handleCloseGasType = () => {
    setGasTypeTitle("");
    setCostPerGallon("");
    setSelectedGasType(null);
    setShowGasTypeModal(false);
  };
  const handleShowCompany = () => setShowCompanyModal(true);
  const handleCloseCompany = () => {
    setGasCompany("");
    setCompanyContact("");
    setcompanyEmail("");
    setSelectedCompany(null);
    setShowCompanyModal(false);
  };
  const handleShowCommission = () => setShowCommissionModal(true);
  const handleCloseCommission = () => {
    setGasName("");
    setGasCommission("");
    setCommissionDate("");
    setSelectedCommission(null);
    setShowCommissionModal(false);
  };
  const [selectedGasType, setSelectedGasType] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCommission, setSelectedCommission] = useState(null);

  // Handle edit for Gas Type
  const handleEditGasType = (gasType) => {
    setSelectedGasType(gasType);
    setGasTypeTitle(gasType.gas_type_title);
    setCostPerGallon(gasType.cost_per_gallon);
    handleShowGasType();
  };

  // Handle edit for Company
  const handleEditCompany = (company) => {
    setSelectedCompany(company);
    setGasCompany(company.name);
    setCompanyContact(company.contact_no);
    setcompanyEmail(company.email);
    handleShowCompany();
  };

  // Handle edit for Commission
  const handleEditCommission = (commission) => {
    setSelectedCommission(commission);
    setGasName(commission.gas_type);
    setGasCommission(commission.commission_percentage);
    setCommissionDate(commission.date_from);
    handleShowCommission();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gasTypeResponse = await Get("gasTypeData");
        setGasTypeData(gasTypeResponse);

        const companyResponse = await Get("gasCompanyData");
        setCompanyData(companyResponse);

        const commissionResponse = await Get("gasCommissionData");
        setCommissionData(commissionResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const handleGasTypeChange = (e) => {
    const { name, value } = e.target;

    // Handle gas type title change
    if (name === "gas_type_title") {
      setGasTypeTitle(value);
    }

    // Handle cost per gallon change with validation for 10 digits (8 before decimal, 2 after)
    if (name === "cost_per_gallon") {
      // Regular expression to allow up to 8 digits before the decimal and 2 digits after the decimal
      const validCostPerGallon = /^\d{1,8}(\.\d{0,2})?$/;

      if (validCostPerGallon.test(value)) {
        setCostPerGallon(value);
      } else {

      }
    }
  };

  const handleCompanyChange = (e) => {
    const { name, value } = e.target;

    // Handle 'name' change (company name)
    if (name === "name") {
      setGasCompany(value);
    }

    // Handle 'contact_no' change (company contact number)
    if (name === "contact_no") {
      // Remove non-digit characters (optional, in case you want to allow formatting like dashes)
      const cleanedValue = value.replace(/\D/g, "");

      // Only allow 12 digits for contact number
      if (cleanedValue.length <= 12) {
        setCompanyContact(cleanedValue);  // Set the contact number with up to 12 digits
      } else {
        // Optionally, handle the case where the number exceeds 12 digits
      }
    }

    // Handle 'email' change (company email)
    if (name === "email") {
      setcompanyEmail(value);
    }
  };


  const handleCommissionChange = (e) => {
    const { name, value } = e.target;

    // Handle gas type change
    if (name === "gas_type") {
      setGasName(value);
    }

    // Validate and handle commission percentage
    if (name === "commission_percentage") {
      let commissionValue = parseFloat(value);

      // Ensure the commission percentage doesn't exceed 100
      if (commissionValue > 100) {
        commissionValue = 100; // Or display an error message, if preferred
        toast.info("Commission percentage cannot exceed 100%. It has been set to 100%.");
      } else if (commissionValue < 0) {
        commissionValue = 0; // Prevent negative commission values
        toast.error("Commission percentage cannot be negative. It has been set to 0%.");
      }

      setGasCommission(commissionValue);
    }

    // Handle date change
    if (name === "date_from") {
      setCommissionDate(value);
    }
  };


  const handleGasTypeSubmit = async (e) => {
    e.preventDefault();
    const gasData = {
      gas_type_title: gasTypeTitle,
      cost_per_gallon: costPerGallon,
      is_enable: true,
      store: storeId,
    };
    try {
      if (selectedGasType) {
        // Update existing gas type
        await Put("gasTypeData", selectedGasType.id, gasData);
        setGasTypeData((prevData) =>
          prevData.map((item) =>
            item.id === selectedGasType.id ? { ...item, ...gasData } : item
          )
        );
        toast.success("Gas type data updated successfully!");
      } else {
        // Add new gas type
        const response = await Post("gasTypeData", gasData);
        setGasTypeData((prevData) => [...prevData, response.data]);
        toast.success("Gas type data added successfully!");
      }
      handleCloseGasType(); // Close modal after submission
    } catch (error) {
      console.error("Error posting gas type data:", error);
      toast.error("Error adding/updating gas type data.");
    }
  };

  const handleEnableDisable = async (id, isActive) => {
    try {
      // Toggle the is_enable value (true to false or false to true)
      const updatedStatus = !isActive;

      // Update the gas type data (you can call an API to update it on the server)
      await Patch("gasTypeData", id, { is_enable: updatedStatus });

      // Update the local state to reflect the change
      setGasTypeData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, is_enable: updatedStatus } : item
        )
      );

      // Optionally show a toast or notification
      toast.success(`Gas Type ${updatedStatus ? "Enabled" : "Disabled"} successfully!`);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status.");
    }
  };

  const handleDeleteGasType = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
  });

  // Check if the user clicked the confirm button
  if (!result.isConfirmed) return; // Exit if the user cancels
    try {
      await Delete("gasTypeData", id); // Call delete API with the gas type ID
      setGasTypeData((prevGasTypes) =>
        prevGasTypes.filter((gasType) => gasType.id !== id)
      );
      toast.success("Gas type deleted successfully");
    } catch (error) {
      console.error("Error deleting gas type data:", error);
      toast.error("Error deleting gas type");
    }
  };

  const handleCompanySubmit = async (e) => {
    e.preventDefault();
    const gasCompanyData = {
      store: storeId,
      name: gasCompany,
      contact_no: companyContact,
      email: companyEmail,
    };
    try {
      if (selectedCompany) {
        // Update existing company
        await Put("gasCompanyData", selectedCompany.id, gasCompanyData);
        setCompanyData((prevData) =>
          prevData.map((company) =>
            company.id === selectedCompany.id
              ? { ...company, ...gasCompanyData }
              : company
          )
        );
        toast.success("Company data updated successfully!");
      } else {
        // Add new company
        const response = await Post("gasCompanyData", gasCompanyData);
        setCompanyData((prevData) => [...prevData, response.data]);
        toast.success("Company data added successfully!");
      }
      handleCloseCompany(); // Close modal after submission
    } catch (error) {
      toast.error("Error adding/updating company data.");
    }
  };

  const handleDeleteCompanyData = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
  });

  // Check if the user clicked the confirm button
  if (!result.isConfirmed) return; // Exit if the user cancels
    try {
      await Delete("gasCompanyData", id); // Call delete API with the gas type ID
      setCompanyData((prevGasCompanyData) =>
        prevGasCompanyData.filter((gasCompanyData) => gasCompanyData.id !== id)
      );
      toast.success("Gas Coompany deleted successfully");
    } catch (error) {
      console.error("Error deleting gas company:", error);
      toast.error("Error deleting gas company");
    }
  };
  const handleCommissionSubmit = async (e) => {
    e.preventDefault();

    const gasCommissionData = {
      gas_type: gasName,
      commission_percentage: gasCommission,
      date_from: commissionDate,
      store: storeId,
    };

    try {
      if (selectedCommission) {
        // Update existing commission
        await Put(
          "gasCommissionData",
          selectedCommission.id,
          gasCommissionData
        );
        setCommissionData((prevData) =>
          prevData.map((commission) =>
            commission.id === selectedCommission.id
              ? { ...commission, ...gasCommissionData }
              : commission
          )
        );
        toast.success("Commission data updated successfully!");
      } else {
        // Add new commission
        const response = await Post("gasCommissionData", gasCommissionData);
        setCommissionData((prevData) => [...prevData, response.data]);
        toast.success("Commission data added successfully!");
      }
      handleCloseCommission(); // Close modal after submission
    } catch (error) {
      toast.error("Error adding/updating commission data.");
    }
  };
  const handleDeleteGasCommision = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
  });

  // Check if the user clicked the confirm button
  if (!result.isConfirmed) return; // Exit if the user cancels
    try {
      await Delete("gasCommissionData", id); // Call delete API with the gas type ID
      setCommissionData((prevGasCommision) =>
        prevGasCommision.filter((gasCommission) => gasCommission.id !== id)
      );
      toast.success("Gas Commission deleted successfully");
    } catch (error) {
      console.error("Error deleting gas commission:", error);
      toast.error("Error deleting gas commission");
    }
  };
  const gasTypeColumns = [
    { header: "Gas Type Title", field: "gas_type_title" },
    { header: "Cost Per Gallon", field: "cost_per_gallon" },
    { header: "Is Blended", field: "is_blended" },
    { header: "Blended", field: "is_blended" },

  ];
  const companyColumns = [
    { header: "Company Name", field: "name" },
    { header: "Contact No", field: "contact_no" },
    { header: "Email", field: "email" },
  ];
  const commissionColumns = [
    { header: "Gas Type", field: "gas_type_title" },
    { header: "Commission Percentage", field: "commission_percentage" },
    { header: "Date From", field: "date_from" },
  ];
  const handleToggleEnable = async (id, currentStatus) => {
    try {
      const updatedStatus = !currentStatus;
      await Put("gasTypeData", id, { is_enable: updatedStatus });
      setGasTypeData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, is_enable: updatedStatus } : item
        )
      );
      toast.success(`Gas type ${updatedStatus ? "enabled" : "disabled"} successfully!`);
    } catch (error) {
      console.error("Error toggling gas type enable status:", error);
      toast.error("Failed to update gas type status.");
    }
  }
  return (
    <>
    <ToastContainer />
      <div className="due-days">
        <div className="header-row mb-4">
          <div className="setting-title">Gas Types</div>
          <button
            className="gas-invoice-add-button"
            onClick={handleShowGasType}
          >
            + Add Gas Type
          </button>
        </div>
        <ReusableModal
          show={showGasTypeModal}
          handleClose={handleCloseGasType}
          title={selectedGasType ? "Edit Gas Type" : "Add Gas Type"}
          width="1054px"
          height="auto"
        >
          <form onSubmit={handleGasTypeSubmit}>
            <div className="form-group d-flex align-items-center mt-0">
              <input
                type="text"
                name="gas_type_title"
                placeholder="Gas Type Title"
                value={gasTypeTitle}
                onChange={handleGasTypeChange}
                className="input-field"
                required
                style={{
                  width: "400px",
                  marginRight: "16px",
                  height: "44px",
                }}
              />
              <input
                type="number"
                name="cost_per_gallon"
                placeholder="Cost Per Gallon"
                value={costPerGallon}
                onChange={handleGasTypeChange}
                className="input-field"
                required
                style={{
                  width: "400px",
                  marginRight: "16px",
                  height: "44px",
                }}
              />

              <button
                type="submit"
                style={{
                  padding: "6px 8px",
                  backgroundColor: "#4545db",
                  color: "white",
                  border: "none",
                  borderRadius: "40px",
                  cursor: "pointer",
                  width: "124px",
                  height: "42px",
                  fontSize: "18px",
                  marginLeft: "16px",
                }}
              >
                {selectedGasType ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </ReusableModal>

        <GasTypeTable
          data={gasTypeData}
          columns={gasTypeColumns}
          showFooter={true}
          showAction={true}
          onEdit={handleEditGasType}
          onDelete={handleDeleteGasType}
          handleEnableDisable={handleEnableDisable}
        />
        <div className="header-row mt-4 mb-4">
          <div className="setting-title">Gas Companies (For House Charges)</div>
          <button
            className="gas-invoice-add-button"
            onClick={handleShowCompany}
          >
            + Add Company
          </button>
        </div>
        <ReusableModal
          show={showCompanyModal}
          handleClose={handleCloseCompany}
          title={selectedCompany ? "Edit Gas Company" : "Add Gas Company"}
          width="1054px"
          height="auto"
        >
          <form onSubmit={handleCompanySubmit}>
            <div className="form-group d-flex align-items-center mt-0">
              <input
                type="text"
                name="name"
                placeholder="Company Name"
                value={gasCompany}
                onChange={handleCompanyChange}
                className="input-field"
                required
                style={{
                  width: "400px",
                  marginRight: "16px",
                  height: "44px",
                }}
              />
              <input
                type="number"
                name="contact_no"
                placeholder="Contact No"
                value={companyContact}
                onChange={handleCompanyChange}
                className="input-field"
                required
                style={{
                  width: "400px",
                  marginRight: "16px",
                  height: "44px",
                }}
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={companyEmail}
                onChange={handleCompanyChange}
                className="input-field"
                required
                style={{
                  width: "400px",
                  marginRight: "16px",
                  height: "44px",
                }}
              />

              <button
                type="submit"
                style={{
                  padding: "6px 8px",
                  backgroundColor: "#4545db",
                  color: "white",
                  border: "none",
                  borderRadius: "40px",
                  cursor: "pointer",
                  width: "124px",
                  height: "42px",
                  fontSize: "18px",
                  marginLeft: "16px",
                }}
              >
                 {selectedCompany ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </ReusableModal>

        <SettingTable
          data={companyData}
          columns={companyColumns}
          showFooter={true}
          showAction={true}
          onEdit={handleEditCompany}
          onDelete={handleDeleteCompanyData}
        />
        <div className="header-row mt-4 mb-4">
          <div className="setting-title">Gas Type Commision</div>
          <button
            className="gas-invoice-add-button"
            onClick={handleShowCommission}
          >
            + Add Gas Commissions
          </button>
        </div>
        <ReusableModal
          show={showCommissionModal}
          handleClose={handleCloseCommission}
          title={
            selectedCommission ? "Edit Gas Commission" : "Add Gas Commission"
          }
          width="1054px"
          height="auto"
        >
          <form onSubmit={handleCommissionSubmit}>
            <div className="form-group d-flex align-items-center mt-0">
              <select
                className="input-field"
                name="gas_type"
                value={gasName}
                onChange={handleCommissionChange}
                required
                style={{
                  width: "400px",
                  marginRight: "16px",
                  height: "44px",
                }}
              >
                <option value="">Select Gas Type</option>
                {gasTypeData.map((gas) => (
                  <option key={gas.id} value={gas.id}>
                    {gas.gas_type_title}
                  </option>
                ))}
              </select>
              <input
                className="input-field"
                type="number"
                name="commission_percentage"
                placeholder="Commission Percentage"
                value={gasCommission}
                onChange={handleCommissionChange}
                required
                style={{
                  width: "400px",
                  marginRight: "16px",
                  height: "44px",
                }}
              />
              <input
                className="input-field"
                type="date"
                name="date_from"
                placeholder="Date From"
                value={commissionDate}
                onChange={handleCommissionChange}
                required
              />

              <button
                type="submit"
                style={{
                  padding: "6px 8px",
                  backgroundColor: "#4545db",
                  color: "white",
                  border: "none",
                  borderRadius: "40px",
                  cursor: "pointer",
                  width: "124px",
                  height: "42px",
                  fontSize: "18px",
                  marginLeft: "16px",
                }}
              >
                {selectedCommission ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </ReusableModal>
        <SettingTable
          data={commissionData}
          columns={commissionColumns}
          showFooter={true}
          showAction={true}
          onEdit={handleEditCommission}
          onDelete={handleDeleteGasCommision}
        />
      </div>
    </>
  );
}

export default GasSettings;
