import React from 'react';

const StoreCashTable = ({ showTable, otherStoreCash }) => {
  if (!showTable) return null; 

  return (
    <div style={{
      position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', 
      backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '999'
    }}>
      <div style={{
        backgroundColor: '#fff', padding: '20px', borderRadius: '8px', maxWidth: '800px', width: '90%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
      }}>
        <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
          <thead>
          
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Store Name</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Cash Balance</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Date</th>
            </tr>
          </thead>
          <tbody>
         {
          otherStoreCash.map((item)=>{
           return ( <tr>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.store_name}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>${item.total_current_cash}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.address}</td>
            </tr>)
          })
         }
           
          
          </tbody>
        </table>
        <button onClick={() => { window.location.reload(); }} style={{
          backgroundColor: '#1976d2', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', marginTop: '20px', cursor: 'pointer'
        }}>
          Close
        </button>
      </div>
    </div>
  );
};

export default StoreCashTable;
