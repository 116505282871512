/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import ReusableModal from "../ManageSettings/ReusableModal"; // Adjust the path as needed
import { toast } from "react-toastify";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../utils/secure-route/AuthContext";

function ManageGameModal({ show, handleClose, gameNo }) {
  const [gameName, setGameName] = useState("");
  const [gameNumber, setGameNumber] = useState(gameNo);
  const [totalTickets, setTotalTickets] = useState("");
  const [gamePrice, setGamePrice] = useState("");
  const { user } = useContext(AuthContext);
  const [games, setGames] = useState([]);
  const storeId = user.store;
  const { Post } = useApi();

  // Handle form submission for adding a new game
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const today = new Date().toISOString().split("T")[0];
    const gameData = {
      game_name: gameName,
      game_no: gameNo || gameNumber,
      total_no_of_tickets: totalTickets,
      value: gamePrice,
      store: storeId,
      receive_date: today,
    };

    try {
      const response = await Post("lotteryGameData", gameData);
      setGames((prevGames) => [...prevGames, response.data]);
      handleClose()
      setGameNumber('')
      toast.success("Game added successfully!");
    } catch (error) {
      handleClose()
      console.error("Error adding game:", error);
      toast.error("Error adding game.");
    }
  };
  return (
    <ReusableModal
      show={show}
      handleClose={handleClose}
      title="Add New Game"
      width="620px"
    >
      <form onSubmit={handleSubmit}>
        <div className="form-group d-flex flex-column" >
          <div className="d-flex align-items-center mb-3">
            <input
              type="text"
              className="input-field"
              placeholder="Game Name"
              value={gameName}
              onChange={(e) => setGameName(e.target.value)}
              style={{ width: "270px", marginRight: "10px" }}
              required
            />
            <input
              type="text"
              className="input-field"
              placeholder="Game Number"
              name="game_no"
              value={gameNo}
              onChange={(e) =>(e.target.value)}
              style={{ width: "270px", marginRight: "10px" }}
              required
            />
          </div>
          <div className="d-flex align-items-center mb-3">
            <input
              type="number"
              className="input-field"
              placeholder="Total Tickets"
              value={totalTickets}
              onChange={(e) => setTotalTickets(e.target.value)}
              style={{ width: "270px", marginRight: "10px" }}
              required
            />
            <input
              type="number"
              className="input-field"
              placeholder="Game Price"
              value={gamePrice}
              onChange={(e) => setGamePrice(e.target.value)}
              style={{ width: "270px", marginRight: "10px" }}
              required
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              style={{
                marginRight: "40px",
                padding: "4px 8px",
                backgroundColor: "#4545db",
                color: "white",
                border: "none",
                borderRadius: "40px",
                cursor: "pointer",
                width: "124px",
                height: "42px",
                fontSize: "18px",
              }}
              type="submit"
            >
              Add Game
            </button>
          </div>
        </div>
      </form>
    </ReusableModal>
  );
}

export default ManageGameModal;
