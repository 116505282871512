/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */


import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import config from "../../../config/Global.json";
import { toast , ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./AccountCreate.css"; // Import the CSS file
import CustomInput from "../../custominput/CustomInput";
import CustomPhoneInput from "../../customphoninput/CustomPhoneInput";
function AccountCreate() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_no: "",
    password: "",
    confirmPassword: "",
    store_name: "",
    address_line1: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
  });
  const [showExist, setShowExist] = useState(false);
  const [customErr, setCustomErr] = useState(false);
  const [customErrMsg, setCustomErrMsg] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [passWordError, setPassWordError] = useState("");
  const [passError, setPassError] = useState(false);
  const [passErrMsg, setPassErrMsg] = useState("");
  const [confpassError, setConfpassError] = useState(false);
  const [confpassErrorMsg, setConfpassErrorMsg] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [pincodeErrorFlag, setPincodeErrorFlag] = useState(false);
  const [pincodeErrorMsg, setPincodeErrorMsg] = useState("");
  const [firsterror, setFirsterror] = useState(false);
  const [firsterrormsg, setFirsterrormsg] = useState("");
  const [lasterror, setLasterror] = useState(false);
  const [lasterrormsg, setLasterrormsg] = useState("");
  const [contactNoError, setContactNoError] = useState(false);
  const [contactNoErrorMsg, setcontactNoErrorMsg] = useState("");
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePhoneChange = (phone) => {
    setFormData((prevData) => ({
      ...prevData,
      contact_no: phone,
    }));
  };
  const validateTextInput = (value) => {
    const textRegex = /^[A-Za-z,.\-_]{0,255}$/;
    return textRegex.test(value);
  };
  const fetchLocationDetails = async (pincode) => {
    const apiUrl = `${config.api.host}${config.api.getPincodeData}`;
    try {
      const response = await axios.post(apiUrl, { pincode });
      const { city, state, country } = response.data;
      setFormData((prevData) => ({
        ...prevData,
        city: city || "",
        state: state || "",
        country: country || "",
      }));
      // Reset the error if fetch is successful
      setPincodeErrorMsg(""); // Clear any previous error message
      setPincodeErrorFlag(false); // Set error flag to false
    } catch (error) {
      setPincodeErrorMsg("Failed to fetch location details");
      setPincodeErrorFlag(true); // Set error flag to true
    }
  };
  const handlePincodeChange = (e) => {
    const { value } = e.target;

    // Allow only numeric input
    const numericValue = value.replace(/[^0-9]/g, '');

    setFormData((prevData) => ({
        ...prevData,
        pincode: numericValue,
        ...(numericValue.trim() === "" && { city: "", state: "", country: "" }),
    }));

    // Trigger fetching location details if the length is valid
    if (numericValue.length === 5 || numericValue.length === 6) {
        fetchLocationDetails(numericValue);
    }
};
  const handlePincodeBlur = () => {
    const { pincode } = formData;
    if (pincode.length === 5) {
      fetchLocationDetails(pincode);
      setPincodeErrorMsg(""); // Clear any previous error message
      setPincodeErrorFlag(false); // Set error flag to false
    } else {
      setPincodeErrorMsg("Pincode must be 5 digits");
      setPincodeErrorFlag(true); // Set error flag to true
    }
  };

  const [formErrors, setFormErrors] = useState(false);
  const validatePasswords = () => {
    return !formErrors;;
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === "confirmPassword") {
      const { password, confirmPassword } = formData;

      if (password !== confirmPassword) {
        setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: true }));
        setConfpassError(true);
        setConfpassErrorMsg("Password does not match.");
        setFormErrors(true);
      } else {
        setFormErrors(false);
        setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: false }));
        setConfpassError(false);
        setConfpassErrorMsg("");
      }
    } else if (name === "password") {
      const passwordRegex =
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>_\-])[A-Za-z\d!@#$%^&*(),.?":{}|<>_\-]{8,}$/;
      if (!passwordRegex.test(value)) {
        setErrors((prevErrors) => ({ ...prevErrors, password: true }));
        setPassError(true);
        setPassErrMsg(
          "Password must be at least 8 characters, with one uppercase letter, one digit, and one special character."
        );
        setFormErrors(true);
      } else {
        setFormErrors(false);
        setPassError(false);
        setPassErrMsg("");
        setErrors((prevErrors) => ({ ...prevErrors, password: false }));
      }
    } else if (name === "first_name") {
      if (!validateTextInput(value)) {
        setFirsterror(true);
        setFirsterrormsg("Special characters and numbers are not allowed!");
        setErrors((prevErrors) => ({
          ...prevErrors,
          first_name: true, // Update errors state with specific message
        }));
        setFormErrors(true);
      } else {
        setFormErrors(false);
        setFirsterror(false);
        setFirsterrormsg("");
        setLasterror(false);
        setLasterrormsg("");
        setErrors((prevErrors) => ({
          ...prevErrors,
          first_name: false,
        }));
      }
    } else if (name === "last_name") {
      if (!validateTextInput(value)) {
        setFormErrors(true);
        setLasterror(true);
        setLasterrormsg("Special characters and numbers are not allowed!");
        setErrors((prevErrors) => ({
          ...prevErrors,
          last_name: true, // Update errors state with specific message
        }));
      } else {
        setLasterror(false);
        setLasterrormsg("");
        setErrors((prevErrors) => ({
          ...prevErrors,
          last_name: false,
        }));
        setFormErrors(false);
      }
    }
  };
  const validatePhoneNo = (value) => {
    let errorMessage = "";

    if (!value) {
      errorMessage = "Phone number is required.";
    } else if (!/^\+1\d{10}$/.test(value)) {
      errorMessage = "Please enter a valid phone number (e.g., +1234567890).";
    }

    setErrors({
      ...errors,
      contact_no: errorMessage,
    });

    setContactNoError(!!errorMessage);
    setcontactNoErrorMsg(errorMessage);
  };
  const checkUserExist = async (email) => {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    let err = false;
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      setCustomErr(true);
      setCustomErrMsg("Please enter a valid email");
      err = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: false }));
      setCustomErr(false);
      setCustomErrMsg("");
      err = false;
    }
    if (!err) {
      await axios
        .post(`${config.api.host}${config.api.userExist}`, {
          email: email,
        })
        .then((response) => {
          setShowExist(response.data.exists);
          if (response.data.exists) {
            setErrors((prevErrors) => ({ ...prevErrors, email: true }));
            setCustomErr(true);
            setCustomErrMsg("This user account already exists.");
          } else {
            setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
            setCustomErrMsg("This user account already exists.");
          }
        });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentStep === 2) {
      if (!validatePasswords()) {
        return;
      }
      const apiUrl = `${config.api.host}${config.api.signUpUser}`;
      try {
        const response = await axios.post(apiUrl, formData);
        toast.success("User Created successfully!"); 
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          contact_no: "",
          password: "",
          confirmPassword: "",
          store_name: "",
          address_line1: "",
          pincode: "",
          city: "",
          state: "",
          country: "",
        });
        setCurrentStep(1);
        navigate("/login");
  
      } catch (error) {
        // Check for duplicate contact number error
        if (error.response && error.response.data.error) {
          const errorMessage = error.response.data.error;
          
          if (errorMessage.includes('A user with this contact number already exists')) {
            toast.error("This mobile number is already registered. Please try with a different one.");
          } else if (errorMessage.includes('duplicate key value violates unique constraint')) {
            // Check for duplicate email (username)
            toast.error("This email is already registered. Please try with a different one.");
          } else {
            toast.error("Error creating user");
          }
        } else {
          toast.error("Error creating user");
        }
      }
    } else {
      if (validatePasswords()) {
        setCurrentStep(currentStep + 1);
      }
    }
  };
  
  
  const goBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  const renderStep1 = () => (
    <>
      <p
        className="card-title mb-3"
        style={{

          fontSize: "30px",
          fontWeight: "700",
          lineHeight: "40px",
          textAlign: "left",
          marginTop: "100px",
        }}
      >
        Create an Account
      </p>
      <p
        className="mb-3"
        style={{  fontSize: "14px", color: "#002300" }}
      >
        Start managing your finances effortlessly. Sign up to access your
        personal ledger and gain control of your transactions with ease!
      </p>
      <div className="mb-3">
        <CustomInput
          type="text"
          id="firstName"
          name="first_name"
          placeholder="First Name"
          value={formData.first_name}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={Boolean(errors.first_name)}
          helpertext={errors.first_name || ""}
          custom={firsterror}
          msg={firsterrormsg}
        />
      </div>
      <div className="mb-3">
        <CustomInput
          type="text"
          id="lastName"
          name="last_name"
          placeholder="Last Name"
          value={formData.last_name}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={Boolean(errors.last_name)}
          helpertext={errors.last_name || ""}
          custom={lasterror}
          msg={lasterrormsg}
        />
      </div>
      <div className="mb-3">
        <CustomPhoneInput
        required
          type="number"
          id="phonenumber"
          name="contact_no"
          placeholder="Phone Number"
          value={formData.contact_no}
          onChange={handlePhoneChange}
          onBlur={() => validatePhoneNo(formData.contact_no)}
          error={Boolean(errors.contact_no)} // Check if there's an error
          helpertext={errors.contact_no} // Pass error message to helpertext
          custom={contactNoError} // Optionally pass additional custom error logic
          msg={contactNoErrorMsg} // Custom error message (if any)
        />
      </div>
      <div className="mb-3">
        <CustomInput
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          onBlur={(e) => checkUserExist(e.target.value)}
          custom={customErr}
          msg={customErrMsg}
          required
          error={Boolean(errors.email)}
          helpertext={errors.email || emailError}
        />
      </div>
      <div className="mb-3">
        <CustomInput
          type="password"
          id="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={Boolean(errors.password)}
          helpertext={errors.password || passWordError}
          custom={passError}
          msg={passErrMsg}
        />
      </div>
      <div className="mb-3">
        <CustomInput
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Confirm Password"
          value={formData.confirmPassword}
          onChange={handleChange}
          onBlur={(e) => handleBlur(e)}
          required
          error={Boolean(errors.confirmPassword)}
          helpertext={errors.confirmPassword || ""}
          custom={confpassError}
          msg={confpassErrorMsg}
        />
      </div>
    </>
  );
  const renderStep2 = () => (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <i
          className="fa-solid fa-arrow-left"
          onClick={goBack}
          style={{ marginTop: "70px", marginRight: "10px", fontSize: "20px" }}
        ></i>
        <p
          className="card-title mb-3"
          style={{

            fontSize: "40px",
            fontWeight: "700",
            textAlign: "left",
            marginTop: "80px",
          }}
        >
          Enter the Details
        </p>
      </div>
      <p
        className="mb-3"
        style={{

          fontSize: "18px",
          color: "#002300",
        }}
      >
        Get started with your personal ledger. Fill in your information to track
        transactions and manage your finances seamlessly!
      </p>
      <div className="mb-3">
        <CustomInput
          type="text"
          id="storeName"
          name="store_name"
          placeholder="Store Name"
          value={formData.store_name}
          onChange={handleChange}
          required
          error={Boolean(errors.store_name)}
          helpertext={errors.store_name || ""}
        />
      </div>
      <div className="mb-3">
        <CustomInput
          type="text"
          id="storeAddress"
          name="address_line1"
          placeholder="Store Address"
          value={formData.address_line1}
          onChange={handleChange}
          required
          error={Boolean(errors.address_line1)}
          helpertext={errors.address_line1 || ""}
        />
      </div>
      <div className="mb-3">
        <CustomInput
          type="text"
          id="pincode"
          name="pincode"
          maxLength={5}
          placeholder="Pin code"
          value={formData.pincode}
          onChange={handlePincodeChange}
          onBlur={handlePincodeBlur} // Call onBlur to trigger fetching
          required
          error={pincodeErrorFlag} // Use error flag for the input
          custom={pincodeErrorFlag} // Custom error flag for message display
          msg={pincodeErrorMsg} // Custom error message
        />
      </div>
      <div className="mb-3">
        <CustomInput
          type="text"
          id="city"
          name="city"
          placeholder="City"
          value={formData.city}
          onChange={handleChange}
          required
          error={Boolean(errors.city)}
          helpertext={errors.city || ""}
        />
      </div>
      <div className="mb-3">
        <CustomInput
          type="text"
          id="state"
          name="state"
          placeholder="State"
          value={formData.state}
          onChange={handleChange}
          required
          error={Boolean(errors.state)}
          helpertext={errors.state || ""}
        />
      </div>
      <div className="mb-3">
        <CustomInput
          type="text"
          id="country"
          name="country"
          className=""
          placeholder="Country"
          value={formData.country}
          onChange={handleChange}
          required
          error={Boolean(errors.country)}
          helpertext={errors.country || ""}
        />
      </div>

    </>
  );
  return (
    
    <div className="container" style={{ maxWidth: "500px" }}>
     
      <ToastContainer/>
      
      <div className="row justify-content-center align-items-center">
        <form onSubmit={handleSubmit}>
          {currentStep === 1 ? renderStep1() : renderStep2()}
          <div className="mb-3 mt-4">
            <button
              type="submit"
              className="btn btn-primary rounded-pill"
              style={{
                width: "100%",
                height: "60px",
                backgroundColor: "#4545DB",
                color: "#ffffff",
                padding: "10px 0px",
                fontSize: "20px",
                fontWeight: "700",
                border: "none",
                cursor: "pointer",
                outline: "none",
                boxShadow: "none",
              }}
              onFocus={(e) => (e.target.style.boxShadow = "none")}
            >
              {currentStep === 2 ? "Submit" : "Next"}
            </button>
            <div className="stepper-container">
              <div
                className={`step-line ${currentStep === 1 ? "active" : ""}`}
              ></div>
              <div
                className={`step-line ${currentStep === 2 ? "active" : ""}`}
              ></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default AccountCreate;
