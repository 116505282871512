import React from 'react';

const CustomSwitch = ({ setting, handleSwitchChange, isActive }) => {
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id={`switch-${setting.id}`}
        role="switch"
        onChange={() => handleSwitchChange(setting.id)} // Handle change function
        checked={isActive} // Add checked prop to control switch state
      />
      <label className="custom-switch-label" htmlFor={`switch-${setting.id}`}>
        {setting.name} {/* Display setting name */}
      </label>

      <style jsx>{`
        .form-check {
          position: relative;
        }

        .custom-switch-label {
          display: inline-block;
          width: 50px; /* Set width to 50px */
          height: 26px; /* Set height to 26px */
          background-color: #D9D9D9;
          border-radius: 13px; /* Half of the height for rounded corners */
          position: relative;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .form-check-input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .form-check-input:checked + .custom-switch-label {
          background-color: #3C3C3C; /* Color when checked */
        }

        .custom-switch-label::after {
          content: '';
          position: absolute;
          top: 1px; /* Center the circle vertically */
          left: 1px; /* Center the circle horizontally */
          width: 24px; /* Circle width */
          height: 24px; /* Circle height */
          background-color: white;
          border-radius: 50%;
          transition: transform 0.3s;
        }

        .form-check-input:checked + .custom-switch-label::after {
          transform: translateX(24px); /* Move the toggle when checked (50px - 26px) */
        }
      `}</style>
    </div>
  );
};

export default CustomSwitch;
