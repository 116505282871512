import React from 'react';
import { BrowserRouter} from 'react-router-dom';
import { AuthProvider } from './utils/secure-route/AuthContext';
import RouteManager from './utils/secure-route/routeManager/RouteManager';
import './assets/css/App.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const App = () => {
  return (
    <>
    <ToastContainer autoClose={2000} />
    <BrowserRouter>
      <AuthProvider>
        <RouteManager />
      </AuthProvider>
    </BrowserRouter>
    </>
  );
};

export default App;
