/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../../../../utils/api-manager/Helper/useApi';
import loaderGif from '../../../../assets/img/loader.gif';
import { toast } from 'react-toastify';
import LotteryHistoryModal from './LotteryHistoryModal';
import ConfirmationModal from './ConfirmationModal';


export default function ReportDateSelection() {
  const [selectedDate, setSelectedDate] = useState(); // Today's date in YYYY-MM-DD format
  const [selectedTime, setSelectedTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })); // Current time
  const [showModal, setShowModal] = useState(false);
  const [shift, setShift] = useState(''); // Will set to the most recent shift
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [historyReport, setHistoryReport] = useState(false);
  const { Post, Get } = useApi();
  const dateInputRef = useRef(null);
  const [activeShift, setActiveShift] = useState(false);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [reportHistory, setReportHistory] = useState(false); // Track report_history status

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);


  useEffect(() => {
    checkActiveShift();

    if (dateInputRef.current) {
      dateInputRef.current.focus()
    }
  }, [navigate]);


  const checkActiveShift = async () => {
    try {
      const response = await Get('isShiftActive');
      if (response.message === "You already have an active shift") {
        setActiveShift(response.data);
        setShift(response.new_shift_title);
        navigate('/reports');
      } else {
        setActiveShift(response.data);
        setShift(response.new_shift_title);
        setLoading(false);
      }


      const historyReport = response.history_report;
      if (historyReport === "true") {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false); // Enable button if no history report exists
      }

    } catch (error) {
      console.error('Error checking active shift:', error);
      setLoading(false);
    }
  };


  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedTime(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    setShowModal(true);
    setErrorMessage('');
  };

  const handleShiftChange = (event) => {
    const selectedShift = event.target.value;
    setShift(selectedShift);
    // Set current date and time when a shift is selected
    setSelectedDate(new Date().toISOString().split('T')[0]); // Today's date
    setShowModal(true); // Show modal
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage('');
  };

  const handleSubmit = async () => {
    try {
      const response = await Post('createShiftBusinessReportAndRegisters');
      if (response.status === 200) {
        toast.success('Shift report created successfully!');
      } else {
        setErrorMessage('Failed to create shift report. Please try again.');
      }
    } catch (error) {
      console.error('Error creating shift report:', error);
      setErrorMessage('Error occurred while creating the report. Please try again.');
    }
  };

  const handleConfirm = async () => {
    if (!activeShift) {
      await handleSubmit();
    }
    navigate('/reports');
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "600px" }}>
        <img src={loaderGif} alt="Loading..." style={{ width: '100px', height: '100px' }} />
      </div>
    );
  }

  ///  lottery report //

  const createHistoricalReport = async () => {
    try {
      const response = await Post('createHistoricalReport');
      setData(response);
      setOpen(true);
      resetData();
      setButtonDisabled(true);
      setReportHistory(true);

      toast.success('Historical report created successfully!');
    } catch (error) {
      console.error('Error calling API:', error);
      toast.error('Error occurred while creating the report. Please try again.');
    }
  };

  const handleButtonClick = () => {
    if (reportHistory) {
      toast.error('A shift for history lottery report already exists');
    } else {
      createHistoricalReport();
    }
  };

  const handleCloseModals = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmClose = async () => {
    setConfirmDialogOpen(false);
    setOpen(false);
    resetData();
 
  };

  const handleCancelClose = () => {
    setConfirmDialogOpen(false);
  };

  const resetData = () => {
    setData({});
  };

  return (
    <div className="d-flex p-2">
      <div className="expensecontainer d-flex flex-column pd-2">

        {!(buttonDisabled || historyReport === "true") && (
          <button
            className="add-button"
            style={{
              backgroundColor: '#4545db',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 10px',
              width: '190px',
              height: '40px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}

            onClick={handleButtonClick}
          >
            +Add Lottery History
          </button>
        )}

        <LotteryHistoryModal

          open={open}
          onClose={handleCloseModals}
          data={data} // Pass the fetched data to the modal
        />

        <ConfirmationModal
          open={confirmDialogOpen}
          onConfirm={handleConfirmClose}
          onCancel={handleCancelClose}
          title="Are you sure you want to close the lottery report?"
          description="If you close, you won't be able to create the lottery history report a second time."
        />

        <div className="d-flex justify-content-center align-items-center" style={{ height: "700px" }}>
          <div className="d-flex flex-column p-2 col-md-5">
            <div className="col-md-12 d-flex align-items-center justify-content-center">
              <label htmlFor="dateInput" className="form-label mb-0 me-2">Select Shift Date:</label>
              <input
                id="dateInput"
                type="date"
                className="form-control-borderless"
                value={selectedDate}
                onChange={handleDateChange}
                style={{ width: 'auto', flex: '1', marginBottom: "10px" }}
              />
            </div>

            <div className="row mb-3">
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <label htmlFor="timeInput" className="form-label mb-0 me-2">Select Time:</label>
                <input
                  id="timeInput"
                  type="time"
                  className="form-control-borderless"
                  value={selectedTime.slice(0, 5)} // Format to HH:mm for input[type=time]
                  onChange={(e) => setSelectedTime(e.target.value)}
                  style={{ width: 'auto', flex: '1' }}
                />
              </div>

              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <label htmlFor="shiftSelect" className="form-label mb-0 me-2">Select Shift:</label>
                <select
                  id="shiftSelect"
                  className="form-control-borderless"
                  value={shift}
                  onChange={handleShiftChange}
                  style={{ width: 'auto', flex: '1' }}
                >
                  <option value={shift}>
                    {shift}
                  </option>
                </select>
              </div>
            </div>

            {showModal && <div className="modal-backdrop fade show"></div>}
            <div className={`modal ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-hidden={!showModal}>
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Confirm Shift</h5>
                  </div>
                  <div className="modal-body">
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    Do you want to start{' '}
                    <span style={{ border: ".5px solid #000", padding: "3px 5px", borderRadius: "3px", backgroundColor: "#4545DB", color: "#f4f4f4" }}>
                      &nbsp;{shift}&nbsp;
                    </span>
                    &nbsp; on {selectedDate ? new Date(selectedDate).toLocaleDateString() : ''} at {selectedTime}?
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-contained" onClick={handleCloseModal}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={handleConfirm}>Confirm</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
