/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import SettingTable from "../ManageSettings/SettingTable";
import { toast, ToastContainer } from "react-toastify";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import ReusableModal from "../ManageSettings/ReusableModal";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import WeeklyInvoiceTable from "./WeeklyInvoiceTable";
import Swal from "sweetalert2";


function WeeklyInvoiceSettlement() {
  const [games, setGames] = useState([]);
  const { Get, Post, Put, Delete } = useApi();
  const { user } = useContext(AuthContext);
  const storeId = user.store;
  const [showModal, setShowModal] = useState(false);
  const [weeklyInvoice, setWeeklyInvoice] = useState([]);
  const [editingInvoice, setEditingInvoice] = useState(null); // Store the invoice being edited
  const [editMode, setEditMode] = useState(false);

  const handleShow = (invoice = null) => {
    setShowModal(true);
    if (invoice) {
      setEditingInvoice(invoice);
      setEditMode(true);
      // Set form fields from existing invoice data
      setWeekStarting(invoice.week_starting);
      setNetSales(invoice.net_sales);
      setCommissionSR61(invoice.commission_sr_61);
      setCashes(invoice.cashes);
      setCashBonus(invoice.cash_bonus);
      setClaimBonus(invoice.claim_bonus);
      setAdjustment61(invoice.adjustment61);
      setServiceFee(invoice.service_fee);
      setBondingFee(invoice.bonding_fee);
      setOnlineDue(invoice.online_due);
      setInstantDue(invoice.instant_due);
      setNetDue(invoice.net_due);
      setTotalSales(invoice.total_sales);
      setTotalReturn(invoice.total_return);
      setCommissionSR38(invoice.commission_sr_38);
      setSr31Total(invoice.sr_31_total);
      setAdjustment38(invoice.adjustment38);
      setAmountDue(invoice.amount_due);
    } else {
      setEditMode(false);
      // Reset form fields for a new invoice
      setWeekStarting("");
      setNetSales(0);
      setCommissionSR61(0);
      setCashes(0);
      setCashBonus(0);
      setClaimBonus(0);
      setAdjustment61(0);
      setServiceFee(0);
      setBondingFee(0);
      setOnlineDue(0);
      setInstantDue(0);
      setNetDue(0);
      setTotalSales(0);
      setTotalReturn(0);
      setCommissionSR38(0);
      setSr31Total(0);
      setAdjustment38(0);
      setAmountDue(0);
    }
  };
  const handleClose = () => {
    setShowModal(false);
    setEditMode(false); // Reset edit mode
    setEditingInvoice(null); // Clear the current editing invoice
  };
  const [showBookModal, setShowBookModal] = useState(false);
  const handleShowBook = () => setShowBookModal(true);
  const handleCloseBook = () => setShowBookModal(false);

  const [weekStarting, setWeekStarting] = useState("");
  const [weekEnding, setWeekEnding] = useState("");
  const [netSales, setNetSales] = useState(0);
  const [commissionSR61, setCommissionSR61] = useState(0);
  const [cashes, setCashes] = useState(0);
  const [cashBonus, setCashBonus] = useState(0);
  const [claimBonus, setClaimBonus] = useState(0);
  const [adjustment61, setAdjustment61] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [bondingFee, setBondingFee] = useState(0);
  const [onlineDue, setOnlineDue] = useState();
  const [instantDue, setInstantDue] = useState(0);
  const [netDue, setNetDue] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [commissionSR38, setCommissionSR38] = useState(0);
  const [sr31Total, setSr31Total] = useState(0);
  const [adjustment38, setAdjustment38] = useState(0);
  const [cashingCommission, setCashingCommission] = useState(0);
  const [totalInstantCashing, setTotalInstantCashing] = useState(0);

  const [amountDue, setAmountDue] = useState(0);

  const [totalNetSales, setTotalNetSales] = useState(0);
  const [totalCashes, setTotalCashes] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [totalDue, setTotalDue] = useState(0);

  const handleWeeklySubmit = async () => {
    const calculatedTotalNetSales =
      parseFloat(netSales || 0) + parseFloat(totalSales || 0);
    const calculatedTotalCashes =
      parseFloat(cashes || 0) + parseFloat(totalInstantCashing || 0);
    const calculatedTotalCommission =
      parseFloat(commissionSR61 || 0) +
      parseFloat(cashBonus || 0) +
      parseFloat(claimBonus || 0) +
      parseFloat(commissionSR38 || 0) +
      parseFloat(cashingCommission || 0);
    const calculatedTotalDue = parseFloat(netDue || 0);

    // Prepare data to submit
    const WeeklyInvoice = {
      store: storeId,
      week_starting: weekStarting,
      net_sales: parseFloat(netSales).toFixed(2),
      commission_sr_61: parseFloat(commissionSR61).toFixed(2),
      cashes: parseFloat(cashes).toFixed(2),
      cash_bonus: parseFloat(cashBonus).toFixed(2),
      claim_bonus: parseFloat(claimBonus).toFixed(2),

      adjustment61: parseFloat(adjustment61).toFixed(2),

      service_fee: parseFloat(serviceFee).toFixed(2),
      bonding_fee: parseFloat(bondingFee).toFixed(2),
      online_due: parseFloat(onlineDue).toFixed(2),
      instant_due: parseFloat(instantDue).toFixed(2),
      net_due: parseFloat(netDue).toFixed(2),

      total_sales: parseFloat(totalSales).toFixed(2),
      total_return: parseFloat(totalReturn).toFixed(2),
      commission_sr_38: parseFloat(commissionSR38).toFixed(2),
      sr_31_total: parseFloat(sr31Total).toFixed(2),
      cashing_commission: parseFloat(cashingCommission).toFixed(2),
      total_instant_cashing: parseFloat(totalInstantCashing).toFixed(2),
      amount_due: parseFloat(amountDue).toFixed(2),

      total_net_sales: calculatedTotalNetSales.toFixed(2),
      total_cashes: calculatedTotalCashes.toFixed(2),
      total_commission: calculatedTotalCommission.toFixed(2),
      adjustment38: parseFloat(adjustment38).toFixed(2),
      total_due: calculatedTotalDue.toFixed(2),
    };

    try {
      if (editMode) {
        // Update existing invoice
        await Put("weeklyInvoiceSettlementData", editingInvoice.id, WeeklyInvoice);
        toast.success("Invoice updated successfully!");
      } else {
        // Create new invoice
        await Post("weeklyInvoiceSettlementData", WeeklyInvoice);
        toast.success("Invoice created successfully!");
      }
      handleClose(); // Close modal after submission
      fetchWeeklyInvoiceData(); // Refresh the data after submission
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while submitting the invoice.");
    }
  };

  useEffect(() => {
    const parsedNetSales = parseFloat(netSales) || 0;
    const parsedCommissionSR61 = parseFloat(commissionSR61) || 0;
    const parsedCashes = parseFloat(cashes) || 0;
    const parsedCashBonus = parseFloat(cashBonus) || 0;
    const parsedClaimBonus = parseFloat(claimBonus) || 0;
    const parsedAdjustment = parseFloat(adjustment61) || 0;
    const parsedServiceFee = parseFloat(serviceFee) || 0;
    const parsedBondingFee = parseFloat(bondingFee) || 0;
    const parsedInstantDue = parseFloat(instantDue) || 0;

    // SR 61 calculations
    const calculatedCommissionSR61 = netSales / 20;
    const calculatedCashBonus = cashes / 100;

    // First part of the calculation (before adjustments)
    const firstPart =
      parsedNetSales -
      parsedCommissionSR61 -
      parsedCashes -
      parsedCashBonus -
      parsedClaimBonus;

    // Second part of the calculation (adjustments, service fee, bonding fee)
    const secondPart = parsedAdjustment + parsedServiceFee + parsedBondingFee;
    // Final onlineDue calculation
    const calculatedOnlineDue = firstPart + secondPart;

    const calculatedNetDue = calculatedOnlineDue + parsedInstantDue;

    if (!isNaN(calculatedOnlineDue)) {
      // Ensure it's a number and round it to 2 decimal places
      setOnlineDue(parseFloat(calculatedOnlineDue).toFixed(2));
    } else {
      setOnlineDue("0.00"); // Fallback if calculation is invalid
    }

    if (!isNaN(calculatedNetDue)) {
      setNetDue(parseFloat(calculatedNetDue).toFixed(2)); // Store rounded value for display
    } else {
      setNetDue("0.00"); // Fallback if invalid
    }
    setCommissionSR61(calculatedCommissionSR61);
    setCashBonus(calculatedCashBonus);

    // SR 38 calculations
    const parsedTotalSales = parseFloat(totalSales) || 0;
    const parsedTotalReturn = parseFloat(totalReturn) || 0;
    const parsedSr31Total = parseFloat(sr31Total) || 0;
    const parsedAdjustment38 = parseFloat(adjustment38) || 0;

    // SR 38 and Instant Cashing calculations

    // Commission SR 38 calculation (based on total sales and total returns)
    const calculatedCommissionSR38 =
      (parsedTotalSales - parsedTotalReturn) / 20;

    // Cashing commission calculation (based on sr31Total)
    const calculatedCashingCommission = parsedSr31Total * 0.01;

    // Total instant cashing calculation (based on sr31Total and commissionSR38)
    let calculatedTotalInstantCashing = 0;
    if (parsedSr31Total > 0) {
      calculatedTotalInstantCashing =
        parsedSr31Total - calculatedCashingCommission;
    }

    const amountDue =
      parsedTotalSales -
      parsedTotalReturn -
      calculatedCommissionSR38 -
      parsedSr31Total +
      parsedAdjustment38;

    // Update the state for amount due
    setAmountDue(amountDue.toFixed(2));
    // Update the state for SR 38 and cashing commissions
    setCommissionSR38(calculatedCommissionSR38.toFixed(2)); // Round to 2 decimal places
    setCashingCommission(calculatedCashingCommission.toFixed(2)); // Round to 2 decimal places
    setTotalInstantCashing(calculatedTotalInstantCashing.toFixed(2)); // Round to 2 decimal places

    if (weekStarting) {
      const startDate = new Date(weekStarting);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6); // Adds 7 days
      setWeekEnding(endDate.toISOString().split("T")[0]); // Format to YYYY-MM-DD
    }
  }, [
    netSales,
    cashes,
    claimBonus,
    adjustment61,
    serviceFee,
    bondingFee,
    totalSales,
    instantDue,
    totalReturn,
    sr31Total,
    adjustment38,
    weekStarting,
  ]);

  const fetchGameData = async () => {
    try {
      const response = await Get("lotteryInventoryData");

      // Filter to only include games with status "settled"
      const settledGames = Array.isArray(response)
        ? response.filter((game) => game.status === "settled")
        : [];

      setGames(settledGames);
    } catch (error) {
      console.error("Error fetching game data:", error);
      toast.error("Error fetching game data.");
    }
  };

  const fetchWeeklyInvoiceData = async () => {
    try {
      const response = await Get("weeklyInvoiceSettlementData");
      if (response && Array.isArray(response)) {
        setWeeklyInvoice(response);
      } else {
        toast.error("Failed to fetch settlement data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while fetching data.");
    }
  };

  const handleEdit = (invoice) => {
    setEditingInvoice(invoice); // Set the invoice being edited
    setEditMode(true); // Enable edit mode
    // Optionally, you can set the state to the values of the invoice for the form
    setWeekStarting(invoice.week_starting);
    setNetSales(invoice.net_sales);
    setCommissionSR61(invoice.commission_sr_61);
    setCashes(invoice.cashes);
    setCashBonus(invoice.cash_bonus);
    setClaimBonus(invoice.claim_bonus);
    setAdjustment61(invoice.adjustment61);
    setServiceFee(invoice.service_fee);
    setBondingFee(invoice.bonding_fee);
    setOnlineDue(invoice.online_due);
    setInstantDue(invoice.instant_due);
    setNetDue(invoice.net_due);
    setTotalSales(invoice.total_sales);
    setTotalReturn(invoice.total_return);
    setCommissionSR38(invoice.commission_sr_38);
    setSr31Total(invoice.sr_31_total);
    setAdjustment38(invoice.adjustment38);
    setAmountDue(invoice.amount_due);
    setTotalNetSales(invoice.total_net_sales);
    setTotalCashes(invoice.total_cashes);
    setTotalCommission(invoice.total_commission);
    setTotalDue(invoice.total_due);
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user clicked the confirm button
    if (!result.isConfirmed) return; // Exit if the user cancels
    try {
      // Call the delete API with the invoice ID
      await Delete("weeklyInvoiceSettlementData", id);

      // Remove the deleted invoice from the state
      setWeeklyInvoice((prevInvoices) =>
        prevInvoices.filter((invoice) => invoice.id !== id)
      );
      toast.success("Invoice deleted successfully");
    } catch (error) {
      toast.error("Error deleting invoice");
    }
  };
  useEffect(() => {
    fetchGameData();
    fetchWeeklyInvoiceData();
  }, []);

  const handleAdd = async () => {
    if (!gameNumber || !bookName) return; // Ensure both fields are filled

    try {
      const response = await Post("updateGameAndInventory", {
        game_no: gameNumber,
        book_no: bookName,
      });

      if (response) {
        setTotalSales(response.game_total_price); // Auto-populate totalSales
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [settledDate, setSettledDate] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [scanBook, setScanBook] = useState("");
  const [processingCount, setProcessingCount] = useState(0);
  const [gameNumber, setGameNumber] = useState("");
  const [bookName, setBookName] = useState("");
  const [totalSalesSettlment, setTotalSalesSettlement] = useState("");
  const handleSubmit = async () => {
    if (!settledDate || !bookName || !gameNumber || !totalSalesSettlment) {
      toast.error("Please fill out all required fields before submitting.");
      return;
    }

    const payload = {
      instant_settlement_date: settledDate,
      book_no: bookName,
      game_no: gameNumber,
      total: totalSalesSettlment,
      status: "settled",
    };

    try {
      const response = await Post("updateGameAndInventory", payload);
      console.log("Success:", response);
      toast.success("Book settlement done!");

      // Reset the form if needed
      setSettledDate("");
      setBookName("");
      setGameNumber("");
      setTotalSalesSettlement(0);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to settle book. Please try again.");
    }
  };

  const columns = [
    { header: "Week End Data", field: "week_ending" },
    { header: "Net Sale", field: "total_net_sales" },
    { header: "Net Cashes", field: "total_cashes" },
    { header: "Total Commision", field: "total_commission" },
    { header: "Total Due", field: "total_due" },
  ];
  const columns2 = [
    { header: "Settlement Date", field: "instant_settlement_date" },
    { header: "Total Sales", field: "game_total_price" },
  ];

  return (
    <>
      <ToastContainer />
      <div className="due-days">
        <div className="header-row">
          <div className="setting-title">Weekly Invoice Settlement</div>
          <button className="lottery-weekly-add-button" onClick={() => handleShow()} >
            + Add Weekly Invoice Settlement
          </button>
        </div>
        <ReusableModal
          show={showModal}
          handleClose={handleClose}
          title={"Add New Weekly Invoice Settlement"}
          width="870px"
          height="1080px"
        >
          <form onSubmit={handleWeeklySubmit}>
            <div className="form-group d-flex flex-column">
              <div className="d-flex align-items-center mb-3">
                {/* Settled Date */}
                <label
                  htmlFor="settledDate"
                  style={{ marginRight: "16px", fontSize: "18px" }}
                >
                  Week Starting
                </label>
                <input
                  type="date"
                  id="weekStarting"
                  value={weekStarting}
                  onChange={(e) => setWeekStarting(e.target.value)}
                  className="input-field"
                  style={{
                    width: "160px",
                    marginRight: "10px",
                    height: "44px",
                  }}
                />
                <label
                  htmlFor="settledDate"
                  style={{ marginRight: "16px", fontSize: "18px" }}
                >
                  Week Ending
                </label>
                <input
                  type="date"
                  id="weekEnding"
                  value={weekEnding}
                  onChange={(e) => setWeekEnding(e.target.value)}
                  className="input-field"
                  style={{
                    width: "160px",
                    marginRight: "10px",
                    height: "44px",
                  }}
                />
              </div>
              <div className="card">
                <h3
                  style={{
                    margin: "10px",
                    padding: "0px",
                    borderBottom: "2px solid #ccc",
                  }}
                >
                  SR-61 Online Settle Report
                </h3>

                <div className="d-flex mb-3">
                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input1" style={{ fontSize: "16px" }}>
                      Net Sales
                    </label>
                    <input
                      type="number"
                      id="netSales"
                      value={netSales}
                      onChange={(e) => setNetSales(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>

                  <div className="d-flex flex-column mx-3" style={{ flex: 1 }}>
                    <label htmlFor="input2" style={{ fontSize: "16px" }}>
                      Commission
                    </label>
                    <input
                      type="number"
                      id="commissionSR61"
                      value={commissionSR61}
                      onChange={(e) => setCommissionSR61(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>

                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input3" style={{ fontSize: "16px" }}>
                      Cashes
                    </label>
                    <input
                      type="number"
                      id="cashes"
                      value={cashes}
                      onChange={(e) => setCashes(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>
                </div>
                <div className="d-flex mb-3 justify-content-start">
                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input3" style={{ fontSize: "16px" }}>
                      Cash Bonus
                    </label>
                    <input
                      type="number"
                      id="cashBonus"
                      value={cashBonus}
                      onChange={(e) => setCashBonus(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>
                  <div className="d-flex flex-column mx-1" style={{ flex: 1 }}>
                    <label htmlFor="input4" style={{ fontSize: "16px" }}>
                      Claim Bonus
                    </label>
                    <input
                      type="number"
                      id="claimBonus"
                      value={claimBonus}
                      onChange={(e) => setClaimBonus(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>
                  <div style={{ flex: 1 }}></div>
                </div>

                <label
                  className="mx-2 mb-2"
                  htmlFor="input1"
                  style={{ fontSize: "16px" }}
                >
                  Adjustments * (Please type minus sign "-" if value is negative
                  on Weekly invoice)
                </label>
                <div
                  className="d-flex flex-column mx-2 mb-2"
                  style={{ flex: 1 }}
                >
                  <input
                    type="number"
                    id="adjustment"
                    value={adjustment61}
                    onChange={(e) => setAdjustment61(e.target.value)}
                    className="input-field"
                    style={{ width: "100%", height: "44px" }}
                  />
                </div>
                <div className="d-flex mb-3">
                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input1" style={{ fontSize: "16px" }}>
                      Service Fee
                    </label>
                    <input
                      type="number"
                      id="serviceFee"
                      value={serviceFee}
                      onChange={(e) => setServiceFee(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>

                  <div className="d-flex flex-column mx-3" style={{ flex: 1 }}>
                    <label htmlFor="input2" style={{ fontSize: "16px" }}>
                      Bonding Fee
                    </label>
                    <input
                      type="number"
                      id="bondingFee"
                      value={bondingFee}
                      onChange={(e) => setBondingFee(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>

                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input3" style={{ fontSize: "16px" }}>
                      Online Due
                    </label>
                    <input
                      type="number"
                      id="onlineDue"
                      value={onlineDue}
                      onChange={(e) => setOnlineDue(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input3" style={{ fontSize: "16px" }}>
                      Instant Due
                    </label>
                    <input
                      type="number"
                      id="instantDue"
                      value={instantDue}
                      onChange={(e) => setInstantDue(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>
                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input3" style={{ fontSize: "16px" }}>
                      Net Due
                    </label>
                    <input
                      type="number"
                      id="netDue"
                      value={netDue}
                      onChange={(e) => setNetDue(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>
                  <div style={{ flex: 1 }}></div>
                </div>
              </div>
              <div className="card">
                <h3
                  style={{
                    margin: "10px",
                    padding: "0px",
                    borderBottom: "2px solid #ccc",
                  }}
                >
                  SR-38 Instant Settle Report
                </h3>

                <div className="d-flex mb-3">
                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input1" style={{ fontSize: "16px" }}>
                      Total Sales
                    </label>
                    <input
                      type="number"
                      id="total_sales"
                      value={totalSales}
                      onChange={(e) => setTotalSales(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>

                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input2" style={{ fontSize: "16px" }}>
                      Total Return
                    </label>
                    <input
                      type="number"
                      id="totalReturn"
                      value={totalReturn}
                      onChange={(e) => setTotalReturn(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>

                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input3" style={{ fontSize: "16px" }}>
                      Commission
                    </label>
                    <input
                      type="number"
                      id="commissionSR38"
                      value={commissionSR38}
                      onChange={(e) => setCommissionSR38(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>
                </div>

                <label
                  className="mx-2 mb-2"
                  htmlFor="input1"
                  style={{ fontSize: "16px" }}
                >
                  Adjustments * (Please type minus sign "-" if value is negative
                  on Weekly invoice)
                </label>
                <div
                  className="d-flex flex-column mx-2 mb-2"
                  style={{ flex: 1 }}
                >
                  <input
                    type="number"
                    id="adjustment38"
                    value={adjustment38}
                    onChange={(e) => setAdjustment38(e.target.value)}
                    className="input-field"
                    style={{ width: "100%", height: "44px" }}
                  />
                </div>
                <div className="d-flex mb-3">
                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input1" style={{ fontSize: "16px" }}>
                      SR 31 Total
                    </label>
                    <input
                      type="number"
                      id="sr31Total"
                      value={sr31Total}
                      onChange={(e) => setSr31Total(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>

                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input2" style={{ fontSize: "16px" }}>
                      Cashing Commission
                    </label>
                    <input
                      type="number"
                      id="cashingCommission"
                      value={cashingCommission}
                      onChange={(e) => setCashingCommission(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>

                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input3" style={{ fontSize: "16px" }}>
                      Total Instant Cashing
                    </label>
                    <input
                      type="number"
                      id="totalInstantCashing"
                      value={totalInstantCashing}
                      onChange={(e) => setTotalInstantCashing(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                    <label htmlFor="input3" style={{ fontSize: "16px" }}>
                      Amount Due
                    </label>
                    <input
                      type="number"
                      id="amountDue"
                      value={amountDue}
                      onChange={(e) => setAmountDue(e.target.value)}
                      className="input-field"
                      style={{ width: "100%", height: "44px" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <button
              style={{
                marginRight: "40px",
                padding: "4px 8px",
                backgroundColor: "#4545db",
                color: "white",
                border: "none",
                borderRadius: "40px",
                marginLeft: "670px",
                cursor: "pointer",
                width: "124px",
                height: "42px",
                fontSize: "18px",
              }}
            >
              Submit
            </button>
          </form>
        </ReusableModal>
        <WeeklyInvoiceTable
          data={weeklyInvoice}
          columns={columns}
          showAction={true}
          showFooter={true}
          onDelete={handleDelete}
          onEdit={handleShow}

        />
        {/* instant Settlement */}
        <div className="header-row ">
          <div className="setting-title">Instant Book Settlement</div>
          <button
            className="lottery-book-settle-button"
            onClick={handleShowBook}
          >
            + Add Instant Book Settlement
          </button>
        </div>
        <ReusableModal
          show={showBookModal}
          handleClose={handleCloseBook}
          title={"Add Instant Book Settlement"}
          width="auto"
          height="auto"
        >
          <form>
            {/* First row */}
            <div className="form-group d-flex flex-column">
              <div className="d-flex align-items-center mb-0">
                {/* Settled Date */}
                <label
                  htmlFor="settledDate"
                  style={{ marginRight: "16px", fontSize: "18px" }}
                >
                  Settled Date
                </label>
                <input
                  type="date"
                  id="settledDate"
                  className="input-field"
                  style={{
                    width: "160px",
                    marginRight: "20px",
                    height: "44px",
                  }}
                  value={settledDate}
                  onChange={(e) => setSettledDate(e.target.value)}
                />

                {/* Sold Books & Returned Books */}
                <label
                  htmlFor="soldReturnedBooks"
                  style={{ marginRight: "16px", fontSize: "18px" }}
                >
                  Sold Books & Returned Books
                </label>

                {/* Select Book Dropdown */}
                <select
                  id="selectBook"
                  className="input-field"
                  style={{
                    width: "289px",
                    marginRight: "10px",
                    height: "44px",
                  }}
                >
                  <option value="">Select Book</option>
                  <option value="book1">Book 1</option>
                  <option value="book2">Book 2</option>
                  {/* Add more options as needed */}
                </select>
              </div>
            </div>

            {/* Second row */}
            <div className="form-group d-flex flex-column">
              <div className="d-flex align-items-center mb-0">
                {/* Scan Book */}
                <input
                  type="text"
                  id="scanBook"
                  className="input-field"
                  placeholder="Scan Book"
                  style={{
                    width: "686px",
                    marginRight: "20px",
                    height: "44px",
                  }}
                />
                <input
                  type="text"
                  id="scanBook"
                  className="input-field"
                  placeholder="Processing:0"
                  style={{
                    width: "141px",
                    marginRight: "20px",
                    height: "44px",
                  }}
                />
              </div>
            </div>

            {/* Third row */}
            <div className="form-group d-flex flex-column">
              <div className="d-flex align-items-center mb-0">
                {/* Select Manually */}
                <label
                  htmlFor="gameNumber"
                  style={{
                    marginRight: "10px",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  Settle Manually:
                </label>
                <input
                  type="text"
                  id="gameNumber"
                  className="input-field"
                  placeholder="Game Number"
                  style={{
                    width: "292px",
                    marginRight: "20px",
                    height: "44px",
                  }}
                  value={gameNumber}
                  onChange={(e) => setGameNumber(e.target.value)}
                />

                <input
                  type="text"
                  id="bookName"
                  className="input-field"
                  placeholder="Book Number"
                  style={{
                    width: "292px",
                    marginRight: "20px",
                    height: "44px",
                  }}
                  value={bookName}
                  onChange={(e) => setBookName(e.target.value)}
                />
                <button
                  className="btn-activate"
                  style={{ width: "79px", marginLeft: "0px" }}
                  onClick={handleAdd}
                >
                  Add
                </button>
              </div>
            </div>

            {/* Fourth row (Total Sales & Submit Button) */}
            <div className="form-group d-flex flex-column">
              <div className="d-flex align-items-center mb-3">
                <label
                  htmlFor="totalSalesSettlement"
                  style={{ marginRight: "10px" }}
                >
                  Total Sales:
                </label>
                <input
                  type="text"
                  id="totalSalesSettlement"
                  className="input-field"
                  placeholder="0"
                  style={{
                    width: "270px",
                    marginRight: "20px",
                    height: "44px",
                  }}
                  value={totalSalesSettlment} // This will now show the game total price
                  onChange={(e) => setTotalSalesSettlement(e.target.value)} // Optional: Allow manual changes if needed
                />
              </div>

              {/* Submit Button */}
              <button
                onClick={handleSubmit}
                style={{
                  padding: "4px 8px",
                  backgroundColor: "#4545db",
                  color: "white",
                  border: "none",
                  borderRadius: "40px",
                  marginLeft: "725px",
                  cursor: "pointer",
                  width: "124px",
                  height: "42px",
                  fontSize: "18px",
                }}
                type="button"
              >
                Submit
              </button>
            </div>
          </form>
        </ReusableModal>
        <SettingTable
          data={games}
          columns={columns2}
          showAction={true}
          showFooter={true}
        />
      </div>
    </>
  );
}

export default WeeklyInvoiceSettlement;
