import React from "react";
import "./CustomInput.css";
import ValidationHelper from '../ValidationHelper/ValidationHelper';

const CustomInput = ({ type = 'text', placeholder = '', className = '', error = false, custom=false, msg="", ...props }) => {
  return (
    <>
      <input
        type={type}
        placeholder={placeholder}
        className={`custom-input ${className}`}
        
        {...props}
      />
      <ValidationHelper placeholder={placeholder} error={error} custom={custom} msg={msg}/>
    </>
  );
};

export default CustomInput;
