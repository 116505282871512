/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect,useContext } from "react";

import BankImage from "../../../../assets/img/bankImage.png";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import SettingTable from "../ManageSettings/SettingTable";
import AuthContext from "../../.././../utils/secure-route/AuthContext";
import { ToastContainer } from "react-toastify";
export default function GeneralLedgerBankLedger() {
  const [billDate, setBillDate] = useState("2024-09-20"); // Example default date
  const dateInputRef = useRef(null);
  const { user } = useContext(AuthContext);
  const { Post ,Get} = useApi();
  const [bankLedger, setBankLedger] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const storeid = user.store;

  const [bankBalances, setBankBalances] = useState({});


  useEffect(() => {
    const fetchBankBalances = async () => {
      try {
        const response = await Post('bankLedger'); // Replace with your actual API endpoint
        setBankBalances(response.data.total_current_balances);
        // console.log(response.data.total_current_balances)
      } catch (error) {
        console.error('Error fetching bank balances:', error);
      }
    };

    fetchBankBalances();
  }, []);

  const fetchBanks = async () => {
    try {
      const data = await Get("bank"); // Replace with your actual API call
      setBanks(data);
    } catch (error) {
      console.error("Error fetching banks:", error);
    }
  };

  const getBankLedger = async () => {
    if (selectedBank && startDate && endDate) {
      try {
        const requestData = {
          bank: selectedBank,
          start_date: startDate,
          end_date: endDate,
        };

        const response = await Post("bankLedger", requestData);
        if (response.status === 200) {
          const bankCashData = response.data.bank_cash || [];
          setBankLedger(Array.isArray(bankCashData) ? bankCashData : []);
        } else {
          console.error("Error: Response status is not 200", response);
        }
      } catch (error) {
        console.error("Error fetching bank ledger data:", error);
      }
    }
  };
  useEffect(() => {
    getBankLedger();
    fetchBanks();
  },[selectedBank, startDate, endDate]);

  const handleCashSubmit = async (e) => {
    e.preventDefault(); // Prevents default page reload
    // Create FormData object directly from the event target (form element)
    const formData = new FormData(e.target);
    // Convert FormData to a plain object
    const payload = Object.fromEntries(formData.entries());
    payload.store = storeid;
    payload.type = "bank_ledger";

    await Post("bankCashAdjustmentData", payload).then((resp) => {
      setShowModal(false);
    });
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setBillDate(selectedDate);
    console.log("Selected date:", selectedDate);
  };

  const openDatePicker = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const columns = [
    // { header: "No", field: "index" }, // Optional, you can generate index dynamically
    { header: "Date", field: "date" },
    { header: "Invoice Number", field: "invoice_no" },
    { header: "Debit", field: "debit" },
    { header: "Credit", field: "credit" },
    { header: "Current Balance", field: "current_balance" },
    { header: "Reason", field: "reason" },
  ];

  return (
    <>
    <ToastContainer />
    <div className="main-container mt-2">
      <div className="d-flex p-2">
        <div className="expensecontainer d-flex flex-column pd-2">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="mb-1">Bank Ledger</h3>
            <button className="btn btn-primary cus" onClick={toggleModal}>
              {" "}
              Adjust Balance
            </button>
          </div>

          <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "16px",
        marginBottom: "20px",
      }}
    >
      {Object.entries(bankBalances).map(([bankName, balance]) => (
        <div
          key={bankName}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "350px",
            height: "100px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            padding: "16px",
            backgroundColor: "#f0f2ff",
            border: "1px solid #d0d7de",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <div style={{ color: "#0a5f38" }}>
            <h3 style={{ margin: "0", fontSize: "18px" }}>
              {bankName}
            </h3>
            <p
              style={{
                margin: "8px 0 0",
                fontSize: "14px",
                color: "#6b6b6b",
              }}
            >
              Current Balance: ${balance.toFixed(2)}
            </p>
          </div>
          <div style={{ opacity: 0.5 }}>
            <img
              src={BankImage}
              alt="Bank Icon"
              style={{
                width: "80px",
                height: "80px",
                filter: "brightness(1.9)",
                opacity: 0.3,
              }}
            />
          </div>
        </div>
      ))}
    </div>


          <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        {/* Bank Selection */}
        <select
          style={{
            backgroundColor: "#0023000C",
            borderRadius: "10px",
            height: "52px",
          }}
          className="form-control"
          name="bank"
          value={selectedBank}
          onChange={(e) => setSelectedBank(e.target.value)}
          required
        >
          <option value="">Select a bank</option>
          {banks.map((bank) => (
            <option key={bank.id} value={bank.id}>
              {bank.name}
            </option>
          ))}
        </select>

        {/* Start Date */}
        <input
          type="date"
          className="form-control"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          style={{
            backgroundColor: "#0023000C",
            borderRadius: "10px",
            height: "52px",
          }}
        />

        {/* End Date */}
        <input
          type="date"
          className="form-control"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          style={{
            backgroundColor: "#0023000C",
            borderRadius: "10px",
            height: "52px",
          }}
        />
      </div>

          <SettingTable
            data={bankLedger}
            columns={columns}
            showFooter={true}
            showAction={false}
            showExport={true}
            showFilter={false}
            // onEdit={handleEditBankDeposit}
            // onDelete={handleDeleteBankDeposit}
          />

          {showModal && (
            <div className="modal-overlay" onClick={toggleModal}>
              <div
                className="modal-container"
                onClick={(e) => e.stopPropagation()}
                style={{ width: "700px" }}
              >
                <div className="modal-header" style={{ border: "none" }}>
                  <h5 className="modal-title" style={{ fontWeight: "bold" }}>
                    Adjust Balance
                  </h5>

                  <svg
                    onClick={toggleModal}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <path
                      d="M1 1L13 13M13 1L1 13"
                      stroke="black"
                      stroke-linecap="round"
                    />
                  </svg>
                  {/* <button type="button" className="modal-close-btn" onClick={toggleModal}>
                    <span className='closebtn'>&times;</span>
                  </button> */}
                </div>
                <div className="modal-body">
                <form onSubmit={handleCashSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <input
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      type="date"
                      className="form-control"
                      name="date"
                      required
                    />
                  </div>

                  <div className="col-md-4">
                    <select
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      className="form-control"
                      name="bank"


                      required
                    >
                      <option value="">Select a bank</option>

                      {banks.map((bank) => (
                        <option key={bank.id} value={bank.id}>
                          {bank.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <select
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      type="number"
                      className="form-control"
                      name="cash_type"
                      required
                    >
                      <option value="Business cash">Business Cash</option>
                      <option value="Gas cash">Gas Cash</option>
                      <option value="Lottery cash">Lottery Cash</option>
                    </select>
                  </div>

                  <div className="col-md-4">
                    <input
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      type="number"
                      className="form-control"
                      placeholder="$ 100"
                      name="amount"
                      min="0"       // Only allow positive values
                      required
                    />
                  </div>
                </div>
                <div className="row">
                <div className="col-md-7"></div>
                  <div className="col-md-5">
                  <hr/>
                    <button type="submit" className="btn btn-primary" style={{width: '100%'}}>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
                </div>

              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
}
